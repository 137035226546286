import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import ProductImage from "../../../images/product_real/DECAF1.JPG";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroDecafCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(32,62,113,0.98),rgba(32,62,113,0.57))'}
        title={'디카페인'}
        detail={<>상큼한 사과,<br/>향기로운 허브.</>}
        description={<>
            허브의 향미와 상큼한 사과의 기분 좋은 산미,<br/>
            그리고 헤이즐넛의 단맛이 좋은 부드러운 커피.<br/>
            스위스워터프로세스로 안전하게 오랜 시간 추출된<br/>
            디카페인으로 깊은 커피의 맛을 느껴보세요.
            </>}
        image={ProductImage}
    />


}
