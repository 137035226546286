import {Modal} from "react-bootstrap";
import CartContainer from "../CartContainer";
import * as React from "react";
import ProductList from "../products/ProductList";
import SelectableProductList from "./SelectableProductList";


export default function SelectProductsModal(props){

    return <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>
                상품 추가
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SelectableProductList onSelect={props.onSelect}/>
        </Modal.Body>
    </Modal>;

};
