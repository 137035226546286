import CobaltVioletPattern from "../../../images/cobalt_violet_und.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


// 제품 상세 코드
export default function DetailImageCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${CobaltVioletPattern})`,
            borderRadius: 40,
        }}>
            <img src={CobaltVioletPattern} style={{width:'100%', zIndex: -1000, borderRadius: 40}}/>

        </div>
    </Col>

}
