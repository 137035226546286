import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "./TopMenu";
import Badge from "react-bootstrap/Badge";
import SizingComponent from "./mixin/SizingComponent";


class Footer extends SizingComponent{

    static getContainerStyle = () => {
        return {
            minHeight: 'calc(100vh - 350px)',
        };
    };

    render() {
        let fontSize = 12;
        if (this.state.width < 600){
            fontSize = 10;
        }
        return (
            <div className="page-footer font-small pt-4" style={{background:
                    'linear-gradient(rgba(22,32,63,0.88),rgba(22,32,63,0.84))',
            paddingBottom: 50,
            }}>
                <div className="container-fluid text-center text-md-left">
                    <div className="row">
                        <div className="col-md-8 mt-md-0 mt-3 p-5">
                            <h5 className="text-uppercase text-white">커피플리즈</h5>
                            <p className={'text-white-50'} style={{fontSize: fontSize}}>
                                대표자: 차성준 | 사업자 등록번호: 754-03-03032<br />
                                통신판매업신고번호: 제 2024-서울강남-02509 호<br />
                                서울특별시 강남구 선릉로 313, 2층 3호(역삼동, 삼미빌딩)<br />
                                Copyright ©2024 All rights<br />
                                reserved by dripsso.com
                            </p>
                        </div>


                            <div className="col-md-4 mb-md-0 mb-3 p-5">
                                <h5 className="text-uppercase text-white">바로가기</h5>

                                <ul className="list-unstyled">
                                    <li>
                                        <a className={'text-white'} style={{fontWeight: 200}} href="https://www.instagram.com/coffeeplease.official/" target={'_blank'}>
                                            커피플리즈 인스타그램
                                        </a>
                                    </li>

                                    <li>
                                        <a className={'text-white'} style={{fontWeight: 200}} href="/docs/privacy.pdf" target={'_blank'}>
                                            개인정보처리방침
                                        </a>
                                    </li>
                                    <li>
                                        <a className={'text-white'} style={{fontWeight: 200}} href="/docs/term.pdf" target={'_blank'}>
                                            이용약관
                                        </a>
                                    </li>
                                </ul>

                            </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default Footer;
