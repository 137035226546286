import * as React from "react";
import {Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import API from "../../shared/service/api/API";
import Image from "../mixin/Image";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Button from "@material-ui/core/Button";


class SelectableProductList extends React.Component{

    state = {
        productsPrice: 0,
        totalPrice: 0,
        shippingPrice: 0,
        productsUnitPrice: 0,
        totalUnitPrice: 0,
        showShippingAlert: false
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadProductCells()
    }

    componentDidMount() {
        this.loadProductCells();
    }


    getProductCell = (product) => {
        let title = product.title;
        let detail = product.detail;
        let imageId = product.real_image_id;
        let price = product.subscription_price;
        let unitPrice = product.unit_price;
        return <><Row onClick={()=>{this.props.onSelect(product)}}
                      className={'justify-content-center mb-2'}
                        style={{
                            cursor: 'pointer'
                        }}>
            <Col xs={4} className={'text-center'}>
                <Image key={imageId} imageId={imageId} style={{
                    width: '70%',
                    borderRadius: 10,
                }}/>
            </Col>
            <Col xs={8} className={'text-left mt-3'}>
                <Row>
                    <Col xs={9}>
                        <h8 style={{fontWeight: 600, fontSize:15}}>{title}</h8><br/>
                        <h7 style={{fontWeight: 500}}>{detail}</h7>
                    </Col>
                </Row>

                <Row className={'mt-2'}>
                    <Col xs={12}>
                        <h6 className={'text-primary'}>{price.toLocaleString()}원 </h6>

                    </Col>
                </Row>

                <Row className={'mt-2'}>
                    <Col xs={12} className={'text-right'}>
                        <Button><AddShoppingCartIcon/></Button>
                    </Col>
                </Row>
            </Col>
        </Row>
            <hr/></>;
    };


    loadProductCells = (callback=()=>{}) => {
        return API.database.queryItems('new_product', [
            {condition: 'eq', field: 'visible_store', value: true, option: 'and'}
        ]).then(response => {
            let products = response.items;
            let productValues = Object.values(products);

            let productCells = productValues.map(product => {
                return this.getProductCell(product);
            });
            this.setState({
                productCells,
            }, () => {
                callback();
            })
        });
    };



    render() {
        return <Container>
            {this.state.productCells}
        </Container>
    }

}


export default SelectableProductList;
