import CardMedia from "@material-ui/core/CardMedia";
import React from "react";
import API from "../../../../shared/service/api/API";


export default function CustomCardMedia(props) {

    const [image, setImage] = React.useState(null);
    const [opacity, setOpacity] = React.useState(0);


    const getImageBase64 = async (image_id) => {
        const response = await API.storage.downloadB64(image_id);
        let fileB64 = response.file_b64;

        const src = 'data:image/gif;base64,' + fileB64;
        return src;
    };

    React.useEffect(()=>{
        (async ()=>{
           const image = await getImageBase64(props.image_id);
           setImage(image);
            setOpacity(1);
        })();
    }, [props.image_id]);

    return <CardMedia
        style={{
            webkitTransition: 'opacity 1s',
            transition: 'opacity 1s',
            opacity: opacity
        }}
        component="img"
        height="250"
        image={image}
    />;

}
