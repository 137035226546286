import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import TasteDarkChocolateSecond from "../card/taste/TasteDarkChocolateSecond";
import TasteDryBerry from "../card/taste/TasteDryBerry";
import IntroBrazilCard from "../card/intro/IntroBrazilCard";
import TasteRoastedPeanuts from "../card/taste/TasteRoastedPeanuts";
import IntroEthiopiaCard from "../card/intro/IntroEthiopiaCard";
import TasteAroma from "../card/taste/TasteAroma";
import TasteHoney from "../card/taste/TasteHoney";


export default function EthiopiaPage(props) {

    const product_id = 'ZzmmpeiHV4zQZuXfwvT6kn';

    return <PageLayout
        product_id={product_id}
    >
        <IntroEthiopiaCard/>
        <TasteAroma/>
        <TasteHoney/>
        <MicrolotCard/>
        <ShippingCard/>

        <RTDCard/>
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
    </PageLayout>

}

