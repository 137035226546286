import * as React from "react";
import Brazil from "../images/coffee_b_a.png";
import API from "../../shared/service/api/API";
import Cache from "../../shared/Cache";


class Image extends React.Component{

    state = {
        base64: null,
    };

    componentDidMount() {
        this.fetch(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.fetch(nextProps);
    }

    fetch = (props) => {
        this.fetchBase64(props);
    };

    fetchBase64 = (props) => {
        let fileId = props.imageId;
        let cacheB64 = Cache.instance.getValue(fileId);
        if (cacheB64){
            let src = 'data:image/gif;base64,' + cacheB64;
            this.setState({
                base64: src
            });
            return;
        }
        API.storage.downloadB64(fileId).then(data => {
            let fileB64 = data.file_b64;
            Cache.instance.putValue(fileId, fileB64);
            let src = 'data:image/gif;base64,' + fileB64;
            this.setState({
                base64: src
            })
        });
    };

    render() {
        return (
            <div>
                <img src={this.state.base64} style={this.props.style}/>
            </div>
        );
    }

}

export default Image;
