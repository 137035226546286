import * as React from "react";
import {Container, ModalBody} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../images/iceCoffee.png";
import BackVideo from "../../mov/backv.mp4";
import SizingComponent from "../../mixin/SizingComponent";
import LampBlack1 from "../../images/product_real/LampBlack.JPG";
import useFontRatio from "../../../util/UseWindowDimensions";
import SeeMoreButton from "./SeeMoreButton";
import Badge from "reactstrap/lib/Badge";
import Color from "../../../shared/Color";
import LandingLampBlack from "../../products/new_landing/LampBlack";
import {Modal} from "react-bootstrap";




export default function LampBlack(props){

    const fr = useFontRatio();

    const [showDetail, setShowDetail] = React.useState(false);

    return <>
        <Row style={{
            ...props.style,
        background: 'linear-gradient(rgba(10,12,13,0.98),rgba(10,12,13,0.92))',
        marginTop: -2
    }} className={'justify-content-md-center'} >


        <Col style={{
            borderRadius: 0,
            padding: 40 * fr,
            background: 'linear-gradient(rgba(30,30,50,0.88),rgba(40, 30, 30,0.97))'
        }}>
            <Container>
                <Row>
                    <Col md={6} className={'mt-3 mb-3'}>
                        <Badge style={{marginTop: 0, background: Color.primary, color: '#fff'}}>드립커피</Badge><br/>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>램프 블랙</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(40, Math.min(50 * fr, 50)), fontWeight: 800, color: '#969696'}}>Lamp Black</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>약한 산미,<br/>강한 바디.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                            어렴풋이 느껴지는 과일의 산미와 <br/>
                            은은하게 비춰지는 꿀의 단맛과는 상반되는  <br/>
                            뚜렷히 표현된 다크초콜렛의 쓴맛과 <br/>
                            강렬한 바디감이 인상적인 묵직한 커피.
                        </text>
                        <br/>
                        <SeeMoreButton onClick={()=>{
                            window.open('/products/lamp_black');
                        }}/>
                    </Col>

                    <Col md={6} className={'mt-3 mb-3'}>
                        <img src={LampBlack1} width={'100%'} style={{marginRight:0, borderRadius: 10}}/>
                    </Col>
                </Row>

            </Container>


        </Col>


    </Row>
        </>

}
