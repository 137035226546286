import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroDecafCard from "../card/intro/IntroDecafCard";
import TasteHerb from "../card/taste/TasteHerb";
import TasteApple from "../card/taste/TasteApple";
import IntroCobaltVioletCard from "../card/intro/IntroCobaltVioletCard";
import TasteDarkChocolateSecond from "../card/taste/TasteDarkChocolateSecond";
import TasteDryBerry from "../card/taste/TasteDryBerry";
import IntroCobaltVioletUDCard from "../card/intro/IntroCobaltVioletUDCard";
import DetailImageCard from "../card/Pros/DetailImageCard";


export default function CobaltVioletUDPage(props) {

    const product_id = '5egQZw6c2P5TEdR3yjDefX';

    return <PageLayout
        product_id={product_id}
    >
        <IntroCobaltVioletUDCard/>
        <TasteDryBerry/>
        <TasteDarkChocolateSecond/>
        <MicrolotCard/>
        <DetailImageCard/>
        <ShippingCard/>
        <DripCoffeeCard/>
        {/*<RTDCard/>*/}
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
    </PageLayout>

}

