import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../images/iceCoffee.png";
import SizingComponent from "../mixin/SizingComponent";
import Brazil from "../images/coffee_b_a.png";
import Ethiopia from "../images/coffee_e_a.png";
import Latte from "../images/coffee_l_a.png";
import CoffeeDark from "../images/caffe_dark.png";
import brazil from "../images/brazil.jpg";
import Button from "react-bootstrap/Button";
import GoldMedal from "../images/gold_medal.png";


class MainSection6 extends SizingComponent{

    render() {

        let header1 = "2020 한국일보 베스트 신상품 대상";

        return <Row className={'justify-content-md-center mt-5 mb-5'} style={{background: '#fafafa'}}>

            <Col md={'12'} className={'text-center'}>
                <div style={{marginTop: 100, marginBottom: 100}}>
                    <img src={GoldMedal} width={80} height={80} style={{marginBottom: 50}}/>
                    <h5>{header1}</h5>
                    <h6>"간소화된 유통단계 덕분에 제조 후 이틀 안에 신선한 커피를 받아볼 수 있는 유일한 브랜드."</h6>
                </div>
            </Col>

        </Row>
    }

}


export default MainSection6;
