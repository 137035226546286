import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../images/iceCoffee.png";
import BackVideo from "../../mov/backv.mp4";
import SizingComponent from "../../mixin/SizingComponent";
import CobaltViolet1 from "../../images/product_real/CobaltViolet1.JPG";
import useFontRatio from "../../../util/UseWindowDimensions";
import SeeMoreButton from "./SeeMoreButton";
import Badge from "reactstrap/lib/Badge";
import Color from "../../../shared/Color";



export default function CobaltViolet(props){

    const fr = useFontRatio();

    return <Row style={{
        background: 'linear-gradient(rgba(10,12,13,0.98),rgba(10,12,13,0.92))',
        marginTop: -2
    }} className={'justify-content-md-center'} >

        <Col style={{
            borderRadius: 0,
            padding: 40 * fr,
            background: 'linear-gradient(rgba(62,62,93,0.98),rgba(62,62,83,0.57))'
        }}>
            <Container>
                <Row>
                    <Col md={6} className={'mt-3 mb-3'}>
                        <Badge style={{marginTop: 0, background: Color.primary, color: '#fff'}}>드립커피</Badge><br/>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>코발트 바이올렛</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(40, Math.min(50 * fr, 50)), fontWeight: 800, color: '#A09EE3'}}>Cobalt Violet</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>드라이 베리,<br/>감미로운 쓴맛.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                            드라이 베리의 산미와 다크초콜렛의 단맛이 <br/>
                            감미롭게 다가와 입안 전체를 감싸는 <br/>
                            묵직한 바디와 환상적인 조화를 이루는 커피.
                        </text>
                        <br/>
                        <SeeMoreButton onClick={()=>{
                            window.open('/products/cobalt_violet');
                        }}/>
                    </Col>

                    <Col md={6} className={'mt-3 mb-3'}>
                        <img src={CobaltViolet1} width={'100%'} style={{marginRight:0, borderRadius: 10}}/>
                    </Col>
                </Row>

            </Container>


        </Col>


    </Row>

}
