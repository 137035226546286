import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SizingComponent from "../mixin/SizingComponent";
import background from "../images/home_background.webp"
import backgroundMobile from "../images/home_background_mobile.png"


class StartSection1 extends SizingComponent{

    render() {
        if (this.state.width < 400) {
            return (
                <Row className={'justify-content-md-center'} style={{
                    height: '90%',
                    backgroundImage: `url(${backgroundMobile})`,
                    backgroundPosition: 'bottom',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <Col md={'auto'} className={'text-center'} style={{marginTop: '40%', marginBottom:200}}>
                        <text style={{fontSize: 26, fontWeight: 300}}>프리미엄 퀄리티</text>
                        <h2 style={{fontSize: 26}}>수제 콜드브루 구독 브랜드</h2>
                        <h3 style={{marginTop:16, marginBottom:40, fontSize: 16, fontWeight: 300}}>
                            스페셜티 콜드브루 12잔 분량을 19,900원에
                        </h3>
                        <Button style={{height:50, width:150, fontWeight:500}}>더 알아볼까요?</Button>
                    </Col>
                </Row>
            );
        }else if (this.state.width < 800){
            return (
                <Row className={'justify-content-md-center'} style={{
                    height: '90%',
                    backgroundImage: `url(${backgroundMobile})`,
                    backgroundPosition: 'bottom',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <Col md={'auto'} className={'text-center'} style={{marginTop: 200, marginBottom:'58%', minHeight: '50%'}}>
                        <text style={{fontSize: 35, fontWeight: 300}}>프리미엄 퀄리티</text>
                        <h2 style={{fontSize: 35}}>수제 콜드브루 구독 브랜드</h2>
                        <h3 style={{marginTop:20, marginBottom:40, fontSize: 16, fontWeight: 300}}>
                            스페셜티 콜드브루 12잔 분량을 19,900원에
                        </h3>
                        <Button style={{height:50, width:150, fontWeight:500}}>더 알아볼까요?</Button>
                    </Col>
                </Row>
            );
        }else{
            return (
                <Row className={'justify-content-md-center'} style={{
                    height: '90%',
                    backgroundImage: `url(${background})`,
                    backgroundPosition: 'bottom',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <Col md={'auto'} className={'text-center'} style={{marginTop: 200, marginBottom:'20%', minHeight: '50%'}}>
                        <text style={{fontSize: 35, fontWeight: 300}}>프리미엄 라이프스타일 시작.</text>
                        <h2 style={{fontSize: 35}}>정기구독시 최대 50% 할인</h2>
                        <h3 style={{marginTop:20, marginBottom:40, fontSize: 16, fontWeight: 300}}>
                            삶의 질을 업그레이드 시켜 줄 프리미엄 콜드브루 정기구독.
                        </h3>
                        <Button style={{height:50, width:150, fontWeight:900}}>시작하기</Button>
                    </Col>
                </Row>
            );
        }
    }

}


export default StartSection1;
