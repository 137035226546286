import API from "../shared/service/api/API";


class Product {

    static products = null;

    static getProducts = async () => {
        if (Product.products){
            return Product.products
        }
        let products = {};
        let response = await API.database.queryItems('new_product', []);
        let items = response.items;
        items.forEach(item => {
            products[item.id] = item;
        });
        Product.products = products;
        return products;
    };

    static getName = async (product_counts) => {
        let products = await Product.getProducts();
        let name = '';
        if (!product_counts){
            return name;
        }
        let product_names = product_counts.map(product_count => {
            let product_id = product_count.product_id;
            let count = product_count.count;
            let title = products[product_id].title;
            return title + ' ' + count + '개';
        });
        product_names.forEach((n, idx, _) => {
            name += n;
            if (idx !== product_names.length - 1){
                name += ', ';
            }
        });
        return name;
    };

}

export default Product;