import SizingComponent from "../components/mixin/SizingComponent";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import TopMenu from "../components/TopMenu";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import MyPage from "./MyPage";
import API from "../shared/service/api/API";
import {TextField} from "@material-ui/core";
import Service from "../shared/service/Service";
import LoginSection from "../components/member/LoginSection";
import CoffeeImage from "../components/images/coffeebottom.png";
import Login from "./Login";

class ChangePassword extends SizingComponent{

    static path = '/changepassword';
    static path2 = '/changepassword/:token';

    state = {
        topMenu: null,
    };

    componentDidMount() {
        super.componentDidMount();
        this.checkLoggedIn();
    }

    getToken = () => {
        return this.props.match.params.token;
    };

    checkLoggedIn = () => {
        API.auth.isLogin().then(loggedIn => {
            if (loggedIn){
                this.props.history.push(MyPage.path);
            }
        });
    };

    handleOnTextChange = (e) => {
        let name = e.target.name;
        let text = e.target.value;
        this.setState({
            [name]: text
        })
    };

    changePassword = async () => {
        let newPassword = this.state.new_password;
        let newPasswordRe = this.state.new_password_re;
        if (!newPassword || !newPasswordRe){
            alert("변경할 비밀번호를 입력해주세요.");
            return;
        }
        if (newPassword !== newPasswordRe || newPassword.length === 0){
            alert("비밀번호를 다시 확인해주세요.");
            return;
        }
        if (newPassword && newPassword.length < 7){
            alert("비밀번호는 7자 이상입니다.");
            return;
        }
        let response = await Service.password.changePasswordViaToken(this.getToken(), newPassword);
        if (response.error){
            alert(response.error.message);
        }else{
            alert("비밀번호가 변경되었습니다.");
            this.props.history.push(Login.path);
        }
    };

    render() {
        return (
            <>
                <div style={{
                    background:'linear-gradient(rgba(32,62,73,0.08), rgba(32,62,73,0.0))',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <TopMenu loggedIn={this.state.loggedIn}/>

                    <Container style={Footer.getContainerStyle()}>
                        <Row className={'mt-1 justify-content-left'} >
                            <Col style={{marginTop: 60, marginLeft: 20}}>
                                <h3 className={'text-black'}>비밀번호<text style={{fontWeight: 200}}>를 변경합니다.</text></h3>
                            </Col>
                        </Row>


                        <Row flex className={'justify-content-left '} style={{height: '100%'}}>
                            <Col md={6} style={{padding: 35}}>
                                <TextField
                                    type={'password'}
                                    label="변경할 비밀번호"
                                    placeholder="7자리 이상"
                                    helperText="변경할 비밀번호를 입력해주세요."
                                    fullWidth
                                    margin="normal"
                                    name={'new_password'}
                                    onChange={this.handleOnTextChange}
                                />

                                <TextField
                                    type={'password'}
                                    label="비밀번호 확인"
                                    placeholder="7자리 이상"
                                    helperText="변경할 비밀번호를 다시 입력해주세요."
                                    fullWidth
                                    margin="normal"
                                    name={'new_password_re'}
                                    onChange={this.handleOnTextChange}
                                />

                                <div className={'text-right'}>
                                    <Button className={'mt-4'} onClick={this.changePassword}>변경하기</Button>
                                </div>


                            </Col>

                            <Col md={6} className={'text-bottom'} style={{minHeight: 450, height: 'calc(100vh - 450px)',}}>
                                <img src={CoffeeImage} height={'600'} width={'auto'} style={{bottom: 0,
                                    position: 'absolute'}} />
                            </Col>

                        </Row>

                    </Container>

                </div>
                <Footer/>
            </>
        );
    }

}

export default ChangePassword;
