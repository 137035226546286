import CardBody from "reactstrap/es/CardBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filter1Icon from "@material-ui/core/SvgIcon/SvgIcon";
import {Card} from "react-bootstrap";
import * as React from "react";


class SelectableCard extends React.Component{

    getCardClassName = () => {
        if (this.props.selected){
            return 'border-primary'
        }
        return ''
    }

    getTitleClassName = () => {
        if (this.props.selected){
            return 'ml-3 text-primary'
        }
        return 'ml-3'
    }

    render() {
        return <Card onClick={this.props.onClick} className={this.getCardClassName()}>
            <CardBody style={{
                borderColor: '#000',
            }}>
                <Container>
                    <Row>
                        <Col>
                            {this.props.icon}
                            <text className={this.getTitleClassName()} style={{fontWeight: 500}}>{this.props.title} <span className={'ml-2'}>{this.props.badge}</span></text>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        </Card>
    }

}

export default SelectableCard;
