import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import KakaoPay from '../images/kakaopay.png'
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Badge from "@material-ui/core/Badge";


const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: '#333',
    },
});

function PaymentDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleClickKakao = () => {
        props.onClickKakao();
    };

    const handleClickCard = () => {
        props.onClickCard();
    };


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">결제 수단을 선택해주세요</DialogTitle>
            <List>
                <ListItem button onClick={() => handleClickKakao()} key={"kakao"}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <img src={KakaoPay} width={'100%'} height={'100%'}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Badge badgeContent={'간편'} color="primary">
                        <text className={'pr-4'}>카카오페이</text>
                    </Badge>} />
                </ListItem>

                <ListItem button onClick={() => handleClickCard()} key={"card"}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <CreditCardIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={'신용/체크 카드'} />
                </ListItem>

            </List>
        </Dialog>
    );
}

PaymentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default PaymentDialog;
