import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import background from "../images/home_background.webp"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import UseWindowDimensions from "../../util/UseWindowDimensions";
import AISelectorModal from "./AISelecterModal";


export default function MainSection1(props){

    const fR = UseWindowDimensions();

    let title1 = "∅ Dripsso";
    let title2 = "극한을 마시다";
    let title3 = " ";

    const introTitle = {

    };

    const styleTitle = {
        fontWeight: 700,
        background: 'radial-gradient(circle at 50% 0%, rgba(255, 255, 255, 1), #929597)',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    };


    const styleSubtitle = {
        fontWeight: 500,
        background: 'radial-gradient(circle at 50% 0%, rgba(255, 255, 255, 1), #929597)',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    };




    return <Row className={'justify-content-md-center'} style={{
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }}>


        <Col md={'auto'} className={'text-center'} style={{marginTop: 140, marginBottom:'20%', minHeight: '50%'}}>
            <text style={{color:'white', fontSize: Math.max(35, 35 * fR), ...styleSubtitle}}>{title1}</text>
            <h3 style={{color:'white', fontSize: Math.min(120, Math.max(120 * fR, 40)), ...styleTitle}}>{title2}</h3>
            {/*<a href={Trial.path} style={{color: '#ec3e7b', fontSize: Math.max(20, 20 * fR)}}>구독하기<ChevronRightIcon/></a>*/}
            <Button
                onClick={()=>{
                    props.onShowAI(true);
                }}
                style={{backgroundColor: '#ec3e7b',  color: '#fff', fontSize: 20, fontWeight: 800, marginTop: 35, borderColor: "#ec3e7b", borderRadius: 30, paddingLeft: 30, paddingRight: 20}}>

                AI 커피 추천 받기<ChevronRightIcon style={{paddingBottom: 3}}/> </Button>
            <h3 style={{color:'white', marginTop:20, marginBottom:220, fontSize: Math.max(16, 16 * fR), fontWeight: 300}}>
                {title3}
            </h3>
        </Col>
    </Row>

}

