import rtd from "../../../../../src/img/rtd.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function RTDCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${rtd}) center`,
            backgroundSize: 'cover',
            objectFit: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>Ready To Drink</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>언제 어디서나 손쉽게</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            이미 최적의 농도로 만들어져 나왔습니다.<br/>
                            가장 만족스럽게 커피를 마실 수 있는 그 순간을 <br/>
                            드립쏘와 함께하세요.

                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
