import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroDecafCard from "../card/intro/IntroDecafCard";
import TasteHerb from "../card/taste/TasteHerb";
import TasteApple from "../card/taste/TasteApple";
import IntroDecafUDCard from "../card/intro/IntroDecafUDCard";
import DecafSwissCard from "../card/Pros/DecafSwissCard";


export default function DecafUDPage(props) {

    const product_id = 'mezpZshFK53hE5iRPqVfsM';

    return <PageLayout
        product_id={product_id}
    >
        <IntroDecafUDCard/>
        <TasteApple/>
        <TasteHerb/>
        <DecafSwissCard/>
        <MicrolotCard/>
        <ShippingCard/>
        <DripCoffeeCard/>
        {/*<RTDCard/>*/}
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
    </PageLayout>

}

