import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import ProductImage from "../../../images/product_real/Brazil1.jpg";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroBrazilCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(32,82,73,0.98),rgba(32,82,73,0.57))'}
        title={'브라질 콜드브루'}
        detail={<>최상의 로스팅,<br/>
            최고의 밸런스.</>}
        description={<>
            기분좋은 쓴맛과 고소함이 잘 조화된 커피.<br/>
            연하고 섬세한 맛과 풍미로 알려진<br/>
            브라질 세라도 & 몬테카멜로.<br/>
            아라비카 원두와 독자적인 기법으로<br/>
            추출된 커피의 풍부함을 느껴보세요.
            </>}
        image={ProductImage}
    />


}
