import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroDecafCard from "../card/intro/IntroDecafCard";
import IntroAnotherGreenCard from "../card/intro/IntroAnotherGreenCard";
import TasteMilk from "../card/taste/TasteMilk";
import TasteSugar from "../card/taste/TasteSugar";
import TasteHerb from "../card/taste/TasteHerb";
import DecafSwissCard from "../card/Pros/DecafSwissCard";
import RealMilkCard from "../card/Pros/RealMilkCard";
import RelatedLatte from "../card/postproduct/RelatedLatte";
import RelatedFlatwhite from "../card/postproduct/RelatedFlatwhite";


export default function AnotherGreenPage(props) {

    const product_id = 'f8obSbpCi53Yt3EgkrA8aZ';

    return <PageLayout
        product_id={product_id}
    >
        <IntroAnotherGreenCard/>
        <TasteMilk/>
        <TasteSugar/>
        <DecafSwissCard/>
        <MicrolotCard/>
        <RealMilkCard/>
        <ShippingCard/>
        <DripCoffeeCard/>
        <RTDCard/>
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
        <RelatedLatte/>
        <RelatedFlatwhite/>
    </PageLayout>

}

