import API from "./api/API";
import Config from "./config";
import Network from "./api/network";
import axios from "axios";


class Login {

    static kakao_redirect_uri = 'https://coffeeplease.co.kr/oauth';
    static kakao_rest_api_key = '30369a24bc1bccb1ca0e457aaec8679f';


    loginKakao = async (access_token) => {
        let response = await API.auth.loginKakao(access_token);
        let after_register = await API.logic.runFunction(Config.functionName, {
            cmd: 'register.after_register'
        });
        return response;
    };

    getKakaoAccessToken = async (code) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "authorization_code");
        urlencoded.append("client_id", Login.kakao_rest_api_key);
        urlencoded.append("redirect_uri", Login.kakao_redirect_uri);
        urlencoded.append("code", code);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch("https://kauth.kakao.com/oauth/token", requestOptions)
            .then(response => {
                return response.json()
            })
            .catch(error => console.log('error', error));
    };

}

export default Login;