import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../images/coffeebottom.png";
import SizingComponent from "../mixin/SizingComponent";
import Button from "react-bootstrap/Button";
import Start from "../../pages/Start";
import Products from "../../pages/Products";
import Trial from "../../pages/Trial";


class MainSection7 extends SizingComponent{

    getStartButtonHidden = () => {
        return this.props.startButtonHidden
    };

    render() {
        if (this.state.width <= 990){
            return <Row className={'justify-content-md-center'} style={{height: '90%',
                background:'linear-gradient(rgba(32,62,73,0.98),rgba(32,62,73,0.77))'}}>
                <Col lg={'6'} className={'text-center'} style={{marginTop: 80, marginBottom:100}}>
                    <h2 className={'text-white'} style={{fontWeight: 300, marginLeft:'0', marginTop: '10%'}}>
                        부담 없이 시작하세요
                    </h2>
                    <h2 className={'text-white'} style={{fontWeight: 1300, marginLeft:'0', marginTop: '0'}}>
                        정기구독시 최대 30% 할인
                    </h2>
                    <a hidden={this.getStartButtonHidden()} href={Trial.path}>
                        <Button style={{height:50, width:150, fontWeight:500, marginLeft:'0', marginTop: 50}}>시작하기</Button>
                    </a>
                </Col>

                <Col lg={'6'} className={'text-center'} style={{marginTop:40, marginBottom:0, marginRight:0}}>
                    <img src={CoffeeImage} height={'600'} width={'auto'} />
                </Col>
            </Row>
        }else{
            return <Row className={'justify-content-md-center'} style={{height: '90%',
                background:'linear-gradient(rgba(32,62,73,0.98),rgba(32,62,73,0.77))'}}>
                <Col lg={'6'} className={'text-left'} style={{marginTop: 80, marginBottom:100}}>
                    <h1 className={'text-white'} style={{fontWeight: 300, marginLeft:'35%', marginTop: '10%'}}>
                        부담 없이 시작해요
                    </h1>
                    <h1 className={'text-white'} style={{fontWeight: 1300, marginLeft:'35%', marginTop: '0'}}>
                        정기구독시 최대<br></br>30% 할인
                    </h1>
                    <a hidden={this.getStartButtonHidden()} href={Trial.path}>
                        <Button style={{height:50, width:150, fontWeight:500, marginLeft:'35%', marginTop: 50}}>시작하기</Button>
                    </a>
                </Col>

                <Col lg={'6'} className={'text-center'} style={{marginTop:40, marginBottom:0, marginRight:0, paddingRight:-10}}>
                    <img src={CoffeeImage} height={'600'} width={'auto'} />
                </Col>
            </Row>
        }

    }

}


export default MainSection7;
