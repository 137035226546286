import * as React from "react";


class InputText extends React.Component{

    state = {
        valid: false,
        invalid_message: this.props.invalid_message,
        danger_invalid: false,
    };


    getInputStyle = () => {
        if (this.state.valid){
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #007bff',
                outline:'none',
                boxShadow:'none',
                height: 40,
                width: '90%', maxWidth: 355,
            }
        }else{
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #888',
                outline:'none',
                boxShadow:'none',
                height: 40,
                width: '90%', maxWidth: 355,
            }
        }
    };

    onChange = (e) => {
        const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let value = e.target.value;
        let valid = this.props.onChange(value);
        if (this.props.type === 'password'){
            this.setState({valid: false});
            if (korean.test(value)){
                this.setState({
                    invalid_message: "비밀번호에 한글이 입력되고 있습니다.",
                    danger_invalid: true
                });
            }else{
                this.setState({
                    invalid_message: this.props.invalid_message,
                    danger_invalid: false
                });
            }
        }else{
            this.setState({valid})
        }
    };

    getNotice = () => {
        if (this.state.valid){
            return <text className={'mt-1 text-primary'} style={{fontSize: 12}}>{this.props.valid_message}</text>
        }else{
            if (this.state.danger_invalid){
                return <text className={'mt-1 text-danger'} style={{fontSize: 12}}>{this.state.invalid_message}</text>
            }else{
                return <text className={'mt-1'} style={{fontSize: 12}}>{this.state.invalid_message}</text>
            }
        }
    };

    render() {
        return <><input style={this.getInputStyle()}
                      type={this.props.type}
                      placeholder={this.props.placeholder}
                      autoFocus={this.props.autoFocus}
                      onChange={this.onChange}/>
                      <br/>
            {this.getNotice()}
            <br/>
            </>
    }

}

export default InputText;
