import React, { Component } from "react";
import Faq from "react-faq-component";
import {Col, Row} from "react-bootstrap";

const subscriptionFAQ = {
    title: "정기구독 이용안내",
    rows: [
        {
            title: "정기구독이 무엇인가요?",
            content: "결제시 원하시는 배송 주기를 선택하시면 주기마다 정기적으로 결제가 청구되고, " +
                "상품이 출고됩니다. 정기 결제는 언제든지 취소 및 연기하실 수 있습니다.",
        },
        {
            title: "한번만 결제하고 싶어요!",
            content:
                "장바구니에 담은 상품을 주문하실 때 한번만 결제하기 버튼을 눌러 결제해주세요. 단건 결제의 경우 구독 할인 혜택이 적용되지 않습니다.",
        },
        {
            title: "구독은 어떻게 해요?",
            content:
                "상품보기 페이지에서 원하시는 상품 구매하기 버튼을 눌러 장바구니에 담고, 장바구니 팝업에서 주문하기 버튼을 눌러 상품을 구독하실 수 있습니다.",
        },
        {
            title: '정기구독이라면 매주 시켜야 하나요?',
            content: "주문하실때 혹은 주문하신 이후에도 마이페이지 > 구독관리 메뉴에서 배송 주기를 수정하실수있습니다. 1주, 2주, 3주 주기로 나누어져있습니다."
        },
        {
            title: '정기구독 취소는 어떻게 하나요?',
            content: '마이페이지 > 구독관리 > 정기구독 수정에서 구독 취소를 누르시면 정기결제가 취소됩니다.'
        }
    ],
};


const productFAQ = {
    title: "상품 안내",
    rows: [
        {
            title: "유통기한이 어떻게 되나요?",
            content: "커피류의 유통기한은 약 30일, 라떼류의 유통기한은 약 10일이며, 개봉후에 바로 섭취하는 것을 권장합니다. "
        },
        {
            title: "커피는 맛있나요?",
            content:
                "오랜 시간 동안 상급 퀄리티의 원두로부터 콜드브루잉하여 방부제 등 화학 첨가물을 일체 첨가하지 않은 채 1-2일 내 배송되는 커피이기에, " +
                "갓 내린 신선한 커피의 산도와 바디감을 맛보실 수 있습니다. "
        },
    ],
};

const orderFAQ = {
    title: "배송 안내",
    rows: [
        {
            title: "배송 업체와 배송 소요일을 알고 싶어요.",
            content: "배송 업체 : CJ대한통운, \n" +
                "배송 지역 : 전국, \n" +
                "배송비 : 무료배송, \n" +
                "배송 소요일 : 업무일 기준 출고 후 1-2일 정도 소요됩니다. 지역 및 택배사 사정에 따라 지연될 수 있으며, 천재지변 및 예외 적인 사유로 인해 다소 지연될 수 있는 점 양해 부탁드립니다.\n" +
                "업무일 기준 오후 1시 이전 결제 시: 당일 출고, \n" +
                " 오후 1시 이후 결제 시: 익일 출고됩니다."
        },
        {
            title: "받은 제품에 이상이 있는 것 같아요.",
            content:
                "수령하신 제품에 하자가 있을 시 고객센터로 문의주시면 100% 환불 및 반품 처리를 해드립니다. " +
                "수제로 당일 제조 되는 제품의 특성상 단숨 변심에 의한 환불은 불가능 합니다. \n" +
                "당일 주문된 건의 경우, 출고건에 대해서 취소가 불가능 합니다. \n" +
                "이미 출고된 상품의 경우 배송지를 변경하실 수 없습니다."
        },

    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "grey",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

class FAQSection extends React.Component{
    render() {
        return (
            <>
                <Row className={'justify-content-center mb-5'}>
                    <Col md={9}>
                        <Faq data={subscriptionFAQ} styles={styles} config={config} />
                    </Col>
                </Row>
                <Row className={'justify-content-center mb-5'}>
                    <Col md={9}>
                        <Faq data={productFAQ} styles={styles} config={config} />
                    </Col>
                </Row>
                <Row className={'justify-content-center mb-5'}>
                    <Col md={9}>
                        <Faq data={orderFAQ} styles={styles} config={config} />
                    </Col>
                </Row>
            </>
        );
    }
};

export default FAQSection;
