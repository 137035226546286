import React from 'react';
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import InputEdit from "../components/mypage/InputEdit";
import API from "../shared/service/api/API";
import Home from "./Home";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import Time from "../util/Time";
import Product from "../util/Product";
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import SubscriptionDetail from "../components/mypage/SubscriptionDetail";
import EditIcon from '@material-ui/icons/Edit';
import ChangePassword from "../components/mypage/ChangePassword";
import queryString from "query-string";
import Random from "../util/Random";
import EnrollPaymentMethod from "../util/RequestPayment";
import Payment from "../shared/service/Payment";
import PaymentDialog from "../components/checkout/PaymentDialog";
import Service from "../shared/service/Service";
import Alert from "react-bootstrap/Alert";
import {CopyToClipboard} from 'react-copy-to-clipboard';

class MyPage extends React.Component {

    static path = "/mypage";

    state = {
        me: {},
        isEmailLogin: false,
        showAddPaymentMethod: false,
        paymentMethodCards: [],
        openChangePasswordModal: false,
        openPaymentMethodDialog: false,
    };

    query = queryString.parse(window.location.search);

    showSubscription = () => {
        return this.query.show_subscription === '1'
    };

    setShowSubscription = (show) => {
        if (show){
            this.query.show_subscription = '1';
        }else{
            this.query.show_subscription = '0';
        }
    };

    componentDidMount() {
        this.fetch();
    }

    handleClickCheckout = () => {
        this.setState({
            openPaymentMethodDialog: true
        })
    };

    handlePaymentModalClose = () => {
        this.setState({
            openPaymentMethodDialog: false
        })
    };

    fetch = () => {
        this.fetchMe(async () => {
            if (this.showSubscription()){
                let me = await API.auth.getMe();
                let response = await API.database.queryItems('subscription', [{
                    condition: 'eq',
                    field: 'user_id',
                    value: me.id,
                    option: 'and'
                }], 100, true);
                let items = response.items;
                if (items){
                    await this.setSubscriptionContent(items[0].id);
                }
                alert("구독이 완료되었습니다.\n다음 주문 내용을 수정해보세요!");
            }else{
                await this.fetchSubscriptionCards();
                this.setMainCardSubscriptions();
                await this.fetchPaymentMethodCards();
                await this.fetchOrderCards();
            }
        });

    };

     logout = async () => {
         let success = await API.auth.logout();
         if (success){
            window.location.href = '/';
         }else{
            alert("로그아웃 실패");
         }
     };

     fetchMe = (callback) => {
         API.auth.getMe().then(me => {
             if (!me){
                 window.location.href = '/';
             }
             this.setState({
                 me
             }, callback);
         });
     };

     setMyInfo = (field, value) => {
         if (field === 'name' && value.length < 2){
             alert("이름은 두 글자 이상 입력해주세요.");
             window.location.reload();
             return;
         }
         if (field === 'phone' && value.length < 10){
             alert("핸드폰 번호는 10자 이상 입력해주세요");
             window.location.reload();
             return;
         }

         API.auth.setMe(field, value).then(data => {
            this.fetch();
         });
     };

    withdrwal = async () => {
        if (!window.confirm("정말 탈퇴하시겠습니까?")){
            return;
        }
        // 정기결제 목록 있는지 확인
        let me = await API.auth.getMe();
        let response = await API.database.queryItems('subscription', [
            {condition: 'eq', field: 'user_id', value: me.id, option: 'and'},
        ]);
        if (response.items && response.items.length > 0){
            alert("정기구독을 모두 해지해주셔야 탈퇴하실 수 있습니다.");
            return;
        }
        let data = await API.auth.withdrwal();
        if (data.success){
            alert("탈퇴되었습니다.");
            this.props.history.push(Home.path);
        }else{
            alert(JSON.stringify(data.error.message));
        }

    };

    getLoginMethod = (me) => {
        if (me.login_method === 'email_login'){
            return "이메일 로그인";
        }else if (me.login_method === 'kakao_login'){
            return "카카오 로그인";
        }
    };

    getPaymentMethodCard = (payment_method) => {
        let paymentType = '신용/체크 카드';
        let extraInfo = <></>;
        if (payment_method.type === 'kakaopay'){
            paymentType = '카카오페이'
        }else if (payment_method.type === 'card'){
            paymentType = '신용/체크 카드';
            extraInfo = <><label className={'mt-3'} style={{fontSize: 13, color: '#888'}}>카드 번호</label><br/>
                <b>{payment_method.card_number}</b></>;
        }

        return <Col md={6} className={'mt-3'}>
            <Card style={{background: '#fafafa', height: 160}}>
                <CardBody>
                    <Row>
                        <Col xs={8}>
                            <label style={{fontSize: 13, color: '#888'}}>결제 수단 종류</label><br/>
                            <b>{paymentType}</b>

                            <br/>
                            {extraInfo}
                        </Col>

                        <Col xs={4} className={'text-right'}>
                            <IconButton aria-label="delete" onClick={async ()=>{
                                let me = await API.auth.getMe();
                                let response = await API.database.queryItems('payment_method', [{
                                    condition: 'eq',
                                    field: 'user_id',
                                    value: me.id,
                                    option: 'and'
                                }]);
                                let items = response.items;
                                if (items.length > 1){
                                    if (!window.confirm("정말 삭제하시겠습니까?")){
                                        return;
                                    }
                                    let response = await API.database.deleteItem(payment_method.id);
                                    if (response.success){
                                        window.location.reload();
                                    }
                                }else{
                                    alert("두개 이상의 결제 수단이 있을때 삭제할 수 있습니다.");
                                }
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Col>
    };

    getOrderCard = async (order) => {
        let shipping_info = order.shipping_info;
        let shipping_number = order.shipping_number;
        if (shipping_number){
            shipping_number = shipping_number.replace('-', '');
            shipping_number = shipping_number.replace('-', '');
            shipping_number = shipping_number.replace('-', '');
        }
        let statusLabel = '상품 준비중';
        let statusColor = '#000';
        if (order.status === 'standby') {
            statusLabel = '상품 준비중';
            statusColor = '#000'
        } else if (order.status === 'shipping') {
            statusLabel = '배송중';
            statusColor = '#3376f1'
        } else if (order.status === 'complete') {
            statusLabel = '배송완료';
            statusColor = '#3376f1'
        } else if (order.status === 'cancelled') {
            statusLabel = '취소됨';
            statusColor = '#f37661'
        }
        let productName = await Product.getName(order.product_counts);
        return <Col md={12} className={'mt-3'}>
            <Card style={{background: '#fafafa'}}>
                <CardBody>
                    <Row>
                        <Col xs={12}>
                            <text style={{fontSize: 14, color: statusColor}}>{statusLabel} </text>
                            <text style={{fontSize: 14, color: '#999'}}> | {order.price.toLocaleString()}원 </text>
                            <br/>
                            <text style={{fontSize: 13, color: '#999'}}> 주문번호 {order.id} </text>
                            <br/>
                            <text style={{
                                fontSize: 13,
                                color: '#888'
                            }}>{Time.timestampToDateTimeString(order.creation_date)}</text>
                            <br/>
                            <b className={'mt-2'}>{productName}</b>
                            <br/>
                            <text>
                                {shipping_info.buyer_addr_road} {shipping_info.buyer_addr_detail}
                            </text>

                            <br/>

                            <div className={'mt-2 text-right'}>

                                <Button variant={'light'} onClick={()=>{
                                    alert("교환 및 환불은 화면 우측 하단의 채널톡 버튼을 눌러 문의하실 수 있습니다.")
                                }}>
                                    교환 환불 문의
                                </Button>

                                {/*<Button className={'mr-1'} variant={'outline-primary'}>*/}
                                {/*    후기 작성*/}
                                {/*</Button>*/}
                                <Button onClick={()=>{
                                    if (shipping_number){
                                        window.open('https://www.cjlogistics.com/ko/tool/parcel/tracking?gnbInvcNo=' + shipping_number, '_blank');
                                    }else{
                                        alert("아직 송장번호가 등록되지 않았습니다.");
                                    }
                                }} variant="contained" color="primary">
                                    배송조회
                                </Button>
                            </div>


                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Col>
    };

    getStatusColor = (subscription) => {
        let statusColor = '#3376f1';
        if (subscription.enabled){
            statusColor = '#3376f1';
        }else{
            statusColor = '#f37661';
        }
        return statusColor
    };

    getStatusLabel = (subscription) => {
        let statusLabel = '구독중';
        if (subscription.enabled){
            statusLabel = '구독중';
        }else{
            statusLabel = '중단됨';
        }
        return statusLabel;
    };

    getSubscriptionCard = async (subscription) => {
        let name = await Product.getName(subscription.product_counts);
        let statusLabel = this.getStatusLabel(subscription);
        let statusColor = this.getStatusColor(subscription);

        return <Col md={12} className={'mt-3'} style={{cursor: 'pointer'}} onClick={()=>{
            this.setSubscriptionContent(subscription.id);
        }}>
            <Card style={{background: '#fafafa'}}>
                <CardBody>
                    <Row>
                        <Col xs={10}>
                            <text style={{
                                fontSize: 14,
                                color: statusColor
                            }}>
                                {statusLabel}
                            </text>
                            <br/>
                            <text style={{
                                fontSize: 13,
                                color: '#888'
                            }}> {Time.timestampToDateString(subscription.next_payment_date)} 결제 예정</text><br/>
                            <b>{name}</b>

                            <br/>
                            <h7>{subscription.shipping_info.buyer_addr_road} {subscription.shipping_info.buyer_addr_detail}</h7>

                            <br/>

                        </Col>
                        <Col xs={2} className={'text-right'}>
                            <EditIcon/>
                        </Col>

                    </Row>

                </CardBody>
            </Card>
        </Col>
    };

    setSubscriptionContent = async (subscription_id) => {
        let subscription = await API.database.getItem(subscription_id);
        subscription = subscription.item;

        let subscriptionCard = <>

            <SubscriptionDetail subscription={subscription}
                                                   getBack={()=>this.setMainCardSubscriptions()}
                                                   onSave={async (subscription)=>{
                                                       let response = API.database.updateItem(subscription.id, subscription);
                                                       this.setState({
                                                           mainCard: subscriptionCard
                                                       });
                                                       this.fetchSubscriptionCards();
                                                   }}/></>;
        this.setState({
            mainCard: subscriptionCard
        });
    };

    fetchPaymentMethodCards = async () => {
        let me = await API.auth.getMe();
        let response = await API.database.queryItems('payment_method', [{
            condition: 'eq',
            field: 'user_id',
            value: me.id,
            option: 'and'
        }], 100, true);
        let items = response.items;
        let cards = items.map(item => {
            return this.getPaymentMethodCard(item);
        });
        this.setState({
            paymentMethodCards: cards
        })
    };

    fetchOrderCards = async () => {
        let me = await API.auth.getMe();
        let response = await API.database.queryItems('new_order', [{
            condition: 'eq',
            field: 'user_id',
            value: me.id,
            option: 'and'
        }], 100, true);
        let items = response.items;
        let cards = [];
        for (let item of items){
            let card = await this.getOrderCard(item);
            cards.push(card);
        }
        this.setState({
            orderCards: cards
        })
    };

    fetchSubscriptionCards = async () => {
        let me = await API.auth.getMe();
        let response = await API.database.queryItems('subscription', [{
            condition: 'eq',
            field: 'user_id',
            value: me.id,
            option: 'and'
        }], 100, true);
        let items = response.items;
        let cards = [];
        for (let item of items){
            cards.push(await this.getSubscriptionCard(item));
        }
        this.setState({
            subscriptionCards: cards
        });
    };

    onClickKakao = async () => {
        this.handlePaymentModalClose();
        let payment_method_key = Random.uuidv4();
        let me = await API.auth.getMe();
        let response = await EnrollPaymentMethod.createPaymentMethod('kakaopay', payment_method_key, me.email, me.name, me.phone);
        this.setState({
            busy: true
        });
        if (response.success){
            await Service.payment.addPaymentMethod(payment_method_key, 'kakaopay', null, null);
            alert("성공하였습니다.");
            await this.fetchPaymentMethodCards();
        }else{
            alert(response.error_msg);
        }
        this.setState({
            busy: false
        });
    };

    onClickCard = async () => {
        this.handlePaymentModalClose();
        let payment_method_key = Random.uuidv4();
        let me = await API.auth.getMe();
        let response = await EnrollPaymentMethod.createPaymentMethod('html5_inicis', payment_method_key, me.email, me.name, me.phone);
        this.setState({
            busy: true
        });
        if (response.success){
            await Service.payment.addPaymentMethod(payment_method_key, 'html5_inicis', response.card_name, response.card_number);
            alert("성공하였습니다.");
            await this.fetchPaymentMethodCards();
        }else{
            alert(response.error_msg);
        }
        this.setState({
            busy: false
        });
    };

    setMainCardMyInfo = () => {
        let myInfo = <Card>
            <CardBody>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 400}}>
                        내 정보 수정
                    </h5>
                </div>
                <hr className={'mb-4'}/>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 700}}>
                        기본 정보
                    </h5>

                    <div className={'ml-1 mt-2 pt-3'}>
                        <InputEdit title={'이름'} value={this.state.me.name} onChange={(value)=>{
                            this.setMyInfo('name', value);
                        }}/>
                    </div>

                    <div className={'ml-1 mt-2 pt-3'}>
                        <InputEdit title={'핸드폰번호'} value={this.state.me.phone} onChange={(value)=>{
                            this.setMyInfo('phone', value);
                        }}/>
                    </div>

                    <div className={'ml-1 mt-2 pt-3'}>
                        <InputEdit readOnly title={'로그인 방식'} value={this.getLoginMethod(this.state.me)} onChange={(value)=>{

                        }}/>
                    </div>

                    <div hidden={this.state.me.login_method !== 'email_login'} className={'ml-1 mt-2 pt-3'}>
                        <InputEdit type={'password'} title={'비밀번호'} value={""} onChange={(newPassword)=>{
                            if (newPassword.length >= 6) {
                                this.setState({
                                    openChangePasswordModal: true,
                                    newPassword
                                })
                            }else{
                                alert("비밀번호는 6자 이상입니다.");
                            }
                        }}/>
                    </div>
                    <hr className={'mt-4 mb-4'}/>
                    <Row>


                        <Col xs={8}>
                            <h5 style={{fontWeight: 700}}>
                                결제 수단
                            </h5>
                        </Col>
                        <Col xs={4} style={{
                            textAlign: 'right'
                        }}>
                            <IconButton aria-label="add" onClick={async ()=>{
                                this.handleClickCheckout();
                            }}>
                                <AddIcon/>
                            </IconButton>
                        </Col>
                    </Row>

                    <div>
                        <Row>
                            {this.state.paymentMethodCards}
                        </Row>
                    </div>

                </div>


            </CardBody>
            <CardFooter>
                <Button onClick={this.withdrwal} className={'btn'} variant={''} style={{fontSize: 13, color:'#888'}}>
                    회원 탈퇴
                </Button>
            </CardFooter>

        </Card>;
        this.setState({
            mainCard: myInfo
        });
    };


    setMainCardOrders = () => {
        let myInfo = <Card>
            <CardBody>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 400}}>
                        주문 조회
                    </h5>
                </div>
                <hr className={'mb-4'}/>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 700}}>
                        내 주문 기록
                    </h5>

                    <div>
                        <Row>
                            {this.state.orderCards}
                        </Row>
                    </div>

                </div>


            </CardBody>
        </Card>;
        this.setState({
            mainCard: myInfo
        });
    };

    setMainCardSubscriptions = () => {
        if (this.showSubscription()){
            this.setShowSubscription(false);
            this.fetchSubscriptionCards();
        }
        let myInfo = <Card>
            <CardBody>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 400}}>
                        구독 관리
                    </h5>
                </div>
                <hr className={'mb-4'}/>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 700}}>
                        구독중인 상품
                    </h5>

                    <div>
                        <Row>
                            {this.state.subscriptionCards}
                        </Row>
                    </div>

                </div>


            </CardBody>
        </Card>;
        this.setState({
            mainCard: myInfo
        });
    };

    getMileageRecords = async () => {
        let response = await API.database.queryItems('new_mileage_record', [], 100, true);
        let items = response.items;
        return items.map(item => {
            if (item.status === 'save'){
                return <Alert variant={'success'}>
                    <div style={{fontSize: 11.5}}>
                        <b>{item.inbound.toLocaleString()}원 적립</b> {item.buyer_email}
                        <div className={'text-right'} style={{float:'right'}}>{Time.timestampToDateString(item.creation_date)}</div>
                    </div>
                </Alert>
            }else if (item.status === 'expense'){
                return <Alert variant={'warning'}>
                    <div style={{fontSize: 11.5}}>
                        <b>{item.outbound.toLocaleString()}원 사용</b> 남은 금액 {item.balance.toLocaleString()}원
                        <div className={'text-right'} style={{float:'right'}}>{Time.timestampToDateString(item.creation_date)}</div>
                    </div>
                </Alert>
            }
        });
    };

    getRecommenderUrl = () => {
        return "https://coffeeplease.co.kr/trial?recommender_email=" + this.state.me.email;
    };

    setMainRecommender = async () => {
        this.setState({
            recommenderShown: true
        });
        let milageRecords = await this.getMileageRecords();
        let myInfo = <Card>
            <CardBody>
                <div className={'ml-2'}>
                    <h5 style={{fontWeight: 400}}>
                        추천인 관리
                    </h5>
                </div>
                <hr className={'mb-4'}/>
                <div className={'ml-2'}>

                    <h5 style={{fontWeight: 700}}>
                        내 마일리지: {(this.state.me.mileage ? this.state.me.mileage: 0).toLocaleString()}원
                    </h5>
                    <p style={{fontSize: 13}}>
                        <b>마일리지는 매 정기주문 마다 자동으로 사용되어 할인 됩니다.</b>
                    </p>


                    <br/>
                    <h5 style={{fontWeight: 700}}>
                        내 추천인 코드
                    </h5>
                    <p style={{fontSize: 12}}>
                        다른 사람이 내 이메일을 추천인으로 입력하면 결제금액의 10%를 적립받습니다.
                    </p>

                    <h6>
                        {this.state.me.email}
                        <CopyToClipboard text={this.getRecommenderUrl()}
                                         onCopy={() => {
                                             alert("이메일 복사 완료! 추천인 결제시 카카오톡 혹은 SMS로 알려드릴게요.")
                                         }}>
                            <Button variant={'btn-success'}>복사</Button>
                        </CopyToClipboard>

                    </h6>


                    <Alert variant={'info'}>
                        추천한 사람이 결제하면 매 정기결제 금액의 <b>10%</b> 를 적립 받습니다!
                    </Alert>


                </div>

                <div className={'ml-2 mt-4'}>
                    <h5 style={{fontWeight: 700}} className={'mb-4'}>
                        마일리지 적립 내역
                    </h5>

                    {milageRecords}

                </div>


            </CardBody>
        </Card>;
        this.setState({
            mainCard: myInfo
        });
    };

    render() {
        return (<div style={{
            marginTop: 40,
            background:'linear-gradient(rgba(32,62,73,0.08), rgba(32,62,73,0.0))',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <TopMenu/>

            <PaymentDialog
                open={this.state.openPaymentMethodDialog}
                onClickKakao={this.onClickKakao}
                onClickCard={this.onClickCard}
                onClose={this.handlePaymentModalClose} />

            <Container style={{
                minHeight: 'calc(100vh - 350px)',
            }}>
                <Row style={{marginTop: 60, marginBottom: 60}}>
                    <Col lg={4} style={{marginTop: 10, marginBottom: 10}}>
                        <Card>
                            <CardBody>
                                <div className={'ml-2'}>
                                    <h3 style={{fontWeight: 700}}>
                                        {this.state.me.name}
                                    </h3>
                                    <h7 style={{fontSize: 13, color: '#888'}}>
                                        {this.state.me.email}
                                    </h7>
                                </div>

                                <br/>

                                <Button className={'btn'} variant={''} onClick={this.setMainCardSubscriptions}>
                                    구독 관리
                                </Button>

                                <br/>
                                <hr/>
                                <Button className={'btn'} variant={''} onClick={this.setMainCardOrders}>
                                    주문 조회
                                </Button>
                                <br/>
                                <hr/>
                                <Button className={'btn'} variant={''} onClick={this.setMainCardMyInfo}>
                                    내 정보 수정
                                </Button>
                                <br/>
                                <hr/>
                                <Button className={'btn'} variant={''} onClick={this.setMainRecommender}>
                                    <b>마일리지 / 추천인 관리</b>
                                </Button>


                            </CardBody>
                            <CardFooter>
                                <Button className={'btn'} variant={''} style={{fontSize: 13, color:'#888'}} onClick={this.logout}>
                                    로그아웃
                                </Button>
                            </CardFooter>
                        </Card>

                    </Col>



                    <Col lg={8} style={{marginTop: 10, marginBottom: 10}}>
                        {this.state.recommenderShown ? null:
                        <Row>
                            <Col xs={12}>
                                <Alert variant={'info'} style={{cursor: 'pointer'}} onClick={()=>{
                                    this.setMainRecommender()
                                }}>
                                    <b>90% 이상</b>의 할인 혜택을 받아보세요! <b>더 알아보기</b>
                                </Alert>
                            </Col>
                        </Row>}

                        {this.state.mainCard}
                    </Col>
                </Row>
            </Container>


            <ChangePassword open={this.state.openChangePasswordModal} newPassword={this.state.newPassword} onClose={()=>{
                this.setState({
                    openChangePasswordModal: false,
                    newPassword: null
                })
            }}/>

            <Footer/>
        </div>);
    }

}

export default MyPage;
