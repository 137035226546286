

class Time {

    static timestampToDateTimeString = (timestamp) => {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let ampm = '오전';
        if (hour > 12){
            hour -= 12;
            ampm = '오후';
        }
        if (hour === 0){
            hour = 12;
        }

        return year + '년 ' + month + '월 ' + day + '일 ' + ampm + ' ' + hour + '시 ' + minute + '분'
    };

    static timestampToDateString = (timestamp) => {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return year + '년 ' + month + '월 ' + day + '일 ';
    };

    static timestampToDateStringYMD = (timestamp) => {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month += '';
        if (month.length === 1){
            month = '0' + month;
        }
        day += '';
        if (day.length === 1){
            day = '0' + day;
        }
        return year + '' + month + '' + day + '';
    };

}

export default Time;
