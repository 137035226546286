import SizingComponent from "../mixin/SizingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Phone from "../../components/images/help/chat.png";
import Email from "../../components/images/help/email.png";

class HelpTopSection extends SizingComponent{

    render() {

        let header1 = "도움이 필요하신가요?";
        let body = <>평일 오전9시 - 오후6시</>;

        return <Row className={'justify-content-md-center mb-5'} style={{background: 'linear-gradient(rgba(12,14,14,0.98),rgba(14,14,14,0.77))'}}>

            <Col md={'12'} className={'text-center'}>
                <div style={{marginTop: 140, marginBottom: 75}}>
                    {/*<img src={GoldMedal} width={80} height={80} style={{marginBottom: 50}}/>*/}
                    <h2 className={'text-white'}>{header1}</h2>
                    <h6 className={'text-white'}>{body}</h6>

                    <Container>
                        <Row className={'justify-content-center'}>
                            <Col md={3} className={'mt-5'}>
                                <img src={Phone} height={25} width={'auto'} className={'mr-1'}/>
                                <text className={'text-white ml-1'}><b>카카오톡</b></text>
                                <br/>
                                <text style={{fontSize: 11}} className={'text-white'}>@커피플리즈</text>

                            </Col>

                        </Row>
                    </Container>
                </div>
            </Col>

        </Row>
    }

}

export default HelpTopSection;
