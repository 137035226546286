
import Button from "react-bootstrap/Button";
import * as React from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


export default function SeeMoreButton(props) {

    return <Button
        onClick={()=>{
            if (props.onClick){
                props.onClick();
            }
        }}
        style={{backgroundColor: '#fff',  color: '#ec3e7b', fontSize: 16, fontWeight: 600, marginTop: 35, borderColor: "#fff", borderRadius: 30, paddingLeft: 30, paddingRight: 20}}>더 알아보기 <ChevronRightIcon style={{paddingBottom: 3}}/>
    </Button>

}
