import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home, About } from '../pages';
import Start from "../pages/Start";
import Products from "../pages/Products";
import Login from "../pages/Login";
import MyPage from "../pages/MyPage";
import Help from "../pages/Help";
import Checkout from "../pages/Checkout";
import ChangePassword from "../pages/ChangePassword";
import PaymentRedirect from "../pages/PaymentRedirect";
import Oauth from "../pages/Oauth";
import Trial from "../pages/Trial";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import LampBlackPage from "../components/store/page/LampBlackPage";
import BrilliantPinkPage from "../components/store/page/BrilliantPinkPage";
import DecafPage from "../components/store/page/DecafPage";
import CobaltVioletPage from "../components/store/page/CobaltVioletPage";
import AnotherGreenPage from "../components/store/page/AnotherGreenPage";
import FlatWhite from "../components/main/ProductSection/FlatWhite";
import FlatWhitePage from "../components/store/page/FlatWhitePage";
import BrazilPage from "../components/store/page/BrazilPage";
import EthiopiaPage from "../components/store/page/EthiopiaPage";
import LattePage from "../components/store/page/LattePage";
import MilkTeaLattePage from "../components/store/page/MilkteaLattePage";
import TaroLattePage from "../components/store/page/TaroLattePage";
import GreenTeaLattePage from "../components/store/page/GreenTeaLattePage";
import BrilliantPinkUDPage from "../components/store/page/BrilliantPinkUDPage";
import LampBlackUDPage from "../components/store/page/LampBlackUDPage";
import CobaltVioletUDPage from "../components/store/page/CobaltVioletUDPage";
import DecafUDPage from "../components/store/page/DecafUDPage";
import CobaltVioletBeanPage from "../components/store/page/CobaltVioletBeanPage";
import BrilliantPinkBeanPage from "../components/store/page/BrilliantPinkBeanPage";
import LampBlackBeanPage from "../components/store/page/LampBlackBeanPage";
import Home2 from "../pages/Home2";
elementScrollIntoViewPolyfill();


class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={Login.path} component={Login}/>
                <Route exact path={Start.path} component={Start}/>
                <Route exact path={'/products'} component={Products}/>
                <Route exact path={MyPage.path} component={MyPage}/>
                <Route exact path={Help.path} component={Help}/>
                <Route exact path={Checkout.path} component={Checkout}/>
                <Route exact path={ChangePassword.path2} component={ChangePassword}/>
                <Route exact path={PaymentRedirect.path} component={PaymentRedirect}/>
                <Route exact path={Oauth.path} component={Oauth}/>
                <Route exact path={Trial.path} component={Trial}/>
                <Route exact path={'/'} component={Home2}/>

                <Route exact path={'/products/lamp_black'} component={LampBlackPage}/>
                <Route exact path={'/products/brilliant_pink'} component={BrilliantPinkPage}/>
                <Route exact path={'/products/decaf'} component={DecafPage}/>
                <Route exact path={'/products/cobalt_violet'} component={CobaltVioletPage}/>
                <Route exact path={'/products/another_green'} component={AnotherGreenPage}/>
                <Route exact path={'/products/flat_white'} component={FlatWhitePage}/>


                <Route exact path={'/products/brazil'} component={BrazilPage}/>
                <Route exact path={'/products/ethiopia'} component={EthiopiaPage}/>
                <Route exact path={'/products/latte'} component={LattePage}/>


                <Route exact path={'/products/milktealatte'} component={MilkTeaLattePage}/>
                <Route exact path={'/products/tarolatte'} component={TaroLattePage}/>
                <Route exact path={'/products/greenlatte'} component={GreenTeaLattePage}/>


                    <Route exact path={'/products/brilliant_pink_undiluted'} component={BrilliantPinkUDPage}/>
                    <Route exact path={'/products/lamp_black_undiluted'} component={LampBlackUDPage}/>
                <Route exact path={'/products/cobalt_violet_undiluted'} component={CobaltVioletUDPage}/>
                <Route exact path={'/products/cobalt_violet_undiluted_glass'} component={CobaltVioletUDPage}/>
                    <Route exact path={'/products/decaf_undiluted'} component={DecafUDPage}/>


                <Route exact path={'/products/cobalt_violet_bean'} component={CobaltVioletBeanPage}/>
                <Route exact path={'/products/brilliant_pink_bean'} component={BrilliantPinkBeanPage}/>
                <Route exact path={'/products/lamp_black_bean'} component={LampBlackBeanPage}/>




            </Switch>
        );
    }
}

export default App;
