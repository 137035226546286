


class CartProduct {

    static instance = new CartProduct();

    cartProducts = "cartProducts";

    setJson = (key, json) => {
        let jsonString = JSON.stringify({
            json: json
        });
        window.localStorage.setItem(key, jsonString);
    };

    getJson = (key) => {
        let jsonString = window.localStorage.getItem(key);
        let json = JSON.parse(jsonString);
        if (json){
            json = json.json;
            return json;
        }
        return null;
    };

    getProductIds = () => {
        let products = this.getJson(this.cartProducts);
        if (products){
            products = products.filter(product=>product);
            return products;
        }
        return [];
    };

    appendProductId = (product) => {
        let products = this.getJson(this.cartProducts);
        if (!products){
            products = [];
        }
        products.push(product);
        this.setJson(this.cartProducts, products);
    };

    removeOneProductId = (productId) => {
        let productIds = this.getJson(this.cartProducts);
        let newProductIds = [];
        let removed = false;
        for (let i = productIds.length - 1; i >= 0; i--){
            let _productId = productIds[i];
            if (productId === _productId && !removed){
                removed = true;
            }else{
                newProductIds.push(_productId);
            }
        }
        newProductIds = newProductIds.reverse();
        this.setJson(this.cartProducts, newProductIds);
    };

    removeProducts = (productId) => {
        let productIds = this.getJson(this.cartProducts);
        productIds = productIds.filter(_productId => {
            return _productId !== productId
        });
        this.setJson(this.cartProducts, productIds);
    };

    clear = () => {
        this.setJson(this.cartProducts, []);
    };

}

export default CartProduct;
