import * as React from "react";
import {Button, Modal, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Icon from "../shared/res/Icon";
import CartProduct from "../shared/service/CartProduct";
import API from "../shared/service/api/API";
import Image from "./mixin/Image";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import Config from "../shared/service/config";
import Color from "../shared/Color";


class CheckoutContainer extends React.Component{

    state = {
        productsPrice: 0,
        totalPrice: 0,
        shippingPrice: 0,
        productsUnitPrice: 0,
        totalUnitPrice: 0,
        showShippingAlert: false,
        freePrice: 3500
    };

    busy = false;

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadProductCells()
    }

    componentDidMount() {
        this.loadProductCells();
        this.fetchFreePrice();
    }

    handleClose = () => {
        this.props.onClose();
    };

    isTrial = () => {
        return this.props.isTrial;
    };

    getProductCell = (product) => {
        let quantity = product.quantity;
        let title = product.title;
        let detail = product.detail;
        let imageId = product.image_id;
        let price = product.subscription_price;
        let unitPrice = product.unit_price;
        return <><Row className={'justify-content-center mb-2'}>
            <Col xs={4} className={'text-center'}>
                <Image imageId={imageId} style={{
                    width: '70%',
                    borderRadius: 10,
                }}/>
            </Col>
            <Col xs={8} className={'text-left mt-3'}>
                <Row>
                    <Col xs={9}>
                        <h8 style={{fontWeight: 600, fontSize:15}}>{title}</h8><br/>
                        <h7 style={{fontWeight: 500}}>{detail}</h7>
                    </Col>
                </Row>

                <Row className={'mt-2'}>
                    <Col xs={12}>
                        <h6 className={'text-primary'}>{this.props.isUnit ? unitPrice.toLocaleString(): price.toLocaleString()}원 <h8 className={'text-dark'}>x {quantity}개</h8></h6>

                    </Col>
                </Row>
            </Col>
        </Row>
            <hr/></>;
    };

    getProductTotalPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.subscription_price;
        });
        return price;
    };

    getShippingPrice = (products) => {
        if (products.length >= 5){
            return 0;
        }
        return 2500
    };

    getProductTotalUnitPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.unit_price;
        });
        return price;
    };



    loadProductCells = (callback=()=>{}) => {
        let productIds = this.props.productIds;
        this.fetchShippingPrice(productIds);
        return API.database.getItemsByIds(productIds).then(   async (products) => {
            let _products = {};
            products.forEach(product => {
                if (Object.keys(_products).includes(product.id)){
                    _products[product.id]['quantity'] += 1;
                }else{
                    _products[product.id] = product;
                    _products[product.id]['quantity'] = 1;
                    _products[product.id]['priority'] = Object.keys(_products).length;
                }
            });

            let productValues = Object.values(_products);
            productValues = productValues.sort((a, b) => {
                return a.priority - b.priority;
            });
            let productCells = productValues.map(product => {
                return this.getProductCell(product);
            });

            let productsPrice = this.getProductTotalPrice(products);
            let shippingPrice = this.getShippingPrice(products);
            let productsUnitPrice = this.getProductTotalUnitPrice(products);
            let discountRatio = await Config.getFirstDiscountRatio();
            this.setState({
                productCells,
                shippingPrice: shippingPrice,
                productsPrice: (productsPrice + shippingPrice) * (1.0 - discountRatio),
                productsUnitPrice: productsUnitPrice,
                totalPrice: shippingPrice + productsPrice,
                totalUnitPrice: shippingPrice + productsUnitPrice,
            }, () => {
                callback();
            })
        });
    };

    subProduct = (product) => {
        if (this.busy){
            return;
        }
        this.busy = true;
        CartProduct.instance.removeOneProductId(product.id);
        this.loadProductCells(() => {
            this.busy = false;
        });
    };

    addProduct = (product) => {
        if (this.busy){
            return;
        }
        this.busy = true;
        CartProduct.instance.appendProductId(product.id);
        this.loadProductCells(() => {
            this.busy = false;
        });
    };

    removeProduct = (product) => {
        CartProduct.instance.removeProducts(product.id);
        this.loadProductCells();
    };

    fetchShippingPrice = (products) => {
        if (products.length >= 5){
            this.setState({
                shippingPriceLabel: '무료 배송'
            })
        }else{
            this.setState({
                shippingPriceLabel: <text className={''}>2,500원</text>
            })
        }
    };

    tryBuyProduct = () => {
        let productIds = CartProduct.instance.getProductIds();
        if (productIds.length >= 5){

        }else{
            this.setState({
                showShippingAlert: true
            })
        }
    };

    buyProduct = () => {
        this.setState({showShippingAlert:false})
    };

    getTotalPrice = () => {
        let price = this.getProductTotalPrice();
        price += this.getShippingPrice();

    };

    getLabelColor = (forUnit) => {
        if (forUnit){
            return this.props.isUnit ? '#000': '#888';
        }else{
            return !this.props.isUnit ? '#000': '#888';
        }
    };

    getPriceLabelStyle = (forUnit) => {
        if (forUnit){
            if (this.props.isUnit){
                return {}
            }else{
                return {
                    color: '#888',
                    // textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid'
                }
            }
        }else{
            if (!this.props.isUnit){
                return {}
            }else{
                return {
                    color: '#888',
                    // textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid'
                }
            }
        }

    };

    fetchFreePrice = async () => {
        let price = await Config.getFreePrice();
        this.setState({
            freePrice: price
        })
    };

    render() {
        let mileage = this.props.mileage;
        if (!mileage){
            mileage = 0;
        }
        let finalPrice = 0;
        if (this.isTrial()){
            finalPrice = this.state.freePrice;
        }else if (this.props.isUnit){
            finalPrice = this.state.totalUnitPrice - this.props.mileage;
        }else{
            finalPrice = this.state.totalPrice - mileage;
        }

        let discountRate = Math.round((1 - finalPrice / (this.state.productsUnitPrice +  this.state.shippingPrice)) * 100);

        return <Container>
            {this.state.productCells}
            <Row className={'mt-1'}>
                <Col xs={6}>
                    <h6 style={{color: '#777777'}}>배송비</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={{color: '#777777'}}>{this.state.shippingPriceLabel}</h6>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <h6 style={{color: this.getLabelColor(true)}}>상품 단품가</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={this.getPriceLabelStyle(true)}>{this.state.productsUnitPrice.toLocaleString()}원</h6>
                </Col>
            </Row>


            {/*<Row>*/}
            {/*    <Col xs={6}>*/}
            {/*        <h6 style={{color: this.getLabelColor(!this.isTrial())}}>다음 결제 금액 </h6>*/}
            {/*    </Col>*/}
            {/*    <Col xs={6} className={'text-right'}>*/}
            {/*        <h6 style={this.getPriceLabelStyle(false)}>{this.state.productsPrice.toLocaleString()}원</h6>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {mileage > 0 ?  <Row>
                    <Col xs={6}>
                        <h6 style={{color: this.getLabelColor(true)}}>총 마일리지 할인</h6>
                    </Col>
                    <Col xs={6} className={'text-right'}>
                        <h6 >-{mileage.toLocaleString()}원</h6>
                    </Col>
                </Row>
                : null}

            <Row>
                <Col xs={6}>
                    <h6>할인 정보</h6>
                    <h6><b>총 결제 금액</b></h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={{color: Color.primary}}>{discountRate}% 할인</h6>
                    <h6>{finalPrice.toLocaleString() + "원"}</h6>

                </Col>
            </Row>



        </Container>
    }

}


export default CheckoutContainer;
