import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import StarIcon from '@material-ui/icons/Star';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import API from "../../../../shared/service/api/API";
import Button from "@material-ui/core/Button";

function Review(props) {

    const fr = useFontRatio();

    const reviewItem = props.reviewItem;

    const getStars = (rating) => {
        let starts = [];
        for (let i = 0; i < Math.round(rating); i++){
            starts.push(<StarIcon style={{fontSize: 20, marginTop: -4}}/>);
        }
        return starts;
    };

    return <div style={{
        padding: 25,
        background: `linear-gradient(rgba(220,220,220,1), rgba(220,220,220,1))`,
        backgroundSize: 'cover',
        borderRadius: 40,
        marginTop: 20,
        textAlign: 'left'
    }}>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{flexGrow: 1, fontWeight: 600, textAlign: 'left'}}>
                {reviewItem.owner_name}
            </div>
            <text style={{color: '#888'}}>Instagram</text>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{flexGrow: 1, fontWeight: 600, textAlign: 'left'}}>
                <text style={{fontSize: 15}}>{reviewItem.start_rating.toFixed(1)} </text>
                {getStars(reviewItem.start_rating)}
            </div>
            {/*<text style={{color: '#888', fontSize: 15}}>{reviewItem.owner_name}</text>*/}
        </div>


        <div style={{height: 10}}/>
        <text style={{fontWeight: 400}}>{reviewItem.content}</text>

    </div>

}


export default function ReviewCard(props) {

    const fr = useFontRatio();

    const [reviewItems, setReviewItems] = React.useState([]);
    const [startKey, setStartKey] = React.useState(null);

    const appendReviews = async () => {
        let response = await API.database.queryItems('new_review', [
            {condition: 'ge', field: 'start_rating', value: 4, option: 'and'}
        ], 3, true, startKey);
        let items = response.items;
        let endKey = response.end_key;

        setReviewItems(reviewItems.concat(items));
        setStartKey(endKey);
    };

    const fetchReviews = async () => {
        await appendReviews();
    };

    const fetch = () => {
        fetchReviews();
    };

    React.useEffect(()=>{
        fetch()
    }, []);


    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(130,130,130,0.5), rgba(130,130,130,0.5))`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingLeft: 30, paddingTop: 70, paddingBottom: 70,  textAlign: 'left'}} lg={6} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>Real Review</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>커피플리즈 <br/>고객님들이 작성한<br/>솔직 담백한 후기 <br/> </text>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            <br/>
                            실제 구독자분들이 SNS에 작성한 리뷰입니다.
                        </text>

                        <div style={{
                            marginTop: 25,
                            color: 'white'
                        }}>
                            <InstagramIcon style={{
                                fontSize: 50
                            }}/>
                            <FacebookIcon style={{
                                fontSize: 50
                            }}/>
                            <TwitterIcon style={{
                                fontSize: 50
                            }}/>
                        </div>


                    </Col>

                    <Col style={{padding: 20, textAlign: 'center'}} lg={6} className={'mt-3 mb-3'}>

                        {reviewItems.map(reviewItem=>{
                            return <Review
                                reviewItem={reviewItem}
                            />
                        })}

                        <Button style={{
                            marginTop: 18,
                            padding: 15,
                            background: `linear-gradient(rgba(220,220,220,1), rgba(220,220,220,1))`,
                            backgroundSize: 'cover',
                            borderRadius: 40,
                            width: '100%',
                            textAlign: 'center'
                        }} onClick={()=>{
                            appendReviews();
                        }}>
                            <text style={{fontWeight: 700}}>
                                더보기
                            </text>
                        </Button>


                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
