import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import API from "../../../../shared/service/api/API";
import Button from "@material-ui/core/Button";


export default function IngredientCard(props) {

    const fr = useFontRatio();

    const [product, setProduct] = React.useState(null);


    const fetchProduct = async () => {
        let response = await API.database.getItem(props.product_id);
        setProduct(response.item);
    };

    const fetch = () => {
        fetchProduct();
    };

    const getIngredient = () => {
        if (!product || !product.ingredient){
            return null;
        }
        return <Container>{product.ingredient.map(info=>{
            if (!info){
                return null;
            }
            let name = info.name;
            let value = info.value;
            return <Row>
                <Col sm={4} md={4}><text style={{fontWeight: 600, fontSize: 14}}>{name}</text></Col>
                <Col sm={8} md={8}><text style={{fontWeight: 400, fontSize: 14, color: '#333'}}>{value}</text></Col>
            </Row>
        })}</Container>;
    };


    React.useEffect(()=>{
        fetch()
    }, []);


    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(60,60,90,0.5), rgba(50,50,70,0.5))`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingLeft: 30, paddingTop: 70, paddingBottom: 70,  textAlign: 'left'}} lg={6} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>영양성분표시</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}> 자연그대로 <br/> 짧고, 정직하게<br/> </text>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            <br/>
                            제품의 성분별 함량입니다.
                        </text>


                    </Col>

                    <Col style={{padding: 20, textAlign: 'center'}} lg={6} className={'mt-3 mb-3'}>

                        <div style={{
                            padding: 25,
                            background: `linear-gradient(rgba(220,220,220,1), rgba(220,220,220,1))`,
                            backgroundSize: 'cover',
                            borderRadius: 40,
                            marginTop: 20,
                            textAlign: 'left'
                        }}>

                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                {}
                                <div style={{flexGrow: 1, fontWeight: 600, textAlign: 'left'}}>
                                    {getIngredient()}

                                </div>
                                {/*<text style={{color: '#888', fontSize: 15}}>{reviewItem.owner_name}</text>*/}
                            </div>

                        </div>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
