import * as React from "react";
import {Button, Modal, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Icon from "../shared/res/Icon";
import CartProduct from "../shared/service/CartProduct";
import API from "../shared/service/api/API";
import Image from "./mixin/Image";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import CartContainer from "./CartContainer";


class CartModal extends React.Component{

    state = {
    };

    handleClose = () => {
        this.props.onClose();
    };


    render() {
        return <Modal show={this.props.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    장바구니
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CartContainer/>
            </Modal.Body>
        </Modal>;
    }

}


export default CartModal;
