import Login from "./Login";
import Payment from "./Payment";
import Password from "./Password";


class Service {

    static login = new Login();
    static payment = new Payment();
    static password = new Password();

}

export default Service;