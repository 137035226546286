import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroDecafCard from "../card/intro/IntroDecafCard";
import IntroAnotherGreenCard from "../card/intro/IntroAnotherGreenCard";
import TasteMilk from "../card/taste/TasteMilk";
import TasteSugar from "../card/taste/TasteSugar";
import IntroMilkTeaLatteCard from "../card/intro/IntroMilkTeaLatteCard";
import TasteBlackTea from "../card/taste/TasteBlackTea";
import IntroTaroLatteCard from "../card/intro/IntroTaroLatteCard";
import TasteTaro from "../card/taste/TasteTaro";
import RealMilkCard from "../card/Pros/RealMilkCard";


export default function TaroLattePage(props) {

    const product_id = 'KLXXzicAdxmcmDXmRKNLzJ';

    return <PageLayout
        product_id={product_id}
    >
        <IntroTaroLatteCard/>
        <TasteMilk/>
        <TasteTaro/>
        <RealMilkCard/>
        <ShippingCard/>

        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
    </PageLayout>

}

