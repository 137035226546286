import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../../images/product_real/AnotherGreen1.JPG";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroAnotherGreenCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(120,110,50,0.88),rgba(80, 70, 30,0.97))'}
        title={'어나더그린'}
        detail={<>케인슈가,<br/>디카페인.</>}
        description={<>
            디카페인으로 느끼는 깊은 커피의 향. <br/>
            비정제설탕 케인슈가의 건강한 맛. <br/>
            오랜 시간 스위스워터프로세스 방식으로 추출한<br/>
            1등급 우유만 100% 사용한 희석 없는 진한 라떼.
            </>}
        image={CoffeeImage}
    />


}
