import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import LampBlack1 from "../../../images/product_real/LampBlack.JPG";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroLampBlackCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(30,30,50,0.88),rgba(40, 30, 30,0.97))'}
        title={'램프 블랙'}
        detail={<>약한 산미,<br/>강한 바디.</>}
        description={<>
            어렴풋이 느껴지는 과일의 산미와 <br/>
            은은하게 비춰지는 단맛과는 상반되는  <br/>
            뚜렷히 표현된 다크초콜렛의 쓴맛과 <br/>
            강렬한 바디감이 인상적인 커피. <br/>
            한번 마시면 빠져나올 수 없을 거에요.
            </>}
        image={LampBlack1}
    />


}
