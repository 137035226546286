import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../images/iceCoffee.png";
import BackVideo from "../../mov/backv.mp4";
import SizingComponent from "../../mixin/SizingComponent";
import DECAF1 from "../../images/product_real/decaf.jpg";
import useFontRatio from "../../../util/UseWindowDimensions";
import SeeMoreButton from "./SeeMoreButton";
import Badge from "reactstrap/lib/Badge";
import Color from "../../../shared/Color";



export default function DECAF(props){

    const fr = useFontRatio();

    return <Row style={{
        background: 'linear-gradient(rgba(10,12,13,0.98),rgba(10,12,13,0.92))',
        marginTop: -2
    }} className={'justify-content-md-center'} >

        <Col style={{
            borderRadius: 0,
            padding: 40 * fr,
            background: 'linear-gradient(rgba(32,62,113,0.98),rgba(32,62,113,0.57))'
        }}>
            <Container>
                <Row>
                    <Col md={6} className={'mt-3 mb-3'}>
                        <Badge className={'mr-1'} style={{marginTop: 0, background: Color.primary, color: '#fff'}}>드립커피</Badge>
                        <Badge className={'mr-1'} style={{marginTop: 0, background: '#669966', color: '#fff'}}>디카페인</Badge>
                        <br/>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>디카페인</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(40, Math.min(50 * fr, 50)), fontWeight: 800, color: '#309EB3'}}>DECAF</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>상큼한 사과,<br/>향기로운 허브.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                            허브의 향미와 상큼한 사과의 기분 좋은 산미, <br/>
                            그리고 헤이즐넛의 단맛이 좋은 부드러운 커피.
                        </text>
                        <br/>
                        <SeeMoreButton onClick={()=>{
                            window.open('/products/decaf');
                        }}/>
                    </Col>

                    <Col md={6} className={'mt-3 mb-3'}>
                        <img src={DECAF1} width={'100%'} style={{marginRight:0, borderRadius: 10}}/>
                    </Col>
                </Row>

            </Container>


        </Col>


    </Row>

}
