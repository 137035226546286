import Delivery from "../../../../img/shipping_image.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function ShippingCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Delivery})`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>

                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>당일 생산<br/>당일 발송</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            주문이 들어오면 당일 생산하는 원칙은<br/>
                            카페에서 바로 만든 향과 맛을 낼 수 있는 비결입니다.<br/>
                            주문 후 바로 제조되는 신선한 음료를 받아보세요.
                        </text>

                    </Col>
                </Row>

            </Container>

        </div>
    </Col>

}
