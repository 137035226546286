import Network from "./network";


class Database {

    createItem = (partition, item) => {
        return Network.instance.post({
            module_name: 'cloud.database.create_item',
            partition, item
        });
    };

    updateItem = (itemId, item) => {
        return Network.instance.post({
            module_name: 'cloud.database.update_item',
            item_id: itemId,
            item
        });
    };

    getItem = (itemId) => {
        return Network.instance.post({
            module_name: 'cloud.database.get_item',
            item_id: itemId
        });
    };

    deleteItem = (itemId) => {
        return Network.instance.post({
            module_name: 'cloud.database.delete_item',
            item_id: itemId
        });
    };

    queryItems = (partition, query, limit=100, reverse=false, start_key=null, sort_key='creation_date', join={}) => {
        return Network.instance.post({
            module_name: 'cloud.database.query_items',
            partition, query, limit, reverse, start_key, sort_key, join
        });
    };

    getItemsByIds = (itemIds) => {
        let promises = itemIds.map(itemId => {
            return this.getItem(itemId);
        });
        return Promise.all(promises).then(values => {
            return values.map(value => {
                return value.item;
            });
        });
    };

}

export default Database;
