import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import ProductImage from "../../../images/product_real/CobaltViolet1.JPG";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroCobaltVioletCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(62,62,93,0.98),rgba(62,62,83,0.57))'}
        title={'코발트 바이올렛'}
        detail={<>드라이 베리,<br/>감미로운 쓴맛.</>}
        description={<>
            드라이 베리의 산미와 다크초콜렛의 단맛이 <br/>
            감미롭게 다가와 입안 전체를 감싸는 <br/>
            묵직한 바디와 환상적인 조화를 이루는 커피.

            </>}
        image={ProductImage}
    />


}
