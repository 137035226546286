import React from 'react';
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import AllProductImage from "../components/images/bottom.png"
import CustomCardMedia from "../components/main/ProductSection/components/CardMedia";
import AISelectorModal from "../components/main/AISelecterModal";
import ProductsAPI from "../shared/service/Products";
import Badge from "reactstrap/lib/Badge";


export default function Products(props) {
    const [showCart, setShowCart] = React.useState(false);
    const [showAI, setShowAI] = React.useState(false);
    const [showProductModal, setShowProductModal] = React.useState(false);
    const [dripssoCells, setDripssoCells] = React.useState([]);
    const [coffeepleaseCells, setCoffeepleaseCells] = React.useState([]);
    const [coffeepleaseMilkteaCells, setCoffeepleaseMilkteaCells] = React.useState([]);
    const [dripssoUndilutedCells, setDripssoUndilutedCells] = React.useState([]);
    const [dripssoBeanCells , setDripssoBeanCells] = React.useState([]);

    const onBuyProduct = () => {
        setShowCart(true);
    };

    const getProducts = async () => {
        return ProductsAPI.instance.listVisibleStore();
    };

    const getProductCells = (category, products) => {
        return products.filter(product=>{
            return product.category === category;
        }).map(product=>{
            return getProductCell(product.title, product.description, product.unit_price, product.subscription_price, product.real_image_id, '/products/' + product.name, product.is_best, product.is_hot);
        });
    };


    function getProductCell (title, description, unit_price, subscription_price, image_id, target_url, is_best, is_hot) {
        let bottom = [];
        if (is_best){
            bottom.push(<Badge style={{background: "#405090", marginRight: 4}}>BEST</Badge>);
        }
        if (is_hot){
            bottom.push(<Badge style={{background: "#C04040", marginRight: 4}} className={''}>HOT</Badge>);
        }
        if (bottom.length === 0){
            bottom.push(<br/>);
        }
        return <Col xs={12} sm={6} md={6} lg={4} xl={3} style={{marginTop: 8, marginBottom: 8}}>

            <Card sx={{ maxWidth: 345 }} onClick={()=>{
                window.open(target_url,'_blank');
            }}>
                <CardActionArea>
                    <CustomCardMedia
                        component="img"
                        height="250"
                        image_id={image_id}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <text style={{fontWeight: 700}}>구독가 {subscription_price.toLocaleString()}원</text><br/>
                            <text style={{fontWeight: 700, color: '#777'}}>단품가 {unit_price.toLocaleString()}원</text><br/>

                        </Typography>
                        {bottom}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Col>;
    };

    const prepareProductCells = async () => {
        const products = await getProducts();
        const dripssoCells = getProductCells('dripsso', products);
        const coffeepleaseCells = getProductCells('coffeeplease', products);
        const coffeepleaseMilkteaCells = getProductCells('coffeeplease_milktea', products);
        const dripssoUndilutedCells = getProductCells('dripsso_undiluted', products);
        const dripssoBeanCells = getProductCells('dripsso_bean', products);

        setDripssoCells(dripssoCells);
        setCoffeepleaseCells(coffeepleaseCells);
        setCoffeepleaseMilkteaCells(coffeepleaseMilkteaCells);
        setDripssoUndilutedCells(dripssoUndilutedCells);
        setDripssoBeanCells(dripssoBeanCells);
    };

    React.useEffect(()=>{
        prepareProductCells();
    }, [showCart, showProductModal]);


    return (<>
        <TopMenu showCart={showCart}/>
        <AISelectorModal brightness={30} onShowCart={(showCart)=>{
            setShowCart(showCart);
        }} show={showAI} onHide={()=>{
            setShowAI(false);
        }}/>
        <Container>
            <Row style={{paddingTop: 150, marginBottom: 100}}>
                <Col sm={12}>
                    <h1 style={{fontWeight: 700, marginBottom: 24}}>
                        취향에 맞는 커피<text style={{color: '#999'}}>를 골라볼까요?</text>
                    </h1>
                </Col>
                <Col xs={8} sm={6} md={6} lg={4} xl={4}>
                    <Card sx={{ maxWidth: 345 }} onClick={()=>{
                        setShowAI(true);
                    }}>

                        <CardContent>
                            <Typography gutterBottom variant="body4" component="div">
                                <text style={{fontWeight: 700}}>커피 추천 받기</text>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div">

                                <text style={{fontWeight: 800, color: '#666'}}>어떤 커피가 <br/>당신에게 어울릴까요?</text>
                            </Typography>

                        </CardContent>

                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="90"
                                image={AllProductImage}
                                alt="green iguana"
                            />

                        </CardActionArea>
                    </Card>
                </Col>


                <Col sm={12}>
                    <h4 style={{fontWeight: 700, marginBottom: 24, marginTop: 64}}>
                        드립쏘 원액, <text style={{color: '#999'}}>품격이 다른 드립 커피 원액.</text>
                    </h4>
                </Col>

                {dripssoUndilutedCells}


                <Col sm={12}>
                    <h4 style={{fontWeight: 700, marginBottom: 24, marginTop: 64}}>
                        드립쏘, <text style={{color: '#999'}}>드립추출 방식으로 추출한 고농도 커피.</text>
                    </h4>
                </Col>

                {dripssoCells}



                <Col sm={12}>
                    <h4 style={{fontWeight: 700, marginBottom: 24, marginTop: 64}}>
                        드립쏘 원두, <text style={{color: '#999'}}>최고의 로스터, 최고의 퀄리티와 향.</text>
                    </h4>
                </Col>

                {dripssoBeanCells}



                {/*<Col sm={12}>*/}
                {/*    <h4 style={{fontWeight: 700, marginBottom: 24, marginTop: 64}}>*/}
                {/*        커피플리즈 스탠다드, <text style={{color: '#999'}}>대용량 콜드브루 커피.</text>*/}
                {/*    </h4>*/}
                {/*</Col>*/}

                {/*{coffeepleaseCells}*/}


                <Col sm={12}>
                    <h4 style={{fontWeight: 700, marginBottom: 24, marginTop: 64}}>
                        커피플리즈 밀크티, <text style={{color: '#999'}}>어디서든 마시는 고급 수제 밀크티.</text>
                    </h4>
                </Col>

                {coffeepleaseMilkteaCells}



            </Row>
        </Container>

        <Footer/>
    </>);

}
