import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FlatWhite1 from "../../images/product_real/flatwhite.jpg";
import useFontRatio from "../../../util/UseWindowDimensions";
import SeeMoreButton from "./SeeMoreButton";
import Badge from "reactstrap/lib/Badge";
import Color from "../../../shared/Color";



export default function FlatWhite(props){

    const fr = useFontRatio();

    return <Row style={{
        background: 'linear-gradient(rgba(10,12,13,0.98),rgba(10,12,13,0.92))',
        marginTop: -2
    }} className={'justify-content-md-center'} >

        <Col style={{
            borderRadius: 0,
            padding: 40 * fr,
            background: 'linear-gradient(rgba(170,160,150,0.88),rgba(180, 170, 130,0.97))'
        }}>
            <Container>
                <Row>
                    <Col md={6} className={'mt-3 mb-3'}>
                        <Badge className={'mr-1'} style={{marginTop: 0, background: Color.primary, color: '#fff'}}>드립커피</Badge>
                        <Badge className={'mr-1'} style={{marginTop: 0, background: '#999955', color: '#fff'}}>라떼</Badge>
                        <br/>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>플랫 화이트</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(40, Math.min(50 * fr, 50)), fontWeight: 800, color: '#DDD'}}>Flat White</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>다크초콜릿,<br/>신선한 우유.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                            고급스럽게 표현된 다크로스트 특유의 쓴맛과 <br/>
                            다크초콜렛과 텐저린의 상큼함의 어우러진 <br/>
                            묵직한 피니쉬를 뚜렷하게 느낄수 있는 진한 커피.
                        </text>
                        <br/>
                        <SeeMoreButton onClick={()=>{
                            window.open('/products/flat_white');
                        }}/>
                    </Col>

                    <Col md={6} className={'mt-3 mb-3'}>
                        <img src={FlatWhite1} width={'100%'} style={{marginRight:0, borderRadius: 10}}/>
                    </Col>
                </Row>

            </Container>


        </Col>


    </Row>

}
