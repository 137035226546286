import MypageProductsContainer from "./MypageProductsContainer";
import SubscriptionFreq from "./SubscriptionFreq";
import TextField from "@material-ui/core/TextField/TextField";
import Postcode from "../checkout/Postcode";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import NextDatePicker from "./NextDatePicker";
import Card from "@material-ui/core/Card/Card";
import CardBody from "reactstrap/es/CardBody";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Container from "react-bootstrap/Container";
import Time from "../../util/Time";
import UserLevel from "./UserLevel";
import Faq from "react-faq-component";
import React, {useEffect, useRef} from "react";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SelectProductsModal from "./SelectProductsModal";
import API from "../../shared/service/api/API";
import Alert from "react-bootstrap/Alert";

export default function SubscriptionDetail(props){

    const [subscription, setSubscription_] = React.useState(props.subscription);
    const [openAddressModal, setOpenAddressModal] = React.useState(false);
    const [reloadCount, setReloadCount] = React.useState(0);
    const [showProductsModal, setShowProductsModal] = React.useState(false);
    const [orderCount, setOrderCount] = React.useState(0);
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    useEffect(()=>{
        fetchOrderCount();
    });

    const reload = () => {
        setReloadCount(reloadCount + 1);
    };

    const setSubscription = (subscription_) => {
        setSubscription_(subscription_);
    };

    const getStatusColor = (subscription) => {
        let statusColor = '#3376f1';
        if (subscription.enabled){
            statusColor = '#3376f1';
        }else{
            statusColor = '#f37661';
        }
        return statusColor
    };

    const getStatusLabel = (subscription) => {
        let statusLabel = '구독중';
        if (subscription.enabled){
            statusLabel = '구독중';
        }else{
            statusLabel = '중단됨';
        }
        return statusLabel;
    };

    const isValid = (subscription) => {
        if (subscription.product_counts.length === 0){
            alert("하나 이상의 상품을 선택해주세요.");
            return false;
        }
        let shipping_info = subscription.shipping_info;
        if (!shipping_info.buyer_name || shipping_info.buyer_name.length === 0){
            alert("주문자 이름을 입력해주세요.");
            return false;
        }
        if (!shipping_info.buyer_tel || shipping_info.buyer_tel.length === 0){
            alert("핸드폰 번호를 입력해주세요.");
            return false;
        }
        if (!shipping_info.buyer_addr_road || shipping_info.buyer_addr_road.length === 0){
            alert("주소를 입력해주세요.");
            return false;
        }
        if (!shipping_info.buyer_addr_detail || shipping_info.buyer_addr_detail.length === 0){
            alert("상세주소를 입력해주세요.");
            return false;
        }

        return true;
    };

    const onSave = () => {
        if (isValid(subscription)){
            props.onSave(subscription);
            alert("저장되었습니다.");
        }
    };

    const handleShippingInfo = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        subscription.shipping_info[name] = value;
        setSubscription(subscription);
        reload();
    };

    const canCancelTrial = (subscription) => {
        // 현재 날짜로부터 생성된지 3일이 안된 상태에서 체험판이면 구독 취소 불가
        if (subscription.is_trial){
            let now = (+ (new Date())) / 1000;
            if (now - subscription.creation_date < 60 * 60 * 24 * 3){
                return false;
            }
        }
        return true;
    };

    const contents = () => { return {
        rows: [
            {
                title: <h5>구독 주기</h5>,
                content: <div className={'mb-4'}>
                    <SubscriptionFreq
                        weeks={subscription.weeks}
                        onChange={(weeks)=>{
                            subscription.weeks = weeks;
                            setSubscription(subscription);
                        }}
                    />
                </div>

            },

            {
                title: <h5>배송지 정보</h5>,
                content:
                    <div className={''}>
                        <TextField
                            label="주문자 이름"
                            name={'buyer_name'}
                            id="outlined-margin-dense"
                            fullWidth
                            margin="dense"
                            className={'mr-2'}
                            value={subscription.shipping_info?.buyer_name}
                            onChange={handleShippingInfo}
                        />

                        <TextField
                            label="핸드폰 번호"
                            name={'buyer_tel'}
                            fullWidth
                            id="outlined-margin-dense"
                            margin="dense"
                            value={subscription.shipping_info?.buyer_tel}
                            onChange={handleShippingInfo}
                        />
                        <br/>

                        <TextField
                            id="standard-full-width"
                            label="주소"
                            fullWidth
                            margin="dense"
                            onFocus={(e)=>{
                                if (!openAddressModal){
                                    e.target.blur();
                                    setOpenAddressModal(true);
                                }
                            }}
                            value={subscription.shipping_info?.buyer_addr_road}
                            name={'buyer_addr_road'}
                            onChange={handleShippingInfo}
                        />

                        <Postcode openModal={openAddressModal} onComplete={(postcode, address)=>{
                            subscription.shipping_info.buyer_addr_road = address;
                            subscription.shipping_info.buyer_postcode = postcode;
                            setSubscription(subscription);
                            setOpenAddressModal(false);
                        }} onClose={()=>{
                            setOpenAddressModal(false);
                        }}/>


                        <TextField
                            id="standard-full-width"
                            label="상세 주소"
                            fullWidth
                            margin="dense"
                            name={'buyer_addr_detail'}
                            value={subscription.shipping_info?.buyer_addr_detail}
                            onChange={handleShippingInfo}
                        />

                        <text className={'mt-4'} style={{fontSize: 13, color: '#888'}}>
                            변경된 배송지 정보는 다음 결제시부터 적용됩니다.
                        </text>
                    </div>
            },

            {
                title: <h5>구독 상태</h5>,
                content: <div>
                    <Row>
                        <Col xs={6}>
                            <Button onClick={async ()=>{
                                if (!canCancelTrial(subscription)){
                                    alert("체험판 구독의 경우 최초 구독일로부터 3일 이후 취소할 수 있습니다.");
                                    return;
                                }
                                if (window.confirm("구독을 해지하시면 누적 할인 혜택을 더 이상 적용 받지 못합니다. 해지 대신 다음 결제일 연기를 추천드립니다. 그래도 해지하시겠습니까?")){
                                    let writeReason = "해지 하시는 이유를 알려주세요. 제품 개선에 반영하겠습니다.";
                                    let reason = window.prompt(writeReason);
                                    if (reason === null){
                                        return;
                                    }
                                    while (reason !== null && reason.length === 0){
                                        alert("해지 하시는 이유를 입력해주세요.");
                                        reason = window.prompt(writeReason);
                                    }
                                    subscription['reason'] = reason;
                                    subscription['first_payment_date'] = subscription.creation_date;
                                    subscription['first_payment_date_ymd'] = Time.timestampToDateStringYMD(subscription.creation_date);
                                    let _ = await API.database.createItem('cancelled_subscription', subscription);
                                    let response = await API.database.deleteItem(subscription.id);
                                    if (response.success){
                                        alert("해지 처리 되었습니다.");
                                        window.location.reload();
                                    }else{
                                        alert("알 수 없는 에러 발생: " + JSON.stringify(response));
                                    }
                                }
                            }}>구독 해지</Button>
                        </Col>
                        <Col xs={6}>
                            <Button onClick={()=>{
                                if (subscription.enabled){
                                    return;
                                }
                                subscription.enabled = true;
                                setSubscription(subscription);
                                reload();
                                onSave();
                                if (subscription.enabled){
                                    alert("구독이 다시 시작 되었습니다.");
                                }
                            }}>{subscription.enabled ? null : '다시 구독하기'}</Button>
                        </Col>
                    </Row>
                </div>
            },

            {
                title: <h5>다음 결제일</h5>,
                content: <Row>
                    <Col style={{bottom: -5}}>
                        <NextDatePicker open={openDatePicker} onClose={()=>setOpenDatePicker(false)} onChange={(next_payment_date)=>{
                            subscription.next_payment_date = next_payment_date;
                            setSubscription(subscription);
                            onSave();
                            reload();
                        }} subscription={subscription}/>
                    </Col>
                </Row>
            }


        ],
    }};

    const styles = {
        // bgColor: 'white',
        titleTextColor: "black",
        rowTitleColor: "black",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };

    const adjustProduct = (targetProductId, count) => {
        let product_counts = subscription.product_counts;
        let has_product_id = false;
        for (let product_count of product_counts){
            if (product_count.product_id === targetProductId){
                product_count['count'] += count;
                has_product_id = true;
            }
        }
        if (!has_product_id){
            product_counts.push({
                product_id: targetProductId,
                count
            })
        }
        setSubscription(subscription);
        reload();
    };

    const fetchOrderCount = async () => {
        let response = await API.database.queryItems('new_order', [
            {condition: 'eq', field: 'subscription_id', value: subscription.id, option: 'and'}
        ]);
        setOrderCount(response.items.length);
    };


    return <Card>
        <CardBody>

            <div className={'ml-2'}>
                <h5> <ArrowBackIosIcon className={'mb-1'} onClick={()=>{
                    props.getBack();
                }}/> 구독 상세</h5>
                <hr className={'mb-4'}/>
            </div>

            <Container fluid>

                <Row>
                    <Col xs={12}>
                        <text style={{
                            fontSize: 17,
                            color: getStatusColor(subscription)
                        }}>
                            {getStatusLabel(subscription)}
                        </text>
                        <text> | {orderCount}회 주문함</text>
                        <br/>
                        <text style={{
                            fontSize: 16,
                            color: '#888'
                        }}>{Time.timestampToDateString(subscription.next_payment_date)} 결제 예정</text>
                        <br/>

                        <br/>

                    </Col>

                </Row>

                <Row>
                    <Col xs={12}>
                        <UserLevel subscription_id={subscription.id}/>
                    </Col>

                </Row>

                <Row className={'mt-3 mb-3'}>

                    <Col className={'mb-4'}>

                        <Row className={'mt-1 mb-2'}>
                            <Col xs={6} className={'text-left'}>
                                <h5>상품 정보</h5>
                            </Col>
                            <Col xs={6} className={'text-right'}>
                                <Button onClick={()=>{
                                    setShowProductsModal(true)
                                }}>상품 추가 <AddShoppingCartIcon className={'ml-2 mr-2'}/></Button>
                            </Col>
                        </Row>
                        <MypageProductsContainer
                        subscription={subscription}
                        onChange={(subscription)=> {
                            setSubscription(subscription);
                            reload();
                        }}/>
                    </Col>
                </Row>


                <Row className={'justify-content-center mb-5'}>
                    <Col>
                        <Faq data={contents()} styles={styles} config={config} />
                    </Col>
                </Row>

                <Row>
                    <Col className={'text-right'}>
                        <Button onClick={onSave} variant="contained" color="primary">저장하기</Button>
                    </Col>
                </Row>

                <SelectProductsModal show={showProductsModal}
                                     onHide={()=>{
                                        setShowProductsModal(false);
                                     }}
                                     onSelect={(product)=>{
                                         adjustProduct(product.id, 1);
                                         setShowProductsModal(false);
                                     }}/>

            </Container>


        </CardBody>
    </Card>;

}
