import API from "./api/API";


class Config {

    static functionName = 'user004';
    static freePrice = null;
    static firstDiscountRatio = null;
    static freePriceItem = null;

    static getFreePriceItem = async () => {
        if (Config.freePriceItem){
            return Config.freePriceItem;
        }
        let response = await API.database.getItem('ZRM8NdezXERGQD6FVyvR3u');
        let item = response.item;
        Config.freePriceItem = item;
        return item;
    };

    static getFreePrice = async () => {
        let item = await Config.getFreePriceItem();
        Config.freePrice = item.price;
        return Config.freePrice;
    };

    static getFreeProductCounts = async () => {
        let item = await Config.getFreePriceItem();
        return item.product_counts;
    };

    static getFirstDiscountRatio = async () => {
        if (Config.firstDiscountRatio){
            return Config.firstDiscountRatio;
        }
        let response = await API.database.getItem('9W7gFtAWGkMRkQdLLhhuZo');
        Config.firstDiscountRatio = response.item.discount_ratio;
        return Config.firstDiscountRatio;
    };
}

export default Config;
