import rtd from "../../../../../src/img/milk.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function RealMilkCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${rtd}) center`,
            backgroundSize: 'cover',
            objectFit: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>밍밍한 라떼 그만!</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>진한 라떼를 마셔보세요.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            원가 절감을 위해 우유에 물을 넣어 희석하는<br/>
                            다른 업체들과는 차이나는 퀄리티의 라떼, <br/>
                            커피플리즈는 100% 희석하지 않은 원유를 사용합니다.

                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
