import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import ProductImage from "../../../images/product_real/Ethiopia1.jpg";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroEthiopiaCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(92,62,73,0.98),rgba(92,62,73,0.57))'}
        title={'에티오피아 콜드브루'}
        detail={<>최상의 로스팅,<br/>
            최고의 밸런스.</>}
        description={<>
            한 모금으로 입안에는 꽃향 가득.<br/>
            목 끝에서는 향이 올라오고<br/>
            혀 끝에 침이 고이기 시작합니다.<br/>
            다시 한모금을 마시고 나면 입 속 전체가<br/>
            커피 향으로 코팅되는 경험을 선사합니다.
            </>}
        image={ProductImage}
    />


}
