import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroDecafCard from "../card/intro/IntroDecafCard";
import IntroAnotherGreenCard from "../card/intro/IntroAnotherGreenCard";
import TasteMilk from "../card/taste/TasteMilk";
import TasteSugar from "../card/taste/TasteSugar";
import IntroFlatWhiteCard from "../card/intro/IntroFlatWhiteCard";
import TasteOrange from "../card/taste/TasteOrange";
import TasteDarkChocolateSecond from "../card/taste/TasteDarkChocolateSecond";
import TasteTangerine from "../card/taste/TasteTangerine";
import RealMilkCard from "../card/Pros/RealMilkCard";
import RelatedLatte from "../card/postproduct/RelatedLatte";
import RelatedFlatwhite from "../card/postproduct/RelatedFlatwhite";
import RelatedAnotherGreen from "../card/postproduct/RelatedAnotherGreen";


export default function FlatWhitePage(props) {

    const product_id = 'EQo4NgEAPGmLAgC9TQZDJV';

    return <PageLayout
        product_id={product_id}
    >
        <IntroFlatWhiteCard/>
        <TasteMilk/>
        <TasteTangerine/>
        <MicrolotCard/>
        <RealMilkCard/>
        <ShippingCard/>
        <DripCoffeeCard/>
        <RTDCard/>
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>


        <RelatedLatte/>
        <RelatedAnotherGreen/>

    </PageLayout>

}

