import Payment from "../shared/service/Payment";
import MyPage from "./MyPage";
import Loader from "react-spinners/DotLoader";
import LoadingOverlay from "react-loading-overlay";
import * as React from "react";
import queryString from 'query-string';
import Home from "./Home";


class PaymentRedirect extends React.Component{

    static path = '/paymentredirect';

    query = queryString.parse(window.location.search);

    getPg = () => {
        return this.query.pg;
    };

    getPaymentMethodKey = () => {
        return this.query.payment_method_key;
    };

    doPayment = async () => {
        let paymentMethodKey = this.getPaymentMethodKey();
        if (this.getPg() === 'kakaopay'){
            let response = await Payment.instance.requestOrderPaymentKakao(paymentMethodKey);
            alert(response.message);
            this.props.history.push(MyPage.path + "?show_subscription=1");
        }else if (this.getPg() === 'html5_inicis'){
            let response = await Payment.instance.requestOrderPaymentCard(paymentMethodKey);
            alert(response.message);
            this.props.history.push(MyPage.path + "?show_subscription=1");
        }else{
            this.props.history.push(Home.path);
        }
    };

    isBusy = () => {
        let busy = localStorage.getItem("payment_busy");
        if (busy === 'true'){
            return true;
        }else{
            return false;
        }
    };

    setBusy = (busy) => {
        if (busy){
            localStorage.setItem("payment_busy", "true");
        }else{
            localStorage.setItem("payment_busy", "false");
        }
    };

    componentDidMount() {
        if (this.isBusy()){
            return
        }
        this.setBusy(true);
        (async ()=> {
            await this.doPayment();
            this.setBusy(false);
        })();
    }

    render() {
        return <div style={{height: 'calc(100vh)'}}>
            <LoadingOverlay
                active={true}
                spinner={<Loader />}
            >
                <div style={{height: 'calc(100vh)'}}>

                </div>
            </LoadingOverlay>

            </div>
    }

}

export default PaymentRedirect;
