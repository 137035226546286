
import CoffeeImage from "../../../images/product_real/tarolatte.jpg";
import * as React from "react";
import IntroCard from "./IntroCard";


export default function IntroTaroLatteCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(120,60,100,0.88),rgba(80, 30, 50,0.97))'}
        title={'타로 라떼'}
        detail={<>풍부하고,<br/>부드러운.</>}
        description={<>
            염증 제거와 항산화 효과가 있는 타로가<br/>
            우유와 만나 풍부하고 부드러운 조화를 선사합니다.<br/>
            부드럽고 깔끔한 타로의 풍미를 한껏 담아냈습니다.<br/>
            건강에도 좋고 맛도 좋은 타로라떼를 마셔보세요.
            </>}
        image={CoffeeImage}
    />


}
