import Col from "react-bootstrap/Col";
import SelectableCard from "../checkout/SelectableCard";
import {Row} from "react-bootstrap";
import React from "react";
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';


class SubscriptionFreq extends React.Component{

    state = {
        weeks: null
    };

    componentDidMount() {
        let weeks = this.props.weeks;
        this.selectWeeks(weeks);
    }

    selectWeeks = (weeks) => {
        if (!weeks){
            return;
        }
        this.setState({
            weeks
        });
        this.props.onChange(weeks);
    };

    isSelected = (weeks) => {
        return this.state.weeks === weeks;
    };

    render() {
        return (
            <div>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} className={'mb-2'}>
                        <SelectableCard
                            selected={this.isSelected(1)}
                            title={'1주에 한 번'}
                            icon={<Filter1Icon/>}
                            badge={<badge className={'badge badge-primary'}>추천</badge>}
                            onClick={()=>{this.selectWeeks(1)}}
                        />
                    </Col>
                    <Col xs={12} className={'mb-2'}>
                        <SelectableCard
                            selected={this.isSelected(2)}
                            title={'2주에 한 번'}
                            icon={<Filter2Icon/>}
                            badge={''}
                            onClick={()=>{this.selectWeeks(2)}}
                        />
                    </Col>
                    <Col xs={12} className={'mb-2'}>
                        <SelectableCard
                            selected={this.isSelected(3)}
                            title={'3주에 한 번'}
                            icon={<Filter3Icon/>}
                            badge={''}
                            onClick={()=>{this.selectWeeks(3)}}
                        />
                    </Col>
                    <Col xs={12} className={'mb-2'}>
                        <SelectableCard
                            selected={this.isSelected(4)}
                            title={'4주에 한 번'}
                            icon={<Filter4Icon/>}
                            badge={''}
                            onClick={()=>{this.selectWeeks(4)}}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

}

export default SubscriptionFreq;
