import axios from "axios/index"


class Network {

    API = null;
    static instance = new Network();

    constructor(props) {
        this.API = axios.create({

        });
    }

    getBaseUrl = () => {
        return 'https://qv3aj9pgqj.execute-api.ap-northeast-2.amazonaws.com/prod_aws_interface/H7BU82n257mxzNzNqfSf3r';
    };

    setSessionId = (sessionId) => {
        localStorage.setItem('sessionId', sessionId);
    };

    getSessionId = () => {
        let sessionId = localStorage.getItem('sessionId');
        if (sessionId === null || sessionId === undefined || sessionId === 'undefined'){
            return null;
        }
        return sessionId;
    };

    post = (data) => {
        let url = this.getBaseUrl();
        return new Promise(resolve => {
            data['session_id'] = this.getSessionId();
            this.API.post(url, data).then((response) => {
                if (Object.keys(response.data).includes('session_id')){
                    this.setSessionId(response.data.session_id);
                }
                resolve(response.data);
            })
        })
    };

    postUrl = (data, url) => {
        return new Promise(resolve => {
            this.API.post(url, data).then((response) => {
                resolve(response.data);
            })
        })
    }

}

export default Network;
