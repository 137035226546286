import SizingComponent from "../components/mixin/SizingComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as React from "react";
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import HelpTopSection from "../components/help/HelpTopSection";
import FAQSection from "../components/help/FAQSection";

class Help extends React.Component {

    static path = "/help";

    render() {
        return (<>
            <TopMenu/>
            <Container fluid>
                <HelpTopSection />
                <FAQSection/>
            </Container>
            <Footer/>
        </>);
    }

}

export default Help;
