import * as React from "react";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TextField from "./TextField";

class InputEdit extends React.Component{

    state = {
        isEdit: false,
        value: null,
        field: null,
    };

    componentDidMount() {
        this.load(()=> {
            this.fetch();
        });
    }

    load = (callback) => {
        this.setState({
            value: this.props.value,
        }, callback)
    };

    fetch = () => {
        this.fetchField()
    };

    fetchField = () => {
        let value = this.state.value;
        if (this.state.isEdit){
            let field = <TextField type={this.props.type} value={value} onChange={(text => {
                this.setState({
                    value: text
                })
            })} onEnter={()=>{
                this.onClickEdit();
            }
            }/>;
            this.setState({
                field
            });
        }else{
            if (this.props.type === 'password'){
                let valuepassword = '';
                for (let i = 0; i < value.length; i++){
                    valuepassword += '*';
                }
                let field = <label>{valuepassword}</label>;
                this.setState({
                    field
                });
            }else{
                let field = <label>{value}</label>;
                this.setState({
                    field
                });
            }

        }
    };

    onClickEdit = () => {
        if (this.state.isEdit){
            this.setState({
                isEdit: false
            }, ()=> {
                this.fetch()
            });
            this.saveEdit();
        }else{
            this.setState({
                isEdit: true
            },()=> {
                this.fetch()
            })
        }

    };

    saveEdit = () => {
        let value = this.state.value;
        this.props.onChange(value);
    };


    render() {
        return <Container>
            <Row>
                <Col xs={12} md={3}>
                    <label style={{color: '#888'}}>
                        {this.props.title}
                    </label>
                </Col>
                <Col xs={8} md={6}>
                    {this.state.field}
                </Col>
                <Col xs={4} md={3}>
                    <a hidden={this.props.readOnly} href={'#!'} onClick={this.onClickEdit}>
                        <label style={{fontWeight: 400}}>
                            {this.state.isEdit ? '확인': '수정'}
                        </label>
                    </a>
                </Col>
            </Row>
        </Container>;
    }

}

export default InputEdit
