import API from "./api/API";
import Config from "./config";


class Password {

    static instance = new Password();

    requestChangePasswordLink = (email) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: "password.change_password_request",
            email: email
        })
    };

    changePasswordViaToken = (token, new_password) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: "password.change_password",
            token, new_password
        })
    }

}

export default Password;
