import React from 'react';
import ReactDOM from 'react-dom';
import Root from './client/Root';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


ReactDOM.render(<Root />, document.getElementById('root'));
