import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IntroBrilliantPinkCard from "../card/intro/IntroBrilliantPinkCard";
import TasteOrange from "../card/taste/TasteOrange";
import TasteHoney from "../card/taste/TasteHoney";
import IngredientCard from "../card/Pros/IngredientCard";
import IntroBrilliantPinkBeanCard from "../card/intro/IntroBrilliantPinkBeanCard";


export default function BrilliantPinkBeanPage(props) {

    const product_id = 'fTw766MTTgrJ5dgFP4YxCG';

    return <PageLayout
        product_id={product_id}
    >
        <IntroBrilliantPinkBeanCard/>
        <TasteOrange/>
        <TasteHoney/>
        <MicrolotCard/>
        <ShippingCard/>
        {/*<DripCoffeeCard/>*/}
        {/*<RTDCard/>*/}
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>
    </PageLayout>

}

