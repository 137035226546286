
import CoffeeImage from "../../../images/product_real/greentealatte.jpg";
import * as React from "react";
import IntroCard from "./IntroCard";


export default function IntroGreenTeaLatteCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(120,160,100,0.88),rgba(80, 130, 70,0.97))'}
        title={'녹차 라떼'}
        detail={<>제주가 만든,<br/>유기농 녹차.</>}
        description={<>
            제주도에서 재배 된,<br/>
            최고의 품질로 인정 받은 유기농 녹차의 쓴맛이<br/>
            고소한 우유에 녹아들어<br/>
            깔끔하고 담백한 녹차라떼를 마셔보세요.
            </>}
        image={CoffeeImage}
    />


}
