
import * as React from 'react';

import {Modal, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TopMenu from "../../TopMenu";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import CartProduct from "../../../shared/service/CartProduct";
import API from "../../../shared/service/api/API";
export default function PageLayout(props) {

    window.setVisibleKakao(false);

    const [showCart, setShowCart] = React.useState(false);
    const [unitPrice, setUnitPrice] = React.useState(0);
    const [subscriptionPrice, setSubscriptionPrice] = React.useState(0);


    const fetchProductPrice = (product) => {
        setUnitPrice(product.unit_price);
        setSubscriptionPrice(product.subscription_price);
    };

    const fetchProduct = async () => {
        const product_id = props.product_id;
        const response = await API.database.getItem(product_id);
        fetchProductPrice(response.item);
    };

    React.useEffect(()=>{
        fetchProduct();
    }, [unitPrice, subscriptionPrice]);


    return <div >
        <TopMenu showCart={showCart} hideCart={()=>{
            setShowCart(false);
        }}/>

        <BottomBar
            unit_price={unitPrice}
            subscription_price={subscriptionPrice}
            addCart={()=>{
                CartProduct.instance.appendProductId(props.product_id);
                setShowCart(true);
            }}
        />
        <TopBar
            unit_price={unitPrice}
            subscription_price={subscriptionPrice}
            addCart={()=>{
                CartProduct.instance.appendProductId(props.product_id);
                setShowCart(true);
            }}
        />

        <div
            style={{
                background: 'rgba(0, 0, 0, 0.9)',
                elevation: 20,
                margin: 0,
                padding: 0,
                paddingTop: 25
            }}
        >


            <Container>
                <Row style={{marginTop: 0}}>
                    {props.children}

                </Row>


                <Row style={{
                    height: 160
                }}/>

            </Container>



        </div>

        </div>

}
