import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../../images/product_real/flatwhite.jpg";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroFlatWhiteCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(170,160,150,0.88),rgba(180, 170, 130,0.97))'}
        title={'플랫화이트'}
        detail={<>다크초콜릿,<br/>신선한 우유.</>}
        description={<>
            고급스럽게 표현된 다크로스트 특유의 쓴맛과<br/>
            다크초콜렛과 텐저린의 상큼함의 어우러진<br/>
            묵직한 피니쉬를 뚜렷하게 느낄수 있는 진한 라떼.
            </>}
        image={CoffeeImage}
    />


}
