import swissImage from "../../../../img/swiss.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function DecafSwissCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${swissImage})`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>가장 안전한 디카페인</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>스위스 워터 프로세스</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            화학적 첨가물이 전혀 들어가지 않습니다.<br/>
                            제일 안전한 디카페인 기술인 "스위스 워터 프로세스"<br/>
                            긴 시간과 과정을 거치지만 100% 인체에 무해합니다.
                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
