import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../images/iceCoffee.png";
import SizingComponent from "../mixin/SizingComponent";
import Brazil from "../images/coffee_b_a.png";
import Ethiopia from "../images/coffee_e_a.png";
import Latte from "../images/coffee_l_a.png";
import CoffeeDark from "../images/caffe_dark.png";
import brazil from "../images/brazil.jpg";
import Button from "react-bootstrap/Button";
import API from "../../shared/service/api/API";
import Image from "../mixin/Image";
import Products from "../../pages/Products";


class MainSection5 extends SizingComponent{

    componentDidMount() {
        super.componentDidMount();
        this.loadProducts();
    }

    getProductCell = (product) => {
        let title = product.title;
        let detail = product.detail;
        let price = product.subscription_price;
        let imageId = product.image_id;
        return <Col onClick={()=>{
            window.location.href = Products.path + "/" + product.name;
        }}  lg={'4'} md={6} sm={6} xs={12} className={'text-center p-4'} style={{marginTop:15, marginBottom:15}}>
            <div style={{background: '#fafafa'}} className={'p-4'}>
                <Image imageId={imageId} style={{maxHeight:300}}/>
                <h6>{title}</h6>
                <h6>{detail}</h6>
                <h5>{price.toLocaleString()}원</h5>
            </div>
        </Col>
    };

    loadProducts = () => {
        API.database.queryItems('new_product', [
            {condition: 'eq', field: 'visible', value: true, option: 'and'}
        ]).then(data => {
            let products = data.items;
            products = products.map(product => {
                return this.getProductCell(product);
            });
            this.setState({
                productCells: products
            })
        });
    };

    render() {

        let header1 = "프리미엄 원두로 내린 수제 커피";
        if (this.state.width < 376){
            header1 = <>프리미엄 원두로 내린<br/>수제 커피</>
        }

        return <Row className={'justify-content-md-center'} style={{background: '#fff'}}>

            <Col md={'12'} className={'text-center'}>
                <div style={{marginTop: 100, marginBottom: 100}}>
                    <h3>{header1}</h3>
                </div>
            </Col>

            <Col md={10}>
                <Row className={'justify-content-center'} style={{background: '#fff'}}>

                    {this.state.productCells}

                </Row>
            </Col>

            <Col className={'text-center'} md={8}>
                <a href={Products.path} className={'btn m-3 p-3 pl-4 pr-4'} style={{fontWeight:500}}>
                    나에게 맞는 커피 선택하기
                </a>
            </Col>

        </Row>
    }

}


export default MainSection5;
