import awards from "../../../../img/award_image.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function AwardCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${awards})`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>최고를 인정받다</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>한국일보 선정<br/> 베스트 신상품 대상 </text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            "간소화된 유통단계 덕분에 제조 후 이틀 안에 <br/>
                            신선한 상품을 받아볼 수 있는 유일한 브랜드" <br/>
                            수많은 사람들이 극찬한데는 이유가 있기 마련입니다.
                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
