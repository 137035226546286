import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { slide as Menu } from 'react-burger-menu'
import Badge from "react-bootstrap/Badge";
import Home from "../pages/Home";
import Products from "../pages/Products";
import CartModal from "./CartModal";
import CartProduct from "../shared/service/CartProduct";
import API from "../shared/service/api/API";
import Login from "../pages/Login";
import MyPage from "../pages/MyPage";
import Logo from "../components/images/logo.png";
import LogoW from "../components/images/logo_w.png";
import Help from "../pages/Help";
import MenuIcon from '@material-ui/icons/Menu';
import Color from "../shared/Color";
import Container from "reactstrap/lib/Container";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


class TopMenu extends React.Component{

    state = {
        menuOpen: false,
        showCart: false,
        products: [],
    };

    menuStyle = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#000000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%',
            width: '80%',
            maxWidth: 400,
        },
        bmMenu: {
            background: '#fff',
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    };

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.fetch();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            showCart: nextProps.showCart
        });
        this.fetch();
    }

    fetch = () => {
        this.fetchLoginOrMyPageDesktop();
        this.fetchLoginOrMyPageMobile()
    };

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    isMobile = () => {
        return this.state.width < 800;
    };


    showCart = () => {
        this.setState({
            showCart: true,
            menuOpen: false,
        });
    };

    hideCart = () => {
        if (this.state.showCart){
            if (this.props.hideCart){
                this.props.hideCart();
            }
            this.setState({
                showCart: false
            });
        }
    };

    fetchLoginOrMyPageMobile = async () => {
        let loggedIn = await API.auth.isLogin();
        let loginOrMyPageMobile = null;
        if (loggedIn){
            loginOrMyPageMobile = <div className={'text-left'}>
                <a href={MyPage.path}>
                    <button className={'btn btn-success'}
                            style={{fontSize: 13, fontWeight: 600, color: 'white'}}>
                        마이페이지
                    </button>
                </a>
            </div>;
        }else{
            loginOrMyPageMobile = <div className={'text-left'}>
                <a href={Login.path}>
                    <button className={'btn btn-success'}
                            style={{fontSize: 13, fontWeight: 600, color: 'white'}}>
                        로그인
                    </button>
                </a>
            </div>;
        }
        this.setState({
            loginOrMyPageMobile
        });
    };

    fetchLoginOrMyPageDesktop = async () => {
        let loggedIn = await API.auth.isLogin();
        if (loggedIn){
            this.setState({
                loginOrMyPage: <Nav.Link href={MyPage.path}>마이페이지</Nav.Link>
            })
        }else{
            this.setState({
                loginOrMyPage: <Nav.Link href={Login.path}>로그인</Nav.Link>
            })
        }
    };

    getTopBar = () => {
        let isMobile = this.isMobile();
        const cartCount = CartProduct.instance.getProductIds().length;
        if (isMobile){
            return <>
                <Menu

                    isOpen={this.state.menuOpen}
                styles={this.menuStyle}
                customBurgerIcon={ <></> }>
                    <div style={{height: 200, padding:30, background:'linear-gradient(rgba(32,62,73,0.98),rgba(32,62,73,0.77))', width: '100%'}}>
                        <text style={{fontSize: 18, fontWeight: 300, color: 'white'}}>프리미엄 콜드브루,</text><br/>
                        <text style={{fontSize: 32, fontWeight: 600, color: 'white'}}>커피플리즈</text>

                        <br/>
                        <br/>
                        {this.state.loginOrMyPageMobile}
                    </div>
                    <div style={{padding:20}}>
                        <a href={'/products'}><button className={'btn mt-3'}>스토어</button></a> <br/>
                        <a href={Help.path}><button className={'btn mt-3'}>고객센터</button></a> <br/>
                        <a onClick={(e)=>{
                            this.showCart();
                        }}><button className={'btn mt-3'}>장바구니 <Badge style={{backgroundColor: Color.primary, color: '#fff'}}>{cartCount}</Badge></button></a> <br/>
                    </div>
                </Menu>
                <Navbar style={{backdropFilter: 'blur(4px)', backgroundColor: 'rgba(0,0,0, 0.7)'}} fixed={'top'} expand="md" variant="dark">
                    <Nav className={'mr-auto'}>
                        <Navbar.Brand>
                            <a href={'/'}>
                                <img height={30} src={LogoW}/>
                            </a>
                        </Navbar.Brand>
                    </Nav>
                    <Nav className={'ml-0'}>
                        <Navbar.Brand >

                            {cartCount > 0 ?
                                <a onClick={this.showCart}>
                                    <button className={'btn'}><Badge
                                        style={{backgroundColor: Color.primary, color: '#fff', fontSize: 13}}><ShoppingCartIcon style={{width: 12, height: 12}}/> {cartCount}</Badge>
                                    </button>
                                </a>
                                : null}

                            <MenuIcon onClick={
                                ()=> {
                                    this.setState({
                                        menuOpen: !this.state.menuOpen
                                    })
                                }
                            }/>


                        </Navbar.Brand>
                    </Nav>
                </Navbar>
                <CartModal show={this.state.showCart} onClose={this.hideCart}/>
            </>

        }else{
            return <Navbar style={{backdropFilter: 'blur(4px)', backgroundColor: 'rgba(0,0,0, 0.7)'}} fixed={'top'} expand="md" variant="dark">
                <Container>
                    <Navbar.Brand href={'/'}><img height={30} src={LogoW}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Nav className="mr-auto">
                        <Nav.Link href={'/products'}>스토어</Nav.Link>
                        <Nav.Link href={Help.path}>고객센터</Nav.Link>
                    </Nav>
                    <Nav className="ml-0">
                        {this.state.loginOrMyPage}
                        <Nav.Link onClick={this.showCart}>장바구니 <Badge style={{
                            backgroundColor: Color.primary,
                            color: '#fff',
                        }}>{CartProduct.instance.getProductIds().length}</Badge></Nav.Link>
                    </Nav>
                    <CartModal show={this.state.showCart} onClose={this.hideCart}/>
                </Container>

                    </Navbar>


        }
    };

    render() {
        return this.getTopBar();
    }

}


export default TopMenu;

