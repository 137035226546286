import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../../images/product_real/milktealatte.jpg";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import IntroCard from "./IntroCard";


export default function IntroMilkTeaLatteCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(120,110,50,0.88),rgba(80, 70, 30,0.97))'}
        title={'얼그레이 밀크티'}
        detail={<>얼그레이,<br/>시트러스.</>}
        description={<>
            잉그리쉬브렉퍼스트 베이스에 <br/>
            시트러스 향을 더한 <br/>
            상큼하고 깊은 맛을 자아내는 <br/>
            향긋하고 부드러운 얼그레이 라떼.
            </>}
        image={CoffeeImage}
    />


}
