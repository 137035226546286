import * as React from "react";
import {Button, Modal, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Icon from "../shared/res/Icon";
import CartProduct from "../shared/service/CartProduct";
import API from "../shared/service/api/API";
import Image from "./mixin/Image";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import Checkout from "../pages/Checkout";
import AddBoxIcon from '@material-ui/icons/AddBox';
import SubBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import DeleteIcon from '@material-ui/icons/Delete';



class CartContainer extends React.Component{

    state = {
        productsPrice: 0,
        totalPrice: 0,
        productsUnitPrice: 0,
        showShippingAlert: false
    };

    busy = false;

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadProductCells()
    }

    componentDidMount() {
        this.loadProductCells();
    }

    handleClose = () => {
        this.props.onClose();
    };

    getProductCell = (product) => {
        let quantity = product.quantity;
        let title = product.title;
        let detail = product.detail;
        let imageId = product.real_image_id;
        let price = product.subscription_price;


        return <><Row className={'justify-content-center mb-2'}>
            <Col xs={4} className={'text-center'} onClick={()=>{
                window.open(`/products/${product.name}`);
            }}>
                <Image imageId={imageId} style={{
                    borderRadius: 10,
                    width: '70%'
                }}/>
            </Col>
            <Col xs={8} className={'text-left mt-3'} >
                <Row>
                    <Col xs={9} onClick={()=>{
                        window.open(`/products/${product.name}`);
                    }}>
                        <h8 style={{fontWeight: 600, fontSize:15}}>{title}</h8><br/>
                        <h7 style={{fontWeight: 500}}>{detail}</h7>
                    </Col>
                    <Col xs={3} className={'text-right'}>
                        <a onClick={()=>{
                            this.removeProduct(product)
                        }}><DeleteIcon/></a>
                    </Col>
                </Row>

                <Row className={'mt-2'}>
                    <Col xs={6}>
                        <h6 className={'text-primary'}>{price.toLocaleString()}원 <h8 className={'text-dark'}>x {quantity}개</h8></h6>

                    </Col>
                    <Col xs={6} className={'text-right'}>
                        <a onClick={()=>{
                            if (quantity > 1){
                                this.subProduct(product)
                            }
                        }} className={'ml-1'}><SubBoxIcon/></a>
                        <a className={'ml-1'} onClick={()=>{this.addProduct(product)}}><AddBoxIcon/></a>
                    </Col>
                </Row>
            </Col>
        </Row>
            <hr/></>;
    };

    getProductTotalPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.subscription_price;
        });
        return price;
    };

    getProductTotalUnitPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.unit_price;
        });
        return price;
    };



    loadProductCells = (callback=()=>{}) => {
        let productIds = CartProduct.instance.getProductIds().filter(productId=>productId!==null);

        this.fetchShippingPrice(productIds);
        return API.database.getItemsByIds(productIds).then(products => {
            let _products = {};
            products.forEach(product => {
                if (product){
                    if (Object.keys(_products).includes(product.id)){
                        _products[product.id]['quantity'] += 1;
                    }else{
                        _products[product.id] = product;
                        _products[product.id]['quantity'] = 1;
                        _products[product.id]['priority'] = Object.keys(_products).length;
                    }
                }

            });

            let productValues = Object.values(_products);
            productValues = productValues.sort((a, b) => {
                return a.priority - b.priority;
            });
            let productCells = productValues.map(product => {
                return this.getProductCell(product);
            });

            let productsPrice = this.getProductTotalPrice(products);
            let productsUnitPrice = this.getProductTotalUnitPrice(products);
            this.setState({
                productCells,
                productsPrice: productsPrice,
                productsUnitPrice: productsUnitPrice
            }, () => {
                callback();
            })
        });
    };

    subProduct = (product) => {
        if (this.busy){
            return;
        }
        this.busy = true;
        CartProduct.instance.removeOneProductId(product.id);
        this.loadProductCells(() => {
            this.busy = false;
        });
    };

    addProduct = (product) => {
        if (this.busy){
            return;
        }
        this.busy = true;
        CartProduct.instance.appendProductId(product.id);
        this.loadProductCells(() => {
            this.busy = false;
        });
    };

    removeProduct = (product) => {
        CartProduct.instance.removeProducts(product.id);
        this.loadProductCells();
    };

    fetchShippingPrice = (products) => {
        if (products.length >= 5){
            this.setState({
                shippingPriceLabel: null
            })
        }else{
            this.setState({
                shippingPriceLabel: <text className={'text-warning'}>5개 이상</text>
            })
        }
    };

    tryBuyProduct = () => {
        // let productIds = CartProduct.instance.getProductIds();
        window.location.href = Checkout.path;
        // if (productIds.length >= 5){
        //     window.location.href = Checkout.path;
        // }else{
        //     this.setState({
        //         showShippingAlert: true,
        //     })
        // }
    };

    buyProduct = () => {
        this.setState({showShippingAlert:false}, ()=>{
            window.location.href = Checkout.path;
        })
    };

    render() {
        // let discountRate = Math.round((1 - this.state.productsPrice / (this.state.productsUnitPrice + 2500)) * 100);
        return <Container>
            {this.state.productCells}
            <Row className={'mt-1'}>
                <Col xs={6}>
                    <h6 style={{color: '#777777'}}>배송비</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={{color: '#777777'}}>{this.state.shippingPriceLabel} 무료 배송</h6>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <h6>상품 단품가</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={{
                        color: '#888',
                        textDecorationLine: 'line-through',
                        textDecorationStyle: 'solid'
                    }}>{this.state.productsUnitPrice.toLocaleString()}원</h6>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <h6>상품 구독가 </h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6>{this.state.productsPrice.toLocaleString()}원</h6>
                </Col>
            </Row>
            <Row>
                <Col className={'mt-3 mb-2'}>
                    <Button disabled={this.state.productsPrice === 0} className={'btn-block'} variant="primary" style={{height:55, fontSize: 20}} onClick={this.tryBuyProduct}>
                        주문하기
                    </Button>
                </Col>
            </Row>
            {/*<SweetAlert show={this.state.showShippingAlert}*/}
            {/*            onCancel={()=>{this.setState({showShippingAlert:false})}}*/}
            {/*            showCancel={true}*/}
            {/*            cancelBtnText={'추가선택'}*/}
            {/*            onConfirm={()=>{this.buyProduct()}}*/}
            {/*            confirmBtnText={'바로구매'}*/}
            {/*            title={'배송비를 절약하세요.'}>*/}
            {/*    5개 이상 구매시 무료배송을 받아보실 수 있습니다.*/}
            {/*</SweetAlert>*/}
        </Container>
    }

}


export default CartContainer;
