import Color from "../../../shared/Color";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import * as React from "react";


export default function BottomBar(props) {

    const [scrollPosition, setScrollPosition] = React.useState(0);
    const [showBottom, setShowBottom] = React.useState(false);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if (showBottom && position <= 60){
            setShowBottom(false);
        }
        if (!showBottom && position > 60){
            setShowBottom(true);
        }

    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showBottom]);

    const getBottomBarBottom = () => {
        if (showBottom){
            return 0;
        }else{
            if (window.innerWidth < 800){
                return -60 * 2;
            }else{
                return -60;
            }
        }
    };


    return <div style={{
        position: 'fixed',
        bottom: getBottomBarBottom(),
        background: 'linear-gradient(45deg, rgba(30,30,30,0.95),rgba(60, 60, 60,1))',
        width: '100%',
        zIndex: 1,
        borderWidth: 2,
        borderColor: Color.primary,
        transition: 'bottom 0.5s'
    }}>
        <Container>
            <Row>
                <Col md={12} style={{padding: 10, textAlign: 'center'}}>

                    <Button onClick={()=>{
                        props.addCart();
                    }} style={{width: '90%', height: 40, fontSize: 20, backgroundColor: Color.blue, color: '#fff', borderRadius: 15, fontWeight: 700}} endIcon={<ShoppingCartIcon/>}>
                        ₩{props.subscription_price.toLocaleString()}부터 · 정기구독
                    </Button>

                </Col>
            </Row>
        </Container>
    </div>

}
