import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import KakaoLoginIcon from "../images/kakao_login_icon.png";
import Icon from "../../shared/res/Icon";
import React from "react";
import EmailInput from "./EmailInput";
import Kakao from 'kakaojs';
import Loader from "react-spinners/DotLoader";
import LoadingOverlay from "react-loading-overlay";
import Login from "../../shared/service/Login";


class LoginSection extends React.Component{

    state = {
        showEmailLogin: false,
        busy: false
    };

    componentDidMount() {
        if (Kakao && Kakao.Auth == null) {
            Kakao.init('70f681282184925ec9d5db772a1cc8b9');
        }
        this.setState({"kakao": Kakao});
    }

    clickKakaoLogin = () => {
        //
        // this.state.kakao.Auth.login({
        //     success: (response) => {
        //         let access_token = response.access_token;
        //         if (access_token){
        //             this.setState({
        //                 busy: true
        //             });
        //             Service.login.loginKakao(access_token).then(data => {
        //                 if (data.user_id){
        //                     this.onLogin();
        //                 }else{
        //                     this.setState({
        //                         busy: false
        //                     });
        //                     alert("이메일로 가입되어 있는 계정입니다. 이메일로 시작하기 버튼을 클릭 해주세요.");
        //                 }
        //             });
        //         }
        //     },
        //     fail: (error) => {
        //         alert(JSON.stringify(error));
        //     },
        // });
        let REST_API_KEY = Login.kakao_rest_api_key;
        let REDIRECT_URI = Login.kakao_redirect_uri;

        window.location.href = 'https://kauth.kakao.com/oauth/authorize?client_id=' + REST_API_KEY + '&redirect_uri=' + REDIRECT_URI +'&response_type=code';
    };

    clickEmailLogin = () => {
        this.setState({
            showEmailLogin: !this.state.showEmailLogin
        });
    };

    onLogin = () => {
        this.props.onLogin();
    };

    getEmailInput = () => {
        if (this.state.showEmailLogin){
            return <Row className={'justify-content-center'} style={{marginLeft: 0, marginRight:20}}>
                <Col className={'text-left'} style={{}}>
                    <EmailInput onLogin={async () => {
                        this.onLogin();
                    }} loginKakao={this.clickKakaoLogin}/>
                </Col>
            </Row>
        }
        return null;
    };

    render() {
        return (
            <>


                <Col md={6}>

                    <Row className={'justify-content-center'} style={{marginLeft: 0, marginRight:20}}>

                        <Col md={12} className={'text-left'} style={{marginTop: 50}}>

                            <Button onClick={this.clickKakaoLogin} style={{height: 55, width: '90%', maxWidth: 355}} variant={'btn btn-warning text-center'}>
                                <img src={KakaoLoginIcon} width={35} style={{
                                    marginLeft: -9,
                                }}/>
                                <text style={{
                                }}>카카오로 <b>간편시작</b></text>

                                <LoadingOverlay

                                    active={this.state.busy}
                                    spinner={<Loader />}
                                >
                                </LoadingOverlay>

                            </Button>


                            {/*<a id="kakao-login-btn"></a>*/}

                        </Col>


                        <Col md={12} className={'text-left'} style={{marginTop: 15, marginBottom: 20}}>
                            <Button onClick={this.clickEmailLogin} style={{height: 55, width: '90%', maxWidth: 355}} variant={' text-center' } variant="outline-primary">
                                <text style={{
                                    marginLeft: 0,
                                    marginRight: 12
                                }}>{Icon.email}</text>
                                <text style={{
                                }}>이메일로 <b>시작하기</b></text>
                            </Button>

                        </Col>

                        <Col>
                            <p style={{fontSize: 11}}>버튼을 눌러 가입시 <a href={'docs/term.pdf'}>이용약관</a>과 <a href={'docs/privacy.pdf'}>개인정보처리방침</a>에 동의합니다.</p>
                        </Col>
                    </Row>
                    {this.getEmailInput()}

                </Col>


            </>
        );
    }

}

export default LoginSection;
