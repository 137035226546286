import CobaltVioletPattern from "../../../images/pattern/cv.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function MicrolotCard(props) {

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            marginTop: 30,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${CobaltVioletPattern})`,
            borderRadius: 40,
        }}>
            <Container fluid>
                <Row>
                    <Col style={{ paddingTop: 70, paddingBottom: 70, textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>마이크로랏 원두</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>입맛을 사로잡는<br/>완벽한 블렌딩.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            원두 농장에서 특별하게 한정 생산된<br/>
                            최고급 원두를 최고 실력의 바리스타가<br/>
                            심혈을 기울여 블랜딩 했습니다.
                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
