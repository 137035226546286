import API from "./api/API";
import Config from "./config";
import MyPage from "../../pages/MyPage";
import Service from "./Service";
import Random from "../../util/Random";


class Payment {

    static instance = new Payment();

    generateKey = () => {
        return Random.uuidv4();
    };

    saveCheckoutState = (value) => {
        let key = "PaymentInfo";
        localStorage.setItem(key, JSON.stringify(value));
    };

    getCheckoutState = () => {
        let key = "PaymentInfo";
        return JSON.parse(localStorage.getItem(key));
    };

    removeCheckoutState = () => {
        this.saveCheckoutState(null);
    };

    requestUnitOrder = (payment_method_id, product_counts, shipping_info) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.request_unit_order',
            payment_method_id, product_counts, shipping_info
        });
    };

    requestSubscriptionOrder = (subscription_id) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.request_subscription_order',
            subscription_id
        });
    };

    getPaymentPriceInfo = (product_counts) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.get_price_info',
            product_counts
        })
    };

    getNextPaymentPrice = (subscription) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.get_next_payment_price',
            subscription
        })
    };

    requestOrderPaymentKakao = async (payment_method_key) => {
        let payment_method_item = {
            key: payment_method_key,
            type: 'kakaopay'
        };
        return await this.requestPayment(payment_method_item);
    };

    requestOrderPaymentCard = async (payment_method_key) => {
        let checkoutState = this.getCheckoutState();
        let payment_method_item = {
            key: payment_method_key,
            type: 'card',
            card_number: checkoutState.card_number,
            card_name: checkoutState.card_name
        };
        return await this.requestPayment(payment_method_item);
    };

    trackPayment = async (price) => {
        try {
            window.trackPaymentFacebook(price);
            window.trackPaymentGoogle(price);
        }catch (e) {
            console.log(e);
        }
    };

    requestPayment = (payment_method_item) => {
        let checkoutState = this.getCheckoutState();
        this.trackPayment(3900 * checkoutState.productIds.length);
        if (!checkoutState){
            window.location.href = MyPage.path;
        }
        let weeks = checkoutState.weeks;
        let is_trial = checkoutState.isTrial;
        let product_counts = this.productIdsToMapList(checkoutState.productIds);
        let shipping_info = {
            buyer_addr_road: checkoutState.buyer_addr_road,
            buyer_addr_detail: checkoutState.buyer_addr_detail,
            buyer_name: checkoutState.buyer_name,
            buyer_postcode: checkoutState.buyer_postcode,
            buyer_tel: checkoutState.buyer_tel
        };
        let recommender_email = checkoutState.recommender_email;
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.request_payment',
            payment_method_item, shipping_info, weeks, product_counts, is_trial, recommender_email
        }).then(data => {
            this.removeCheckoutState();
            return data;
        });
    };

    addPaymentMethod = (key, pg_type, card_name=null, card_number=null) => {
        return API.logic.runFunction(Config.functionName, {
            cmd: 'payment.add_payment_method',
            pg_type, key, card_number, card_name
        })
    };

    getNextPaymentDate = (weeks) => {
        let current = (+(new Date())) / 1000;
        let next = current + (weeks * 7 * 24 * 60 * 60);
        return next;
    };

    productIdsToMapList = (productIds) => {
        let dict = {};
        productIds.forEach(productId => {
            if (Object.keys(dict).includes(productId)){
                dict[productId] += 1;
            }else{
                dict[productId] = 1;
            }
        });
        let list = [];
        Object.keys(dict).forEach(productId=>{
            let count = dict[productId];
            let item = {
                product_id: productId,
                count: count
            };
            list.push(item);
        });
        return list;
    };


}

export default Payment;
