import Container from "react-bootstrap/Container";
import React from "react";
import Col from "react-bootstrap/Col";
import {FormControl, InputGroup} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import API from "../../shared/service/api/API";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import KakaoLoginIcon from "../images/kakao_login_icon.png";
import InputText from "./InputText";
import Service from "../../shared/service/Service";


class EmailInput extends React.Component{

    state = {
        valid: false,
        to_login: false,
        show_password: false,
        validPassword: false,
        showLoginFailed: false,
        showIsKakaoAccount: false,
    };

    setValid = (valid) => {
      this.setState({
          valid: valid
      })
    };

    onEmailChange = (e) => {
        let email = e.target.value;
        this.setState({
            email
        });
        if (email.includes('@') && email.includes('.')){
            this.setValid(true);
        }else{
            this.setValid(false);
        }
    };

    onPasswordChange = (e) => {
        let password = e.target.value;
        this.setState({
            password
        });
        if (password.length > 6){
            this.setState({
                validPassword: true
            })
        }else{
            this.setValid({
                validPassword: false
            });
        }
    };

    getNotice = () => {
        if (this.state.to_login){
            return <text className={'mt-1 text-primary'} style={{fontSize: 12}}>다시 만나서 반가워요!</text>
        }
        if (this.state.valid){
            return <text className={'mt-1 text-primary'} style={{fontSize: 12}}>유효한 이메일입니다.</text>
        }else{
            return <text className={'mt-1'} style={{fontSize: 12}}>이메일을 입력해주세요.</text>
        }
    };


    getEmailInputStyle = () => {
        if (this.state.valid){
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #007bff',
                outline:'none',
                boxShadow:'none',
                height: 40,
                width: '90%', maxWidth: 355
            }
        }else{
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #888',
                outline:'none',
                boxShadow:'none',
                height: 40,
                width: '90%', maxWidth: 355
            }
        }
    };

    getPasswordInputStyle = () => {
        if (this.state.validPassword){
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '1px solid #007bff',
                outline:'none',
                boxShadow:'none',
                height: 50,
                width: '90%', maxWidth: 355
            }
        }else{
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '1px solid #888',
                outline:'none',
                boxShadow:'none',
                height: 50,
                width: '90%', maxWidth: 355
            }
        }
    };

    getPasswordInputType = () => {
        if (this.state.show_password){
            return 'text'
        }else{
            return 'password'
        }
    };

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (this.state.valid){
                this.next();
            }
        }
    };

    handlePasswordKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.login();
        }
    };

    login = () => {
        let email = this.state.email;
        let password = this.state.password;
        API.auth.login(email, password).then(data => {
            if (data.session_id){
                this.props.onLogin()
            }else{
                this.setState({
                    showLoginFailed: true
                })
            }
        });
    };

    register = () => {
        let email = this.state.email;
        let name = this.state.register.name;
        let phone = this.state.register.phone;
        let password = this.state.register.password;
        API.auth.register(email, password, phone, name).then(data => {
            if (data.error){
                if (data.error.code === 9){
                    alert("이미 가입된 계정입니다.");
                }else{
                    alert(data.error.message);
                }
            }else{
                API.auth.login(email, password).then(data => {
                    this.props.onLogin();
                });
            }
        });
    };

    next = () => {
        if (this.state.register){
            this.register();
        }else{
            let email = this.state.email;
            API.auth.hasAccount(email).then((data) => {
                let has_account = data.has_account;
                let login_method = data.login_method;
                if (has_account){
                    if (login_method === 'email_login'){
                        this.setState({
                            to_login: true
                        });
                    }else if (login_method === 'kakao_login'){
                        this.setState({
                            showIsKakaoAccount: true
                        });
                    }else{

                    }
                }else{
                    this.setRegisterForm();
                }
            });
        }
    };


    setRegisterForm = () => {
        this.setState({
            register: {}
        });
        let registerForm = <>
            <h4>처음이시군요! 환영합니다.</h4>
            <h6>회원가입을 진행합니다.</h6>

            <InputText placeholder={'이름'}
                       autoFocus
                       type={'text'}
                       valid_message={'반갑습니다 :)'}
                       invalid_message={'실명을 입력해주세요.'}
                       onChange={(value)=>{
                           let register = this.state.register;
                           register.name = value;
                           let valid = value.length > 1;
                           if (valid){
                               this.setState({
                                   register
                               });
                           }

                           return valid
                       }}/>

            <InputText placeholder={'휴대폰 번호'}
                       type={'text'}
                       valid_message={'이 번호로 배송 안내가 이루어집니다.'}
                       invalid_message={'배송 안내를 받을 휴대폰 번호를 입력해주세요.'}
                       onChange={(value)=>{
                           let valid = value.length > 4;
                           if (valid){
                               let register = this.state.register;
                               register.phone = value;
                               this.setState({
                                   register
                               });
                           }
                           return valid
                       }}/><br/>



                <InputText placeholder={'비밀번호'}

                            type={'password'}
                            valid_message={'사용할 수 있는 비밀번호입니다.'}
                            invalid_message={'7자리 이상의 비밀번호를 입력해주세요.'}
                            onChange={(value)=>{
                                console.log(value);
                                let valid = value.length > 6;
                                if (valid){
                                    let register = this.state.register;
                                    register.password = value;
                                    this.setState({
                                        register
                                    });
                                }
                                return valid;
                            }}/>

            <InputText placeholder={'비밀번호 확인'}
                       type={'password'}
                       valid_message={'비밀번호가 동일합니다.'}
                       invalid_message={'위의 비밀번호와 동일한 번호를 입력해주세요.'}
                       onChange={(value)=>{
                           let valid = this.state.register.password === value;
                           if (valid){
                               let register = this.state.register;
                               register.password_check = valid;
                               this.setState({
                                   register
                               })
                           }
                           return valid
                       }}/>

            </>;
        this.setState({
            registerForm
        })
    };

    getNextButton = () => {
        if (this.state.to_login){
            return <>
                    <Button onClick={this.login} className={'mt-3 btn btn-primary btn-block'} style={{height: 50, width: '90%', maxWidth: 355}}>로그인 하기</Button>
                    <div>
                        <a href={'#'} onClick={async ()=>{
                            if (window.confirm("가입시 입력하신 핸드폰 번호로 비밀번호 변경이 가능한 링크를 카카오톡/문자 메시지로 보내드립니다.")){
                                let email = this.state.email;
                                let response = await Service.password.requestChangePasswordLink(email);
                                if (response.error){
                                    alert(response.error.message);
                                    return;
                                }
                                alert("가입시 입력하신 핸드폰 번호로 카카오톡/문자 메시지가 전송 되었습니다.");
                            }
                        }} style={{fontSize: 12, color: '#888', width: '100%'}}>비밀번호가 생각나지 않습니다.</a>
                    </div>
                </>
        }
        if (this.state.valid){
            return <Button onClick={this.next} className={'mt-3 btn btn-primary btn-block'} style={{height: 50, width: '90%', maxWidth: 355}}>다음</Button>
        }
        return <Button disabled className={'mt-3 btn btn-secondary btn-block'} style={{height: 50, width: '90%', maxWidth: 355}}>다음</Button>
    };

    getPasswordInput = () => {
        if (this.state.to_login){
            return <><input style={this.getPasswordInputStyle()}
                          type={this.getPasswordInputType()}
                          placeholder={'비밀번호 (6자 이상)'}
                          autoFocus={true}
                          onChange={this.onPasswordChange}
                          onKeyPress={this.handlePasswordKeyPress}
            />
                    </>
        }
        return null;
    };

    render() {
        let kakaoButton =  <div>
            <img src={KakaoLoginIcon} width={35} style={{
            marginLeft: -9,
        }}/>
            <text style={{
            }}>카카오로 <b>간편시작</b></text>
        </div>;

        return <>
            <SweetAlert show={this.state.showLoginFailed}
                        onConfirm={()=>{this.setState({showLoginFailed: false})}}
                        title={'로그인 실패하였습니다.'}
                        confirmBtnText={'확인'}>
                비밀번호를 확인해주세요.
            </SweetAlert>

            <SweetAlert show={this.state.showIsKakaoAccount}
                        onConfirm={()=>{
                            this.setState({showIsKakaoAccount: false});
                            this.props.loginKakao();
                        }}
                        title={'카카오로 가입된 계정입니다'}
                        confirmBtnStyle={{width: '90%', maxWidth: 355, borderColor: '#fff', shadowColor: '#fff'}}
                        confirmBtnCssClass={'btn btn-warning'}
                        focusConfirmBtn={false}
                        confirmBtnText={kakaoButton}>
                <text>카카오로 <b>간편시작</b></text> 버튼을 눌러 로그인해주세요.
            </SweetAlert>

            <input style={this.getEmailInputStyle()}
                   type={'text'}
                   placeholder={'이메일'}
                   autoFocus={true}
                   onChange={this.onEmailChange}
                   onKeyPress={this.handleKeyPress}
            />
            <br/>{this.getNotice()}
            <br/>{this.getPasswordInput()}
            <br/>{this.state.registerForm}
            <br/>{this.getNextButton()}
            <br/>
        </>;
    }

}

export default EmailInput;
