import milk_ch from "../../../../img/mlik_dark_image.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";

export default function TasteDarkChocolate(props) {

    const fr = useFontRatio();

    return <Col lg={6}>
        <div style={{
            marginTop: 20,
            background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${milk_ch})`,
            backgroundSize: 'cover',
            borderRadius: 40,
        }}>
            <Container>
                <Row>
                    <Col style={{paddingTop: 70, paddingBottom: 70,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(27, Math.min(27 * fr, 27)), fontWeight: 800}} className={'text-white'}>퍼스트 테이스트</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>초콜릿</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            뚜렷히 표현된 다크초콜렛의 쓴맛<br/>
                            입안 전체를 감싸는 강렬한 바디감

                        </text>

                    </Col>

                </Row>

            </Container>

        </div>
    </Col>

}
