import React from 'react';
import queryString from "query-string";


class Trial extends React.Component {

    static path = "/trial";

    query = queryString.parse(window.location.search);

    getRecommenderEmail = () => {
        return this.query.recommender_email;
    };

    componentDidMount() { // recommender_email
        window.location.href = '__trial.html';
        localStorage.setItem('recommender_email', this.getRecommenderEmail())
    }

    render() {
        return (<>

        </>);
    }

}

export default Trial;
