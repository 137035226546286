import * as React from "react";


class TextField extends React.Component{

    state = {
        valid: true,
    };

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
    }

    getInputStyle = () => {
        if (this.state.valid){
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #007bff',
                outline:'none',
                boxShadow:'none',
                height: 28,
                width: '90%', maxWidth: 355
            }
        }else{
            return {
                background: 'transparent',
                border: 'none',
                borderBottom: '2px solid #888',
                outline:'none',
                boxShadow:'none',
                height: 28,
                width: '90%', maxWidth: 355
            }
        }
    };

    setValid = (valid) => {
        this.setState({
            valid: valid
        })
    };

    onChange = (e) => {
        let text = e.target.value;
        this.props.onChange(text);
        this.setState({
            value: text
        });
        if (text.length > 0){
            this.setValid(true);
        }else{
            this.setValid(false);
        }
    };


    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (this.state.valid){
                this.props.onEnter();
            }
        }
    };

    render() {
        let type = 'text';
        if (this.props.type){
            type = this.props.type;
        }
        return (
            <div>
                <input style={this.getInputStyle()}
                       type={type}
                       placeholder={this.props.placeholder}
                       value={this.state.value}
                       autoFocus={true}
                       onChange={this.onChange}
                       onKeyPress={this.handleKeyPress}
                />
            </div>
        );
    }

}

export default TextField;
