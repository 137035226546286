
import ProductImage from "../../../images/product_real/Cafelatte1.jpg";
import * as React from "react";
import IntroCard from "./IntroCard";


export default function IntroLatteCard(props){

    return <IntroCard
        background={'linear-gradient(45deg, rgba(92,92,73,0.98),rgba(92,92,73,0.57))'}
        title={'스페셜티 카페라떼'}
        detail={<>스페셜티 원두,<br/>신선한 원유.</>}
        description={<>
            최상의 블렌딩 기법으로 맛의 밸런스가<br/>
            좋고 맛이 깊지만 부담스럽지 않으며,<br/>
            깔끔한 쓴 맛이 고소함과 잘 조화되는<br/>
            스페셜티 원두와 신선한 원유의 콜라보.
            </>}
        image={ProductImage}
    />


}
