import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../images/iceCoffee.png";
import BackVideo from "../mov/backv.webp";
import SizingComponent from "../mixin/SizingComponent";
import Start from "../../pages/Start";
import Button from "react-bootstrap/Button";
import Products from "../../pages/Products";
import Trial from "../../pages/Trial";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import UseWindowDimensions from "../../util/UseWindowDimensions";


export default function MainSection2(props){

    const ratio = UseWindowDimensions();

        return <Row className={'justify-content-md-center'}>

            <Col style={{
                padding: 0,
                marginBottom: -6,
                zIndex: -5,
            }}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    left: 0,
                    right: 0,
                    height: '100vh',
                    overflow: 'hidden',
                }}>

                    {/*<video loop muted autoPlay poster="" style={{*/}
                    {/*    zIndex: 0,*/}
                    {/*    filter: 'brightness(70%)',*/}
                    {/*    position: 'relative',*/}
                    {/*    right: 0,*/}
                    {/*    bottom: 0,*/}
                    {/*    minWidth: '100%',*/}
                    {/*    maxHeight: '70%',*/}
                    {/*}}>*/}
                    {/*    <source src={BackVideo} type="video/webm"/>*/}
                    {/*</video>*/}
                    <img src={BackVideo} style={{
                        zIndex: -2,
                        filter: 'brightness(70%) blur(12px)',
                        position: 'relative',
                        right: 0,
                        top: -20,
                        bottom: -20,
                        left: -20,
                        marginBottom: -20,
                        minWidth: '110%',
                        height: '110vh',
                    }}/>
                    <div style={{
                        height: '100vh',
                        position:'absolute',
                        top:'32%',
                        left:0,
                        right: 0,
                        zIndex:1,
                        width: '100%'
                    }} className={'justify-content-center text-center'}>

                        <Container>
                            <Row className={'justify-content-center'}>
                                <Col sm={12}>
                                    <div style={{
                                        borderRadius: 20,
                                        // backdropFilter: 'brightness(60%) blur(12px)',
                                    }}>
                                        <text style={{fontSize: Math.min(50, Math.max(20, 50 * ratio)), fontWeight: 800}} className={'text-white'}>병 하나에 담은 억겁의 시간</text>
                                        <p style={{fontWeight: 600, fontSize: Math.max(16, Math.min(25, 25 * ratio))}} className={'text-white'}>
                                            <text style={{fontWeight: 800, fontSize: Math.max(Math.min(40, 40 * ratio), 18)}} className={'text-white'}>24시간 동안 추출한 극한의 향</text>
                                            <br/>
                                            <br/> 독자적인 드립 추출 방식으로
                                            <br/> 한방울 한방울 내린 원두의 본연 향.
                                            <br/> 완전히 새로운 커피의 기준점을 선사합니다.
                                        </p>
                                        {/*<a href={Trial.path} style={{color: '#ec3e7b', fontWeight: 900, fontSize: Math.max(20, 20 * ratio)}}>더 알아보기<ChevronRightIcon style={{paddingBottom: 3}}/></a>*/}
                                    </div>

                                    {/*<Button style={{backgroundColor: '#fff',  color: '#ec3e7b', fontSize: 20, fontWeight: 800, marginTop: 35, borderColor: "#fff", borderRadius: 30, paddingLeft: 30, paddingRight: 20}}>더 알아보기<ChevronRightIcon/> </Button>*/}
                                </Col>
                            </Row>

                        </Container>


                    </div>
                </div>
            </Col>


        </Row>

}
