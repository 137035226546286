import Color from "../../../shared/Color";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import * as React from "react";


export default function TopBar(props) {

    return <div style={{
        paddingTop: 60,
        background: 'linear-gradient(45deg, rgba(30,30,30,0.95),rgba(60, 60, 60,1))',
        width: '100%',
        zIndex: 1,
        borderWidth: 2,
        borderColor: Color.primary,
    }}>
        <Container>
            <Row>
                <Col md={6} style={{textAlign: 'center'}}>
                    <Button onClick={()=>{
                        props.addCart();
                    }} style={{textAlign: 'center', height: 60, fontSize: 16, color: 'white', fontWeight: 700}}>
                        <text style={{marginRight: 10, fontWeight: 400, color: '#AAA'}}>단품가 {props.unit_price.toLocaleString()}원 </text> 구독가 {props.subscription_price.toLocaleString()}원
                    </Button>
                </Col>
                <Col md={6} style={{padding: 10, textAlign: 'center'}}>
                    <Button onClick={()=>{
                        props.addCart();
                    }} style={{width: '90%', height: 40, fontSize: 20, backgroundColor: Color.blue, color: '#fff', borderRadius: 15, fontWeight: 700}} endIcon={<ShoppingCartIcon/>}>
                        ₩{props.subscription_price.toLocaleString()}부터 · 정기구독
                    </Button>
                </Col>
            </Row>
        </Container>
    </div>

}
