import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade/Fade";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import API from "../../shared/service/api/API";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ChangePassword(props){

    const classes = useStyles();
    const [currentPassword, setCurrentPassword] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState(null);


    const changePassword = async () => {
        let firstNewPassword = props.newPassword;
        if (firstNewPassword !== newPassword){
            alert("변경할 비밀번호가 일치하지 않습니다.");
            return;
        }
        let response = await API.auth.changePassword(currentPassword, newPassword);
        if (response.error){
            alert("현재 비밀번호 혹은 새 비밀번호를 확인해주세요.");
        }else{
            alert("비밀번호를 변경했습니다.");
        }
        props.onClose();
    };

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 200,
        }}
    >
        <Fade in={props.open}>
            <div className={classes.paper}>
                <TextField className={'m-2'} type={'password'} label="변경할 비밀번호" fullWidth value={newPassword} autoFocus={true} onChange={(e)=>{
                    setNewPassword(e.target.value)
                }}/>
                <TextField className={'m-2'} type={'password'} label="현재 비밀번호" fullWidth value={currentPassword} onChange={(e)=>{
                    setCurrentPassword(e.target.value)
                }}/>
                <br/>
                <div className={'text-right'}>
                    <Button className={'m-2'} onClick={changePassword}>변경하기</Button>
                </div>
            </div>
        </Fade>
    </Modal>

};