import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import LampBlack1 from "../../../images/product_real/LampBlack.JPG";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";


export default function IntroCard({background, image, title, detail, description}){

    const fr = useFontRatio();

    return <Col sm={12}>
        <div style={{
            background: background,
            borderRadius: 40,
        }}>
            <Container>
                <Row>
                    <Col style={{padding: 30}} lg={6} className={'mt-3 mb-3'}>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>{title}</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>{detail}</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            {description}
                        </text>
                        <br/>


                    </Col>

                    <Col lg={6} className={'mt-3 mb-3'}>
                        <img src={image} width={'100%'} style={{marginRight:0, borderRadius: 40}}/>
                    </Col>
                </Row>

            </Container>

        </div>
    </Col>

}
