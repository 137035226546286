import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../images/iceCoffee.png";
import SizingComponent from "../mixin/SizingComponent";
import Brazil from "../images/coffee_b_a.png";
import Ethiopia from "../images/coffee_e_a.png";
import Latte from "../images/coffee_l_a.png";
import CoffeeDark from "../images/caffe_dark.png";
import brazil from "../images/brazil.jpg";
import Button from "react-bootstrap/Button";
import Start from "../../pages/Start";
import Products from "../../pages/Products";
import Trial from "../../pages/Trial";


class MainSection4 extends SizingComponent{

    render() {

        let header1 = "구독으로 만나는";
        let header2 = "수제 커피";

        let body1 = "매일 아침 당신의 하루를 책임질";
        let body2 = "프리미엄 수제 커피를 만나보세요.";
        let body3 = "당신은 그럴 자격이 있는 사람입니다.";

        let start = "시작하기";

        if (this.state.width < 400) {
            return (
                <Row className={'justify-content-md-center mt-3 mb-4'} style={{background: '#fff'}}>
                    <Col xl={'6'} md={'12'} sm={'12'} className={'text-center'} style={{background:'#fff'}}>
                        <img src={brazil} width={'100%'} style={{marginRight:0, minHeight: '70%'}}/>
                    </Col>
                    <Col className={'text-center'}>
                        <div style={{margin: '10%', marginTop:'6%'}}>
                            <h2 className={'text-center'}>{header1}</h2>
                            <h2 className={'text-center'}>{header2}</h2>

                            <h3 className={'text-black text-center'} style={{marginTop:30, fontSize: 14, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                            </h3>

                            <a href={Trial.path}>
                                <Button style={{marginTop:10, height:50, width:150, fontWeight:500}}>{start}</Button>
                            </a>
                        </div>
                    </Col>

                </Row>
            );
        } else if (this.state.width < 1200) {

            return (
                <Row className={'justify-content-md-center mt-3  mb-4'} style={{background: '#fff'}}>
                    <Col xl={'6'} md={'12'} sm={'12'} className={'text-center'} style={{background:'#fff'}}>
                        <img src={brazil} width={'100%'} style={{marginRight:0, minHeight: '70%'}}/>
                    </Col>
                    <Col className={'text-center'}>
                        <div style={{margin: '10%', marginTop:'6%'}}>
                            <h1 className={'text-center'}>{header1}</h1>
                            <h1 className={'text-center'}>{header2}</h1>

                            <h3 className={'text-black text-center'} style={{marginTop:30, fontSize: 16, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                            </h3>
                            <a href={Trial.path}>
                                <Button className={'mt-5'} style={{height:50, width:150, fontWeight:500}}>{start}</Button>
                            </a>
                        </div>
                    </Col>

                </Row>
            );
        } else {
            return (
                <Row className={'justify-content-md-center mt-1  mb-4'} style={{background: '#fff'}}>
                    <Col xl={'6'} md={'6'} sm={'6'} lg={'6'} className={'text-right'} style={{margin:0}}>
                        <img src={brazil} width={'100%'} style={{marginRight:-15, minHeight: '70%'}}/>
                    </Col>
                    <Col xl={'6'} md={'6'} sm={'6'} lg={'6'} className={'text-left'}>
                        <div style={{margin: '10%', marginTop:'6%'}}>
                            <h1>{header1}</h1>
                            <h1>{header2}</h1>

                            <h3  className={'text-black'} style={{marginTop:30, marginBottom:40, fontSize: 16, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                            </h3>
                            <a href={Trial.path}>
                                <Button style={{height:50, width:150, fontWeight:500}}>{start}</Button>
                            </a>
                        </div>
                    </Col>

                </Row>
            );
        }
    }

}


export default MainSection4;
