import Network from "./network";


class Auth {

    getMe = () => {
        return Network.instance.post({
            module_name: 'cloud.auth.get_me'
        }).then((data)=> {
            return data.item;
        });
    };

    login = (email, password) => {
        return Network.instance.post({
            module_name: 'cloud.auth.login',
            email, password
        });
    };

    loginKakao = (access_token) => {
        return Network.instance.post({
            module_name: 'cloud.auth.login_kakao',
            access_token
        });
    };

    logout = () => {
        return Network.instance.post({
            module_name: 'cloud.auth.logout'
        }).then((data) => {
            Network.instance.setSessionId(null);
            return true;
        }).catch((data) => {
            return false;
        });
    };

    isLogin = () => {
        return this.getMe().then((me) => {
            return !!me;
        });
    };

    getUsers = (startKey=null) => {
        return Network.instance.post({
            module_name: 'cloud.auth.get_users',
            start_key: startKey,
            limit: 500,
        });
    };

    getUser = (userId) => {
        return Network.instance.post({
            module_name: 'cloud.auth.get_user',
            user_id: userId
        });
    };

    updateUser = (userId, item) => {
        return Network.instance.post({
            module_name: 'cloud.auth.update_user',
            user_id: userId,
            user: item,
        });
    };

    userCount = () => {
        return Network.instance.post({
            module_name: 'cloud.auth.get_user_count',
            count_system_user: false
        });
    };

    hasAccount = (email) => {
        return Network.instance.post({
            module_name: 'cloud.auth.has_account',
            email: email
        })
    };

    setMe = (field, value) => {
        return Network.instance.post({
            module_name: 'cloud.auth.set_me',
            field, value
        })
    };

    withdrwal = () => {
        return Network.instance.post({
            module_name: 'cloud.auth.delete_my_membership'
        })
    };

    register = (email, password, phone, name) => {
        return Network.instance.post({
            module_name: 'cloud.auth.register',
            email, password, extra:{
                phone: phone,
                name: name
            }
        });
    };


    changePassword = (current_password, new_password) => {
        return Network.instance.post({
            module_name: 'cloud.auth.change_password',
            current_password,
            new_password
        });
    };


}

export default Auth;
