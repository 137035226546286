import BrilliantPink1 from "../../../images/product_real/bpbean.jpg";
import * as React from "react";
import IntroCard from "./IntroCard";


export default function IntroBrilliantPinkBeanCard(props){

    return <IntroCard
        background={'linear-gradient(rgba(203,92,192,0.58),rgba(203,92,192,0.37))'}
        title={'브릴리언트 핑크 원두'}
        detail={<>산뜻한 입맛,<br/>상큼한 향미.</>}
        description={<>
            오렌지 블라썸의 향미, <br/>
            잘익은 오렌지의 상큼함,<br/>
            달콤한 꿀의 밸런스가 조화로운 마일드 커피.<br/>
            부드럽고 상큼한 커피를 좋아하신다면<br/>
            분명히 만족하실거에요.
            </>}
        image={BrilliantPink1}
    />


}
