import React from 'react';
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import MainSection1 from "../components/main/MainSection1";
import MainSection2 from "../components/main/MainSection2";
import MainSection3 from "../components/main/MainSection3";
import MainSection5 from "../components/main/MainSection5";
import MainSection6 from "../components/main/MainSection6";
import MainSection4 from "../components/main/MainSection4";
import MainSection7 from "../components/main/MainSection7";
import Footer from "../components/Footer";
import StartSection1 from "../components/start/StartSection1";

class Start extends React.Component {

    static path = "/start";

    render() {
        return (<>
            <TopMenu/>
            <Container fluid>
                <StartSection1/>
                <MainSection2/>
                <MainSection3/>
                <MainSection5/>
                <MainSection6/>
                <MainSection4/>
                <MainSection7/>
            </Container>
            <Footer/>
        </>);
    }

}

export default Start;
