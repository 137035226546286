import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SizingComponent from "../mixin/SizingComponent";
import brazil from "../images/brazil3d.png";
import Image from "react-bootstrap/Image";



class MainSection3 extends SizingComponent{

    render() {
        let header1 = "짧고,";
        let header2 = "정직하게.";

        let body1 = "커피플리즈의 커피는 오직 원두와 ";
        let body2 = "증류수만을 이용해 만들어집니다.";
        let body3 = "길게 보존되는 방부제 커피는 이제 그만.";
        let body4 = "자연 그대로의 정직한 커피를 경험하세요.";

        if (this.state.width < 400) {
            return (
                <Row className={'justify-content-md-center'} style={{background: '#fff', marginTop: 40}}>
                    <Col className={'text-left'}>
                        <div style={{marginTop: '20%', marginBottom: '20%'}}>
                            <h2 className={'text-center'}>{header1}</h2>
                            <h2 className={'text-center'}>{header2}</h2>

                            <h3 className={'text-black text-center'} style={{marginTop:30, fontSize: 16, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                                {body4}
                            </h3>
                        </div>
                    </Col>
                    <Col xl={'6'} md={'12'} sm={'12'} className={'text-center'} style={{background:'#fff'}}>
                        <img src={brazil} width={'100%'} style={{marginRight:0, minHeight: '70%'}}/>
                    </Col>
                </Row>
            );
        } else if (this.state.width < 1200) {

            return (
                <Row className={'justify-content-md-center'} style={{background: '#fff', marginTop: 40}}>
                    <Col className={'text-left'}>
                        <div style={{marginTop: '20%', marginBottom: '20%'}}>
                            <h1 className={'text-center'}>{header1}</h1>
                            <h1 className={'text-center'}>{header2}</h1>

                            <h3 className={'text-black text-center'} style={{marginTop:30, fontSize: 16, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                                {body4}
                            </h3>
                        </div>
                    </Col>
                    <Col xl={'6'} md={'12'} sm={'12'} className={'text-center'} style={{background:'#fff'}}>
                        <img src={brazil} width={'100%'} style={{marginRight:0, minHeight: '70%'}}/>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className={'justify-content-md-center'} style={{background: '#fff', marginTop: 40}}>
                    <Col xl={'6'} md={'6'} sm={'6'} lg={'6'} className={'text-left'}>
                        <div style={{margin: '15%'}}>
                            <h1>{header1}</h1>
                            <h1>{header2}</h1>

                            <h3  className={'text-black'} style={{marginTop:30, marginBottom:40, fontSize: 16, fontWeight: 300}}>
                                {body1}<br/>
                                {body2}<br/>
                                {body3}<br/>
                                {body4}
                            </h3>
                        </div>
                    </Col>
                    <Col xl={'6'} md={'6'} sm={'6'} lg={'6'} className={'text-right'} style={{margin:0}}>
                        <img src={brazil} width={'100%'} style={{marginRight:-15, minHeight: '70%'}}/>
                    </Col>
                </Row>
            );
        }
    }

}


export default MainSection3;
