
import React from "react";
import {Button, Modal, Row} from "react-bootstrap";
import LogoSQ from "../images/logo_sq.png";
import Close from '@material-ui/icons/Close';
import useFontRatio from "../../util/UseWindowDimensions";
import Color from "../../shared/Color";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ProductCounter from "./ProductCounter";
import scrollIntoView from 'smooth-scroll-into-view-if-needed'


import API from "../../shared/service/api/API";
import CartProduct from "../../shared/service/CartProduct";
import ProductsAPI from "../../shared/service/Products";
import Image from "../mixin/Image";


const baseChatBubbleStyle = {
    transition: 'opacity 0.5s ease-in, height 0.5s ease-in, display 0.5s ease-in',
    opacity: 0,
    borderRadius: 19,
    borderColor: 'rgba(90, 90, 90, 0.5)',
    backgroundColor: 'rgba(90, 90, 90, 0.5)',
    fontSize: 16,
    fontWeight: 700,
    paddingLeft: 17,
    paddingRight: 17,
    marginBottom: 12,
    marginRight: 20,

    textAlign: 'left'
};


function TextChatBubble(props) {

    const fr = useFontRatio();
    baseChatBubbleStyle.fontSize = Math.max(Math.min(fr * 17, 17), 15);

    const [opacity, setOpacity] = React.useState(0);

    React.useEffect(() => {
        const countdown = setInterval(() => {
            setOpacity(1.0);
            clearInterval(countdown);
        }, 300);
        return () => clearInterval(countdown);
    }, [opacity]);


    if (props.showIcon){
        return <div>
            <Button
                style={{
                    transition: 'opacity 0.2s ease-in',
                    opacity: opacity,
                    borderRadius: 19,
                    borderColor: 'rgba(90, 90, 90, 0.5)',
                    backgroundColor: 'rgba(90, 90, 90, 0.5)',
                    height: 38,
                    width: 38,
                    marginLeft: 20,
                    marginBottom: 12,
                    textAlign: 'center',
                    marginRight: 10,
                }}
            ><img src={LogoSQ} style={{
                    opacity: opacity,
                    marginLeft: -4,
                    marginTop: -3,
                    right: 0
                }} width={20} height={20}/>
            </Button>
            <Button onClick={props.onClick} style={{...props.style, ...baseChatBubbleStyle, opacity: opacity, maxWidth: '70%'}}>
            {props.children}
        </Button>
            <br/>
        </div>
    }else{
        return <div style={{textAlign: props.align}}><Button onClick={props.onClick} style={{...props.style, ...baseChatBubbleStyle,
            opacity: opacity,
            marginLeft: 68
        }}>
            {props.children}
        </Button>
            <br/>
        </div>
    }
}

function MyTextChatBubble(props) {
    const [opacity, setOpacity] = React.useState(0);
    const [hover, setHover] = React.useState(false);

    React.useEffect(() => {
        const countdown = setInterval(() => {
            setOpacity(1.0);
            clearInterval(countdown);
        }, 300);
        return () => clearInterval(countdown);
    }, [opacity]);

    const onMouseEnter = () => {
        setHover(true);
    };

    const onMouseLeave = () => {
        setHover(false);
    };

    const getStyle = () => {
        if (props.selected){
            return {
                borderColor: 'rgba(225, 125, 255, 0.7)',
                backgroundColor: 'rgba(225, 125, 255, 0.75)',
                color: 'white',
            }
        }
          if (hover){
              return {
                  borderColor: 'rgba(225, 125, 255, 0.9)',
                  backgroundColor: 'rgba(225, 125, 255, 0.99)',
                  color: 'white',
              }
          }else{
              return {
                  borderColor: 'rgba(255, 255, 255, 0.8)',
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  color: 'black',
              }
          }
    };


    return <div
        style={{textAlign: 'right'}}><Button

        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}

        onClick={()=>{
            if (props.onSelect){
                props.onSelect(props.name);
            }
            if (props.onClick){
                props.onClick()
            }
    }} style={{...props.style, ...baseChatBubbleStyle,
        opacity: opacity,
        marginLeft: 38,
        ...getStyle()
    }}>
        {props.children}
    </Button>
        <br/>
    </div>;
}


function BubbleGroup(props) {

    const [selectedIndex, setSelectedIndex] = React.useState(null);
    // 대화 그룹
    return <text style={{
        color: '#fff'
    }}>

        {props.bubbles.map((bubble, idx)=>{
            return <MyTextChatBubble
                disabled={selectedIndex !== idx && !props.canRepress}
                selected={selectedIndex === idx}
                onSelect={()=>{
                    if (selectedIndex === null || props.canRepress){
                        props.onSelect(idx);
                        setSelectedIndex(idx);
                    }
                }}
                onClick={()=>{
                    props.onClick(idx);
                }}
            >{bubble.message}</MyTextChatBubble>
        })}

    </text>


}

function ProductBubble(props) {


    const [count, setCount] = React.useState(props.count);
    if (count === 0){
        return <TextChatBubble style={{padding: 0, backgroundColor: 'rgba(0,0,0,0)'}}>
                {/*<img src={props.image} style={{position: 'relative', width: 'calc(100% + 35px)', margin: 0, zIndex: -10, marginLeft: -18, marginRight: -17, borderRadius: 19}}/>*/}
                <div style={{
                    // background: 'linear-gradient( to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(0,0,0,0.7), rgba(0,0,0, 0.9) )',
                    position:'relative',
                    zIndex:10,
                    bottom: 0,
                    top: 0,
                    height: '100%',
                    width: '100%'
                }} className={'justify-content-left text-left'}>
                    <div style={{
                        position:'relative',
                        bottom: 0,
                        width: '100%',
                        paddingLeft: 3,
                        paddingBottom: 15,
                        paddingTop: 15,

                    }}>
                        <text style={{fontSize: 16, fontWeight: 400}}>{props.subtitle} </text><br/>
                        <text style={{fontSize: 20, fontWeight: 600}}>{props.title}</text><br/>
                        <text style={{fontSize: 15, fontWeight: 500}}>
                            {props.description}
                        </text><br/>

                        <Button style={{
                            marginTop: 10,
                            borderColor: Color.primary,
                            textAlign: 'center',
                            paddingTop: 2,
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            borderRadius: 10,
                            height: 30,

                        }} onClick={()=>{
                            setCount(count + 1);
                        }}>
                            <text>추가하기</text>
                        </Button>


                    </div>
                </div>
        </TextChatBubble>
    }
    return <TextChatBubble style={{padding: 0, backgroundColor: 'rgba(0,0,0,0)'}}>
        <div style={{
            opacity: 1,
            position: 'relative',
            width: 'calc(100% + 34px)', margin: 0, zIndex: 0, marginLeft: -17, marginRight: -17, borderRadius: 19,
            overflow: 'hidden',
        }}>
            <Image imageId={props.image_id} style={{position: 'relative', width: 'calc(100% + 35px)', margin: 0, zIndex: -10, marginLeft: -18, marginRight: -17, borderRadius: 19}}/>
            {/*<img src={props.image} style={{position: 'relative', width: 'calc(100% + 35px)', margin: 0, zIndex: -10, marginLeft: -18, marginRight: -17, borderRadius: 19}}/>*/}
            <div style={{
                background: 'linear-gradient( to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.0), rgba(0,0,0,0.7), rgba(0,0,0, 0.9) )',
                position:'absolute',
                zIndex:10,
                bottom: 0,
                top: 0,
                height: '100%',
                width: '100%'
            }} className={'justify-content-left text-left'}>
                <div style={{
                    position:'absolute',
                    bottom: 0,
                    width: '100%',
                    padding: 18,
                }}>
                    <text style={{fontSize: 16, fontWeight: 400}}>{props.subtitle} </text><br/>
                    <text style={{fontSize: 20, fontWeight: 600}}>{props.title}</text><br/>
                    <text style={{fontSize: 15, fontWeight: 500}}>
                        {props.description}
                    </text><br/>

                        <ProductCounter style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }} count={count}
                                onIncrease={()=>{
                                    props.onChange(count + 1);
                                    setCount(count + 1);
                                }}
                                        onDecrease={()=>{
                                            props.onChange(Math.max(0, count - 1));
                                            setCount(Math.max(0, count - 1))
                                        }}
                        />

                    <a href={'#!'} style={{
                        color: Color.primary
                    }} onClick={()=>{
                        if (props.onClickSeeMore){
                            props.onClickSeeMore();
                        }
                    }}>더 알아보기 <ChevronRightIcon/></a>

                </div>
            </div>
        </div>

    </TextChatBubble>

}



function GeneralProductBubble(props) {

    const fr = useFontRatio();


    const [product, setProduct] = React.useState(null);

    const fetchProducts = async () => {
        const product_name = props.product_name;
        let items = await ProductsAPI.instance.listVisibleStore();
        for (const item of items){
            if (item.name === product_name){
                setProduct(item);
                return
            }
        }
    };

    React.useEffect(()=>{
        fetchProducts();
    }, []);

    if (product){
        return <ProductBubble
            image_id={product.real_image_id}
            subtitle={product.detail}
            title={product.title}
            description={<text style={{fontSize: Math.max(11, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                {product.description}
            </text>}
            onClickSeeMore={()=>{
                window.open(`/products/${product.name}`);
            }}
            onChange={props.onChange}
            count={props.count}/>
    }
    return null;

}




let timeouts = [];
let productCounts = [];


const productIds = {

};

API.database.queryItems("new_product", []).then(response=>{
    const items = response.items;
    for (const item of items){
        productIds[item.name] = item.id;
    }
});

export default function AISelectorModal(props) {

    const [datas, setDatas] = React.useState([]);
    const [lastData, setLastData] = React.useState(null);

    const [inited, setInited] = React.useState(false);
    const [bubbles, setChatBubbles] = React.useState([]);
    const [lastBubble, setLastBubble] = React.useState(null);

    let typeIndex = null;
    let tasteIndex = null;
    let latteIndex = null;
    let sugarLatteIndex = null;
    let caffeineIndex = null;
    let freqIndex = null;


    let milkteaIndex = null;
    let greenLatteIndex = null;
    let taroLatteIndex = null;


    const addBubble = (bubble) => {
        return new Promise(resolve => {
            bubbles.push(bubble);
            // bubbles.push(<NowEditingBubble/>);
            setChatBubbles(bubbles);
            setLastBubble(bubble);
            setTimeout(()=>{
                resolve();
                scrollToBottom();
            }, 700)
        });
    };

    const addData = (data) => {
        return new Promise(resolve => {
            datas.push(data);
            setDatas(datas);
            setLastData(data);
            timeouts.push(setTimeout(()=>{
                resolve();
                scrollToBottom();
            }, 500));
        });
    };

    const messagesEndRef = React.useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current){
            scrollIntoView(messagesEndRef.current, { behavior: "smooth", block: "end" })
        }
    };

    const getProducts = async () => {
        const response = await API.database.queryItems('new_product', [
            {condition: 'eq', field: 'visible_store', value: true, option: 'and'}
        ]);
        return response.items;
    };

    const renderDatas = () => {
        const addAllCount = (array) => {
            let value = 0;
            for (const elem of array){
                value += elem.count;
            }
            return value;
        };
        return datas.map(data=>{
            if (data.type === 'me'){
                return <MyTextChatBubble onClick={()=> {

                }}>{data.message}</MyTextChatBubble>
            }else if (data.type === 'group'){
                return <BubbleGroup
                    bubbles={data.bubbles}
                    canRepress={data.canRepress}
                    onClick={(idx)=>{
                        if (data.onClick) {
                            data.onClick(idx);
                        }
                    }}
                    onSelect={(idx)=>{
                        if (data.onSelect) {
                            data.onSelect(idx);
                        }
                    }}
                />
            }else if (data.type === 'products'){
                let allCount = 0;
                productCounts = [];
                let targetProductNames = [];
                if (data.category === 'rtd'){
                    targetProductNames = ['another_green', 'brilliant_pink', 'cobalt_violet', 'decaf', 'flat_white', 'lamp_black', 'brazil', 'ethiopia', 'latte'];
                }else if (data.category === 'undiluted'){
                    targetProductNames = ['brilliant_pink_undiluted', 'cobalt_violet_undiluted', 'lamp_black_undiluted', 'decaf_undiluted']
                }else if (data.category === 'bean'){
                    targetProductNames = ['brilliant_pink_bean', 'cobalt_violet_bean', 'lamp_black_bean'];
                }else if (data.category === 'milktea'){
                    targetProductNames = ['milktealatte', 'greenlatte', 'tarolatte'];
                }

                for (const productName of targetProductNames){
                    productCounts.push({
                        name: productName,
                        count: data.productNames.filter(n=>n===productName).length
                    });
                    allCount += data.productNames.filter(n=>n===productName).length;
                }
                productCounts.sort((a, b)=>{
                    return a.count - b.count;
                });
                let counter = 0;
                let minimum = 5;
                if (data.category === 'rtd'){
                    minimum = 5;
                }
                if (data.category === 'milktea'){
                    minimum = 5;
                }
                if (data.category === 'undiluted'){
                    minimum = 3;
                }
                if (data.category === 'bean'){
                    minimum = 2;
                }
                while (addAllCount(productCounts) < minimum && allCount > 0){
                    if (productCounts[counter].count > 0){
                        productCounts[counter].count++;
                    }
                    counter++;
                    counter = counter % productCounts.length;
                }
                return productCounts.map((productCount, idx)=>{
                    const name = productCount.name;
                    const count = productCount.count;
                    return <GeneralProductBubble
                        count={count}
                        product_name={name}
                        onChange={(count)=>{
                            productCount.count = count;
                        }}
                    />;

                });
            }else{
                return <TextChatBubble
                    showIcon={data.showIcon}
                >
                    {data.message}
                </TextChatBubble>
            }
        });
    };

    const addCoffeeTaste = async () => {
        await addData({
            showIcon: true,
            message: '안녕하세요 개인 AI 바리스타, Alex 입니다. 😁'
        });

        await addData({
            message: '취향 저격 커피를 추천해 드릴게요.'
        });

        await addData({
            message: '어떤 제품을 추천받고 싶으세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '☕ 바로 마실 수 있는 커피와 라떼요!'
                },
                {
                    message: '💧 우유나 물에 타서 마실 수 있는 원액이요 :)'
                },
                {
                    message: '🤎  로스팅된 원두를 추천받고 싶어요!'
                },
            ],

            onSelect: (idx) => {
                if (idx === 0){
                    doRTD();
                }else if (idx === 1){
                    doUndiluted();
                }else if (idx === 2){
                    doRoastedBean();
                }
                typeIndex = idx;
            }
        });


    };

    // Rosting
    const doRoastedBean = async () => {
        await addData({
            showIcon: true,
            message: '어떤 커피를 좋아하세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😉 커피는 원래 고소한 맛이죠!'
                }, {
                    message: '🥰 커피는 부드러운 산미가 중요하죠 :)'
                }, {
                    message: '⚖️ 고소함과 산미 둘 다 중요하죠.'
                }
            ],

            onSelect: (idx) => {
                tasteIndex = idx;
                drinkFreq('bean');
                // complete('bean');
            }
        });
    };


    // One Ack
    const doUndiluted = async () => {
        await addData({
            showIcon: true,
            message: '어떤 커피를 좋아하세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😉  커피는 원래 고소한 맛이죠!'
                }, {
                    message: '🥰  커피는 부드러운 산미가 중요하죠 :)'
                }, {
                    message: '⚖️  고소함과 산미 둘 다 중요하죠.'
                }
            ],

            onSelect: (idx) => {
                tasteIndex = idx;
                drinkFreq('undiluted');
                // complete('undiluted');
            }
        });
    };

    // Milktea
    const doMilktea = async () => {
        await addData({
            showIcon: true,
            message: '달달한 얼그레이 밀크티 좋아하세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😅 달달한건 제 취향은 아니에요.'
                }, {
                    message: '🥰 좋아요! :)'
                }
            ],

            onSelect: (idx) => {
                milkteaIndex = idx;
                doGreenLatte();
            }
        });
    };

    const doGreenLatte = async () => {
        await addData({
            showIcon: true,
            message: '녹차 라떼는 어떠세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😅 녹차라떼는 좋아하지 않아요.'
                }, {
                    message: '🥰 씁슬 달달한 녹차 라떼, 좋아요! :)'
                }
            ],

            onSelect: (idx) => {
                greenLatteIndex = idx;
                doTaroLatte();
            }
        });
    };

    const doTaroLatte = async () => {
        await addData({
            showIcon: true,
            message: '달지 않고 담백한 타로라떼 추천해드릴까요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😅 별로에요.'
                }, {
                    message: '🥰 좋아요! 마셔볼래요 :)'
                }
            ],

            onSelect: (idx) => {
                taroLatteIndex = idx;
                drinkFreq('milktea');
                // complete('milktea');
            }
        });
    };

    // RTD

    const doRTD = async () => {

        await addData({
            showIcon: true,
            message: '어떤 커피를 좋아하세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😉  커피는 원래 고소한 맛이죠!'
                }, {
                    message: '🥰  커피는 부드러운 산미가 중요하죠 :)'
                }, {
                    message: '⚖️  고소함과 산미 둘 다 중요하죠.'
                }
            ],

            onSelect: (idx) => {
                doYouLikeLatte();
                tasteIndex = idx;
            }
        });


    };

    const doYouLikeLatte = async () => {

        await addData({
            showIcon: true,
            message: '라떼 좋아하세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😉  라떼보다는 아메리카노죠!'
                }, {
                    message: '🥛  저는 라떼만 마셔요!'
                }, {
                    message: '🥺 둘 다 좋아요.'
                }
            ],

            onSelect: (idx) => {
                if (idx === 0){
                    doYouLikeCaffein();
                }else{
                    doYouLikeSugarLatte();
                }

                latteIndex = idx;
            }
        });


    };

    const doYouLikeSugarLatte = async () => {

        await addData({
            showIcon: true,
            message: '바닐라라떼는 어때요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '😉  좋아요!'
                }, {
                    message: '🥛  저는 달지 않은 라떼가 좋아요.'
                }
            ],

            onSelect: (idx) => {
                doYouLikeCaffein();
                sugarLatteIndex = idx;
            }
        });


    };

    const doYouLikeCaffein = async () => {

        await addData({
            showIcon: true,
            message: '카페인은 괜찮으세요?'
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '🤩 카페인! 제 삶의 동반자에요!'
                }, {
                    message: '😉 가끔은 디카페인이 필요해요.'
                }, {
                    message: '😅 저는 디카페인만 마셔요!'
                }
            ],

            onSelect: (idx) => {
                drinkFreq('rtd');
                caffeineIndex = idx;
            }
        });

    };

    const drinkFreq = async (category) => {
        let question = '커피를 얼마나 자주 마시나요?';
        if (category === 'milktea'){
            question = '밀크티를 얼마나 자주 마시나요?';
        }
        await addData({
            showIcon: true,
            message: question
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '🔥 하루에 두잔 이상 마셔요!'
                }, {
                    message: '☕ 하루에 한잔 정도 마셔요 :)'
                }, {
                    message: '😊 이틀에 한잔 마셔요.'
                }
            ],

            onSelect: (idx) => {
                freqIndex = idx;
                complete(category);
            }
        });

    };

    const getProductCountsRTD = async () => {
        let productCounts = [];
        let productNames = [];
        if (tasteIndex === 0){
            productNames.push('lamp_black');
            productNames.push('cobalt_violet');
            productNames.push('brazil');
        }
        if (tasteIndex === 1){
            productNames.push('brilliant_pink');
            productNames.push('ethiopia');
        }
        if (tasteIndex === 2){
            productNames.push('brilliant_pink');
            productNames.push('lamp_black');
            productNames.push('cobalt_violet');
            productNames.push('brazil');
            productNames.push('ethiopia');
        }
        if (latteIndex === 0){

        }
        if (latteIndex === 1){
            productNames = [];
            productNames.push('flat_white');
            productNames.push('latte');
        }
        if (latteIndex === 2){
            productNames.push('flat_white');
            productNames.push('latte');
        }
        if (sugarLatteIndex === 0){
            productNames.push('another_green')
        }
        if (caffeineIndex === 0){

        }
        if (caffeineIndex === 1){
            if (latteIndex !== 1){
                productNames.push('decaf');
            }
            if ((latteIndex === 1 || latteIndex === 2) && sugarLatteIndex === 0){
                productNames.push('another_green')
            }
        }
        if (caffeineIndex === 2){
            productNames = [];
            if ((latteIndex === 1 || latteIndex === 2) && sugarLatteIndex === 0){
                productNames.push('another_green')
            }
            if (latteIndex !== 1){
                productNames.push('decaf');
            }
        }
        if (latteIndex === 1 && sugarLatteIndex === 1 && caffeineIndex === 2){
            productNames.push('another_green');
            await addData({
                message: '달지 않은 디카페인 라떼가 없어서, 케인슈가를 넣은 디카페인 라떼를 선별했어요 😅'
            });
            await addData({
                message: '많이 달지 않아서 괜찮을거에요!'
            });
        }
        return productNames;
    };

    const getProductCountsUndiluted = async () => {
        let productNames = [];
        if (tasteIndex === 0){
            productNames.push('lamp_black_undiluted');
            productNames.push('cobalt_violet_undiluted');
        }
        if (tasteIndex === 1){
            productNames.push('brilliant_pink_undiluted');
            productNames.push('decaf_undiluted');
        }
        if (tasteIndex === 2){
            productNames.push('brilliant_pink_undiluted');
            productNames.push('lamp_black_undiluted');
        }

        return productNames;
    };

    const getProductMilktea = async () => {
        let productNames = [];
        if (milkteaIndex === 1){
            productNames.push('milktealatte');
        }
        if (greenLatteIndex === 1){
            productNames.push('greenlatte');
        }
        if (taroLatteIndex === 1){
            productNames.push('tarolatte');
        }

        return productNames;
    };


    const getProductCountsBean = async () => {
        let productNames = [];
        if (tasteIndex === 0){
            productNames.push('lamp_black_bean');
            productNames.push('cobalt_violet_bean');
        }
        if (tasteIndex === 1){
            productNames.push('brilliant_pink_bean');
        }
        if (tasteIndex === 2){
            productNames.push('brilliant_pink_bean');
            productNames.push('lamp_black_bean');
        }

        return productNames;
    };

    const complete = async (category) => {


        let productNames = [];

        if (category === 'rtd'){
            productNames = await getProductCountsRTD();
        }else if (category === 'undiluted'){
            productNames = await getProductCountsUndiluted();
        }else if (category === 'bean'){
            productNames = await getProductCountsBean();
        }else if (category === 'milktea'){
            productNames = await getProductMilktea();
        }


        let ment = '취향 저격 커피들을 선별했어요! 💖';

        if (category === 'milktea'){
            ment = '취향 저격 밀크티를 선별했어요! 💖';
        }

        await addData({
            showIcon: true,
            message: ment
        });

        await addData({
            message: '구성을 변경할수도 있어요 😊'
        });

        await addData({
            type: 'products',
            category: category,
            productNames: productNames
        });

        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '🛍️ 장바구니에 담을래요!'
                },
            ],

            onSelect: (idx) => {
                for (const productCount of productCounts){
                    for (let i = 0; i < productCount.count; i++){
                        CartProduct.instance.appendProductId(productIds[productCount.name]);
                    }
                }
                props.onShowCart(true);
                closeModal();
            }
        });

    };

    const closeModal = async () => {
        await addData({
            type: 'group',
            bubbles: [
                {
                    message: '🏠 홈으로 돌아가기'
                },
            ],

            onSelect: (idx) => {
                closeMe();
            }
        });
    };



    const addInitBubbles = async () => {
        bubbles.push(<div style={{height: 30}}></div>);
        await addCoffeeTaste();

    };

    const initScripts = async () => {
        if (inited){
            return;
        }
        setInited(true);

        await addInitBubbles();

    };

    const closeMe = () => {
        props.onShowCart(false);
        props.onHide();
    };

    React.useEffect(()=>{
        if (props.show){
            initScripts();
        }else{
            for (const timeout of timeouts){
                clearTimeout(timeout);
            }
            timeouts = [];
            setDatas([]);
            setInited(false);
        }
    }, [props.show, tasteIndex]);

    let backdropFilter = 'blur(20px) ';
    if (props.brightness){
        backdropFilter += 'brightness(' + props.brightness + '%)';
    }

    return <Modal
        transparent={true}
        show={props.show}
        onHide={()=>{
            closeMe();
        }}
        className={'transition-height'}
        style={{
            transition: 'height 0.5s ease-in',
            backdropFilter: backdropFilter,
            webkitBackdropFilter:backdropFilter,
        }}>
        <Modal.Body

            transparent={true}
            style={{
                transition: 'height 0.5s ease-in',
                backdropFilter: 'blur(20px)',
                background: 'rgba(0, 0, 0, 0.0)',
                borderRadius: 0,
                borderColor:  'rgba(0, 0, 0, 0.0)',
                borderWidth: 0,
                elevation: 20,
                margin: 0,
                padding: 0,
                height: 0,
            }}
        >
            <div style={{
                textAlign: 'right'
            }}>
                <Button
                    onClick={()=>{
                        closeMe();
                    }}
                    style={{
                        transition: 'opacity 0.5s ease-in',
                        opacity: 1.0,
                        borderRadius: 19,
                        borderColor: 'rgba(0, 0, 0, 0.0)',
                        backgroundColor: 'rgba(90, 90, 90, 0.0)',
                        height: 38,
                        paddingLeft: -10,
                        paddingTop: -10,

                        width: 38,
                        marginLeft: 20,
                        marginBottom: 12,
                        textAlign: 'center',
                        margin: 16,
                    }}
                ><Close/>
                </Button>

            </div>


            <text
                ref={messagesEndRef}
                style={{
                    color: '#fff'
                }}>

                {renderDatas()}

                <br/>
                <div style={{height: 100}}></div>

            </text>

        </Modal.Body>
    </Modal>

}
