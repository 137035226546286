import React from 'react';
import TopMenu from "../components/TopMenu";
import AISelectorModal from "../components/main/AISelecterModal";


let init = false;

export default function Home2(props){

    const [showCart, setShowCart] = React.useState(false);

    const [scrollPosition, setScrollPosition] = React.useState(0);
    const [showBottom, setShowBottom] = React.useState(false);
    const [showAI, setShowAI] = React.useState(false);

    const handleScroll = () => {
        let position = window.pageYOffset;
        if (!position){
            position = 0;
        }
        setScrollPosition(position);
        const point = 200;
        if (showBottom && position <= point){
            setShowBottom(false);
        }
        if (!showBottom && position > point){
            setShowBottom(true);
        }

    };

    React.useEffect(() => {
        if (!init){
            console.log('init');
            setTimeout(()=>{
                // Instagram 등의 브라우저에서 처음에 이상하게 모양 잡히는 이슈 해결
                window.dispatchEvent(new Event('resize'));
                console.log('resize');
            }, 500);
            init = true;
        }
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showBottom]);

    window.setVisibleKakao(false);

    const getBottomBarBottom = () => {
        if (showBottom){
            return 0;
        }else{
            return -60;
        }
    };

    window.setShowAI = (visible) => {
        setShowAI(visible);
    };

    return (<>
        <TopMenu showCart={showCart}/>

        <AISelectorModal onShowCart={(show)=>{
            setShowCart(show);
        }} show={showAI} onHide={()=>{
            setShowAI(false);
        }}/>

        <iframe src="/index2.html"
                style={{
                    position:'fixed',
                    top:0,
                    left:0,
                    bottom:0,
                    right:0,
                    width:"100%",
                    height:"100%",
                    border:0,
                    margin:0,
                    padding:0,
                    overflow:'hidden',
                    zIndex:0
                }}/>

    </>);

}
