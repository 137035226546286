import dripcoffee from "../../../../img/dripcoffee_image.jpg";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import * as React from "react";
import useFontRatio from "../../../../util/UseWindowDimensions";
import dripssoVid from "../../../../assets/video/maindripsso.webm";
import dripssoVidMp4 from "../../../../assets/video/maindripsso.mp4";

export default function DripCoffeeCard(props){

    const fr = useFontRatio();

    return <Col sm={12} style={{overflow: "hidden"}}>
        <div style={{
            marginTop: 30,
            // background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))`,
            // backgroundSize: 'cover',
            borderRadius: "40px",
            "-webkit-transform": "translateZ(0)",
            overflow: "hidden",
        }}>
            <picture>
                {/*<img id="main-vid-image" className="fade fade-in" src="assets/video/dripssomain.jpg" alt=""*/}
                {/*     style={{*/}
                {/*         position: "absolute", height: "50vh", objectFit: "cover", "opacity": 0.6*/}
                {/*     }}></img>*/}

                    <video muted="muted" id="main-vid" poster="assets/video/dripssomain.jpg" autoPlay loop playsInline
                           style={{
                               opacity: 0.6, minHeight: "100%", minWidth: "100%",
                               objectFit: "cover",
                               position: "absolute", overflow: "hidden",
                               maskPosition:"left top",
                               maskRepeat:"no-repeat",
                               maskSize:"150%",
                           }}>
                        <source src={dripssoVid} type="video/webm"></source>
                        <source src={dripssoVidMp4} type="video/mp4"></source>
                    </video>
            </picture>

            <Container fluid>
                <Row>
                    <Col style={{paddingTop: 170, paddingBottom: 170,  textAlign: 'center'}} lg={12} className={'mt-3 mb-3'}>
                        {/*<text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>주문 즉시 제조 프로세스</text>*/}
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(50 * fr, 50)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>정성을 마시다</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(17 * fr, 17)), fontWeight: 700, color: '#fff', lineHeight: 1.5}}>
                            핸드드립으로 한방울 한방울 정성을 들여,<br/>
                            오랜 시간 추출했습니다.<br/>
                            진한 향과 맛을 경험하신다면<br/>
                            다른 커피들은 시시하게 느껴질수도 있습니다.
                        </text>

                    </Col>

                </Row>

            </Container>


        </div>
    </Col>

}
