import API from "./api/API";


export default class ProductsAPI {

    cache = {};
    static instance = new ProductsAPI();


    constructor(props) {
        (async ()=>{
            await this.listVisibleStore();
        })();
    }


    listVisibleStore = async () => {
        const key = 'listVisibleStore';
        if (this.cache[key]){
            return this.cache[key];
        }
        let response = await API.database.queryItems('new_product', [
            {
                option: 'and',
                condition: 'eq',
                field: 'visible_store',
                value: true
            }
        ]);
        // this.cache[key] = response.items;
        return response.items;
    }

}
