
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as React from "react";
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import Button from "react-bootstrap/Button";
import TextField from '@material-ui/core/TextField';
import CheckoutContainer from "../components/CheckoutContainer";
import PaymentDialog from "../components/checkout/PaymentDialog";
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import SelectableCard from "../components/checkout/SelectableCard";
import Postcode from "../components/checkout/Postcode";
import EnrollPaymentMethod from "../util/RequestPayment";
import Random from "../util/Random";
import API from "../shared/service/api/API";
import Login from "./Login";
import CartProduct from "../shared/service/CartProduct";
import MyPage from "./MyPage";
import Service from "../shared/service/Service";

import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-spinners/DotLoader'
import Payment from "../shared/service/Payment";
import queryString from "query-string";
import Config from "../shared/service/config";
import Products from "./Products";


class Checkout extends React.Component {

    static path = "/checkout";

    query = queryString.parse(window.location.search);

    isTrial = () => {
        return this.query.is_trial === 'true';
    };

    state = {
        openPaymentMethodDialog: false,
        weeks: 1,
        openAddressModal: false,
        detailAddressFocus: false,
        busy: false,
        productIds: [],
        focusAll: false,
        isTrial: this.isTrial(),
        me: {
            mileage: 0
        }
    };


    canTrial = async () => { // 체험판 사용 가능 유저인지 확인, 이후 한번더 주소지와 함께 결제시 확인
        let response = await API.database.queryItems('new_order', [
            {condition: 'eq', field: 'is_trial', value: true, option: 'and'},
        ]);
        let orders = response.items;
        if (orders){
            return orders.length === 0;
        }
        return true;
    };


    componentDidMount() {
        (async ()=> {
            await this.checkLogin();
            if (this.isTrial()){
                if (!(await this.canTrial())){
                    alert("체험세트는 한번만 주문할 수 있습니다.");
                    this.props.history.push(Products.path);
                }else{
                    this.createInduceOrderNotification();
                }
            }
            await this.fetchFinalTotalPrice();
        })();
        this.prepareUserDefault();
        this.prepareProductIds();
        this.prepareRecommenderEmail();
    }

    prepareRecommenderEmail = () => {
        const recommender_email = localStorage.getItem('recommender_email');
        if (recommender_email !== null && recommender_email !== undefined && recommender_email !== 'undefined'){
            this.setState({
                recommender_email
            })
        }
    };

    prepareProductIds = async () => {
        if (this.isTrial()){
            // let response = await API.database.queryItems('new_product', [], 1000);
            // let products = response.items.filter(product => {
            //     return Object.keys(productSet).includes(product.code_name);
            // });
            // let productIds = [];
            // products.forEach(product => {
            //    let count = productSet[product.code_name];
            //    for (let i = 0; i < count; i++){
            //        productIds.push(product.id);
            //    }
            // });
            let productIds = [];
            let product_counts = await Config.getFreeProductCounts();
            for (let product_count of product_counts){
                let count = product_count.count;
                for (let i = 0; i < count; i++){
                    productIds.push(product_count.product_id);
                }
            }
            this.setState({
                productIds: productIds
            });
        }else{
            this.setState({
                productIds: CartProduct.instance.getProductIds()
            });
        }

    };

    prepareUserDefault = async () => {
        let me = await API.auth.getMe();
        if (!me){
            return;
        }
        let buyer_name = me.name;
        let buyer_tel = me.phone;
        this.setState({
            buyer_name,
            buyer_tel,
            focusAll: true,
            me: me
        }, ()=>{

        });
    };

    checkLogin = async () => {
        let loggedIn = await API.auth.isLogin();
        if (!loggedIn){
            if (this.isTrial()){
                this.props.history.push(Login.path + '?to_buy=true&is_trial=true')
            }else{
                this.props.history.push(Login.path + '?to_buy=true');
            }

        }
    };

    handleClickCheckout = () => {
        if (this.checkInputValids()){
            this.setState({
                openPaymentMethodDialog: true
            })
        }
    };

    handleChangeText = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [name] : value
        })
    };

    handlePaymentModalClose = () => {
        this.setState({
            openPaymentMethodDialog: false
        })
    };

    selectWeeks = (weeks) => {
        if (!weeks){
            alert("단건 결제시 구독가 할인혜택이 적용되지 않습니다.");
        }
        this.setState({
            weeks
        }, ()=>{
            this.fetchFinalTotalPrice();
        })
    };

    isSelected = (weeks) => {
        return this.state.weeks === weeks;
    };

    showErrorMessage = (message) => {
        alert(message);
    };

    showMessage = (message) => {
        alert(message);
    };

    checkInputValids = () => {
        if (!this.state.buyer_name){
            alert("주문자 이름을 입력해주세요.");
            return false;
        }
        if (!this.state.buyer_tel){
            alert("핸드폰 번호를 입력해주세요.");
            return false
        }
        if (!this.state.buyer_addr_road){
            alert("주소를 입력해주세요.");
            return false
        }
        if (!this.state.buyer_addr_detail){
            alert("상세주소를 입력해주세요.");
            return false
        }
        if (this.state.recommender_email === this.state.me.email){
            alert("자신을 추천인으로 입력할 수 없습니다.");
            return false
        }
        return true;
    };

    saveCheckoutState = () => {
        Payment.instance.saveCheckoutState(this.state);
    };

    onClickKakao = async () => {
        if (window.isRunOnInstagram()){
            // alert("인스타그램 브라우저에서는 카카오페이를 지원하지 않습니다. 사파리 혹은 크롬브라우저로 열어서 주문하거나, 신용/체크 카드 결제를 이용해주세요.");
            // return;
        }
        this.handlePaymentModalClose();
        let payment_method_key = Random.uuidv4();
        this.saveCheckoutState();
        let me = await API.auth.getMe();
        let addr = this.state.buyer_addr_road + ' ' + this.state.buyer_addr_detail;
        let postcode = this.state.buyer_postcode;
        let response = await EnrollPaymentMethod.request('kakaopay', payment_method_key, me.email, me.name, me.phone, addr, postcode);
        this.setState({
            busy: true
        });
        if (response.success){
            let response = await Payment.instance.requestOrderPaymentKakao(payment_method_key);
            if (response.code === '000000'){
                this.props.history.push(MyPage.path + "?show_subscription=1");
            }
            this.showMessage(response.message);
        }else{
            this.showErrorMessage(response.error_msg);
        }
        this.setState({
            busy: false
        });
    };

    onClickCard = async () => {
        this.handlePaymentModalClose();
        let payment_method_key = Random.uuidv4();
        this.saveCheckoutState();
        let me = await API.auth.getMe();
        let addr = this.state.buyer_addr_road + ' ' + this.state.buyer_addr_detail;
        let postcode = this.state.buyer_postcode;

        let response = await EnrollPaymentMethod.request('html5_inicis', payment_method_key, me.email, me.name, me.phone, addr, postcode);

        this.setState({
            busy: true,
            card_number: response.card_number,
            card_name: response.card_name
        }, async ()=>{
            this.saveCheckoutState();
            if (response.success){
                let response = await Payment.instance.requestOrderPaymentCard(payment_method_key);
                if (response.code === '000000'){
                    this.props.history.push(MyPage.path + "?show_subscription=1");
                }
                this.showMessage(response.message);
            }else{
                this.showErrorMessage(response.error_msg);
            }
            this.setState({
                busy: false
            });
        });
    };


    fetchFinalTotalPrice = async () => {
        this.setState({
            final_total_price: ''
        });
        let product_counts = Payment.instance.productIdsToMapList(this.state.productIds);
        let response = await Service.payment.getPaymentPriceInfo(product_counts);
        let isUnit = !this.state.weeks;
        if (this.isTrial()){
            let freePrice = await Config.getFreePrice();
            this.setState({
                final_total_price: freePrice.toLocaleString() + "원 "
            });
            return
        }
        if (isUnit){
            this.setState({
                final_total_price: ((response.unit.total_price - response.mileage).toLocaleString() + '원 ')
            })
        }else{
            if (!response){
                return
            }
            if (!(response.subscription)){
                return
            }
            this.setState({
                final_total_price: ((response.subscription.total_price - response.mileage).toLocaleString() + '원 ')
            })
        }
    };

    createInduceOrderNotification = async () => {
        await API.database.createItem("notification_queue", {
            tag: 'induce_order',
        })
    };

    render() {
        return (<>
            <LoadingOverlay
                active={this.state.busy}
                spinner={<Loader />}
            >
            <TopMenu/>
            <Container>
                <Container style={{
                    minHeight: 'calc(100vh - 350px)',
                }}>
                    <Row style={{
                        marginTop: 50,
                    }}>
                        <Col md={5} style={{
                            marginTop: 100,
                            paddingLeft: 20,
                            marginBottom: 50,
                        }}>
                            <CheckoutContainer
                                productIds={this.state.productIds}
                                isUnit={!this.state.weeks}
                                isTrial={this.isTrial()}
                                mileage={this.state.me.mileage}
                            />

                        </Col>
                        <Col md={1} style={{

                            borderColor: '#f0f0f0',
                            borderRightWidth: 1.5,
                            borderRightStyle: 'solid'}}>
                        </Col>
                        <Col md={6} style={{
                            marginTop: 100,
                            paddingLeft: 30,
                            paddingRight: 30,
                            marginBottom: 50,
                        }}>
                            <Container>
                                <Row className={'mb-4'}>
                                    <Col>
                                        <h1 style={{fontWeight: 300}}><b>배송지 정보</b>를</h1>
                                        <h1 style={{fontWeight: 300}}>입력해주세요</h1>

                                        <TextField
                                            label="주문자 이름"
                                            name={'buyer_name'}
                                            fullWidth
                                            margin="dense"
                                            className={'mr-2'}
                                            onChange={this.handleChangeText}
                                            value={this.state.buyer_name}
                                            focused={this.state.focusAll}
                                        />

                                        <TextField
                                            label="핸드폰 번호"
                                            name={'buyer_tel'}
                                            fullWidth
                                            margin="dense"
                                            onChange={this.handleChangeText}
                                            value={this.state.buyer_tel}
                                            focused={this.state.focusAll}
                                        />
                                        <br/>

                                        <TextField
                                            label="주소"
                                            fullWidth
                                            margin="dense"
                                            onFocus={(e)=>{
                                                if (!this.state.openAddressModal){
                                                    e.target.blur();
                                                    this.setState({
                                                        openAddressModal: true,
                                                    });
                                                }
                                            }}
                                            value={this.state.buyer_addr_road}
                                            name={'buyer_addr_road'}
                                            onChange={this.handleChangeText}
                                        />

                                        <Postcode openModal={this.state.openAddressModal} onComplete={(postcode, address)=>{
                                            this.setState({
                                                buyer_postcode: postcode,
                                                buyer_addr_road: address,
                                                openAddressModal: false
                                            })
                                        }} onClose={()=>{
                                            this.setState({
                                                openAddressModal: false
                                            })
                                        }}/>


                                        <TextField
                                            label="상세 주소"
                                            fullWidth
                                            margin="dense"
                                            name={'buyer_addr_detail'}
                                            onChange={this.handleChangeText}
                                        />

                                        <TextField
                                            label="추천인 이메일"
                                            fullWidth
                                            margin="dense"
                                            type={'email'}
                                            name={'recommender_email'}
                                            value={this.state.recommender_email}
                                            onChange={this.handleChangeText}
                                        />

                                    </Col>

                                </Row>


                                <Row style={{marginTop: 60}}>
                                    <Col>
                                        <h1 style={{fontWeight: 300}}><b>배송주기</b>를</h1>
                                        <h1 style={{fontWeight: 300}}>선택해주세요</h1>
                                    </Col>

                                </Row>

                                <Row style={{marginTop: 20}}>
                                    <Col xs={12} className={'mb-2'}>
                                        <SelectableCard
                                            selected={this.isSelected(1)}
                                            title={'1주에 한 번'}
                                            icon={<Filter1Icon/>}
                                            badge={''}
                                            onClick={()=>{this.selectWeeks(1)}}
                                        />
                                    </Col>
                                    <Col xs={12} className={'mb-2'}>
                                        <SelectableCard
                                            selected={this.isSelected(2)}
                                            title={'2주에 한 번'}
                                            icon={<Filter2Icon/>}
                                            badge={<badge className={'badge badge-primary'}>추천</badge>}
                                            onClick={()=>{this.selectWeeks(2)}}
                                        />
                                    </Col>

                                    <Col xs={12} className={'mb-2'}>
                                        <SelectableCard
                                            selected={this.isSelected(3)}
                                            title={'3주에 한 번'}
                                            icon={<Filter3Icon/>}
                                            badge={''}
                                            onClick={()=>{this.selectWeeks(3)}}
                                        />
                                    </Col>

                                    <Col xs={12} className={'mb-2'}>
                                        <SelectableCard
                                            selected={this.isSelected(4)}
                                            title={'4주에 한 번'}
                                            icon={<Filter4Icon/>}
                                            badge={''}
                                            onClick={()=>{this.selectWeeks(4)}}
                                        />
                                    </Col>


                                    {/*{this.isTrial() ? null :*/}
                                    {/*    <Col xs={12} className={'mb-2'}>*/}
                                    {/*        <SelectableCard*/}
                                    {/*            selected={this.isSelected(0)}*/}
                                    {/*            title={'단건 결제'}*/}
                                    {/*            icon={<FilterNoneIcon/>}*/}
                                    {/*            badge={''}*/}
                                    {/*            onClick={() => {*/}
                                    {/*                this.selectWeeks(0)*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </Col>*/}
                                    {/*}*/}
                                </Row>

                                <Row className={'mt-4'}>

                                    <Col xs={12} className={'text-center m-2'}>
                                        <text style={{color: '#555', fontWeight: 400, fontSize: 12}}>결제 1일 전 핸드폰으로 미리 알려드립니다.</text><br/>
                                    </Col>

                                    <Col xs={12}>
                                        <Button className={'btn btn-block'}
                                                onClick={this.handleClickCheckout}
                                                style={{height: 50, fontSize: 18, fontWeight: 500}}>
                                            {this.state.final_total_price}
                                            결제하기
                                        </Button>
                                    </Col>

                                    <Col xs={12} className={'text-center m-2'}>
                                        <text style={{color: '#888', fontWeight: 400, fontSize: 12}}>주문 내역을 확인하였으며, 추후 구독 결제에 동의합니다.</text><br/>
                                        {this.isTrial() ?  <text style={{color: '#888', fontWeight: 400, fontSize: 12}}>무료 체험은 3일 후 구독 취소가 가능합니다.</text> :
                                            <text style={{color: '#888', fontWeight: 400, fontSize: 12}}>구독 취소는 언제든 가능합니다.</text>}
                                    </Col>

                                    <PaymentDialog
                                        open={this.state.openPaymentMethodDialog}
                                        onClickKakao={this.onClickKakao}
                                        onClickCard={this.onClickCard}
                                        onClose={this.handlePaymentModalClose} />


                                </Row>

                            </Container>
                        </Col>
                    </Row>

                </Container>
            </Container>


            <Footer/>

            </LoadingOverlay>
        </>);
    }

}

export default Checkout;
