import * as React from "react";
import {Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Icon from "../../shared/res/Icon";
import CartProduct from "../../shared/service/CartProduct";
import API from "../../shared/service/api/API";
import Image from "../mixin/Image";
import AddBoxIcon from '@material-ui/icons/AddBox';
import SubBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import Service from "../../shared/service/Service";
import Alert from "react-bootstrap/Alert";


class MypageProductsContainer extends React.Component{

    state = {
        productsPrice: 0,
        totalPrice: 0,
        shippingPrice: 0,
        productsUnitPrice: 0,
        totalUnitPrice: 0,
        next_payment_price: 0,
        showShippingAlert: false,
        showProductsModal: false,
        me: {
            mileage: 0
        }
    };

    busy = false;

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadProductCells();
        this.fetchNextPaymentPrice(this.props.subscription);
    }

    componentDidMount() {
        this.loadProductCells();
        this.fetchMe();
        this.fetchNextPaymentPrice(this.props.subscription);
    }

    handleClose = () => {
        this.props.onClose();
    };

    onChange = (subscription) => {
        this.fetchNextPaymentPrice(subscription);
        this.props.onChange(subscription);
    };

    fetchMe = async () => {
        let me = await API.auth.getMe();
        this.setState({
            me: me
        })
    };

    getProductCell = (product) => {
        let quantity = product.quantity;
        let title = product.title;
        let detail = product.detail;
        let imageId = product.image_id;
        let price = product.subscription_price;
        let is_old = product.is_old;
        return <><Row className={'justify-content-center mb-2'}>
            {!is_old?
            <Col xs={3} className={'text-center'}>
                <Image imageId={imageId} style={{
                    borderRadius: 10,
                    width: '70%'
                }}/>
            </Col>: null}
            <Col xs={9} className={'text-left mt-3'}>
                <Row>
                    <Col xs={9}>
                        <h8 style={{fontWeight: 600, fontSize:15}}>{title}</h8><br/>
                        <h7 style={{fontWeight: 500}}>{detail}</h7>
                    </Col>
                    <Col xs={3} className={'text-right'}>
                        <a onClick={()=>{
                            this.removeProduct(product);
                        }}><DeleteIcon/></a>
                    </Col>
                </Row>

                <Row className={'mt-2'}>
                    <Col xs={6}>
                        <h6 className={'text-primary'}>{price.toLocaleString()}원 <h8 className={'text-dark'}>x {quantity}개</h8></h6>

                    </Col>
                    <Col xs={6} className={'text-right'}>
                        <a onClick={()=>{
                            if (is_old){
                                alert("(구) 상품 구성은 변경이 불가능합니다. 새로운 상품을 추가해주세요.");
                            }else{
                                if (quantity > 1){
                                    this.subProduct(product)
                                }
                            }

                        }} className={'ml-1'}><SubBoxIcon/></a>
                        <a className={'ml-1'} onClick={()=>{
                            if (is_old){
                                alert("(구) 상품 구성은 변경이 불가능합니다. 새로운 상품을 추가해주세요.");
                            }else{
                                this.addProduct(product)
                            }
                        }}><AddBoxIcon/></a>
                    </Col>
                </Row>
            </Col>
        </Row>
            <hr/></>;
    };

    getProductTotalPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.subscription_price;
        });
        return price;
    };

    getShippingPrice = (products) => {
        if (products.length >= 5){
            return 0;
        }
        return 2500
    };

    getProductTotalUnitPrice = (products) => {
        let price = 0;
        products.forEach(product => {
            price += product.unit_price;
        });
        return price;
    };

    getShippingWarning = () => {
        let totalCount = 0;
        let subscription = this.props.subscription;
        let product_counts = subscription.product_counts;
        for (let product_count of product_counts){
            let count = product_count.count;
            totalCount += count;
        }
        if (totalCount < 5){
            return <Alert variant={'warning'}>5개 이상 주문시 추가 할인 적용</Alert>;
        }
        return null;
    };


    loadProductCells = (callback=()=>{}) => {
        let subscription = this.props.subscription;
        let product_counts = subscription.product_counts;
        let productIds = [];
        for (let product_count of product_counts){
            let product_id = product_count.product_id;
            let count = product_count.count;
            for (let i = 0; i < count; i++){
                productIds.push(product_id);
            }
        }
        this.fetchShippingPrice(productIds);
        return API.database.getItemsByIds(productIds).then(products => {
            let _products = {};
            products.forEach(product => {
                if (Object.keys(_products).includes(product.id)){
                    _products[product.id]['quantity'] += 1;
                }else{
                    _products[product.id] = product;
                    _products[product.id]['quantity'] = 1;
                    _products[product.id]['priority'] = Object.keys(_products).length;
                }
            });

            let productValues = Object.values(_products);
            productValues = productValues.sort((a, b) => {
                return a.priority - b.priority;
            });
            let productCells = productValues.map(product => {
                return this.getProductCell(product);
            });

            let productsPrice = this.getProductTotalPrice(products);
            let shippingPrice = this.getShippingPrice(products);
            let productsUnitPrice = this.getProductTotalUnitPrice(products);
            this.setState({
                productCells,
                shippingPrice: shippingPrice,
                productsPrice: productsPrice,
                productsUnitPrice: productsUnitPrice,
                totalPrice: shippingPrice + productsPrice,
                totalUnitPrice: shippingPrice + productsUnitPrice,
            }, () => {
                callback();
            })
        });
    };

    adjustProduct = (targetProductId, count) => {
        let subscription = this.props.subscription;
        let product_counts = subscription.product_counts;
        let has_product_id = false;
        for (let product_count of product_counts){
            if (product_count.product_id === targetProductId){
                product_count['count'] += count;
                has_product_id = true;
            }
        }
        if (!has_product_id){
            product_counts.push({
                product_id: targetProductId,
                count
            })
        }
        this.onChange(subscription)
    };

    subProduct = (product) => {
        this.adjustProduct(product.id, -1)
    };

    addProduct = (product) => {
        this.adjustProduct(product.id, +1)
    };

    removeProduct = (product) => {
        let subscription = this.props.subscription;
        let product_counts = subscription.product_counts.filter(product_count => {
            return product_count.product_id !== product.id;
        });
        subscription.product_counts = product_counts;
        this.onChange(subscription);
    };

    fetchShippingPrice = (products) => {
        if (products.length >= 5){
            this.setState({
                shippingPriceLabel: '무료 배송'
            })
        }else{
            this.setState({
                shippingPriceLabel: <text className={''}>2,500원</text>
            })
        }
    };

    getLabelColor = (forUnit) => {
        if (forUnit){
            return this.props.isUnit ? '#000': '#888';
        }else{
            return !this.props.isUnit ? '#000': '#888';
        }
    };

    getPriceLabelStyle = (forUnit) => {
        if (forUnit){
            if (this.props.isUnit){
                return {}
            }else{
                return {
                    color: '#888',
                    textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid'
                }
            }
        }else{
            if (!this.props.isUnit){
                return {}
            }else{
                return {
                    color: '#888',
                    textDecorationLine: 'line-through',
                    textDecorationStyle: 'solid'
                }
            }
        }

    };

    fetchNextPaymentPrice = (subscription) => {
        Service.payment.getNextPaymentPrice(subscription).then(data => {
            let next_payment_price = data.next_payment_price;
            this.setState({
                next_payment_price
            })
        });
    };

    render() {
        return <Container>

            {this.state.productCells}
            {this.getShippingWarning()}
            <Row className={'mt-1'}>
                <Col xs={6}>
                    <h6 style={{color: '#777777'}}>배송비</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={{color: '#777777'}}>{this.state.shippingPriceLabel}</h6>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <h6 style={{color: this.getLabelColor(true)}}>상품 단품가</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={this.getPriceLabelStyle(true)}>{this.state.productsUnitPrice.toLocaleString()}원</h6>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <h6 style={{color: this.getLabelColor(false)}}>상품 구독가</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 style={this.getPriceLabelStyle(false)}>{this.state.productsPrice.toLocaleString()}원</h6>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <h6>총 금액</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6>{this.props.isUnit ? this.state.totalUnitPrice.toLocaleString(): this.state.totalPrice.toLocaleString()}원</h6>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <hr/>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <h6>총 할인 금액</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    { this.state.next_payment_price > 0 ?
                    <h6 className={'text-danger'}>-{(this.state.totalUnitPrice - this.state.next_payment_price).toLocaleString()}원</h6>
                        : null}
                </Col>
            </Row>

            {this.state.me.mileage > 0 ?
            <Row>
                <Col xs={6}>
                    <h6>추가 마일리지 할인</h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6 className={'text-danger'}><b>-{(this.state.me.mileage).toLocaleString()}원</b></h6>
                </Col>
            </Row> : null}

            <Row>
                <Col xs={6}>
                    <h6><b>구독 할인 적용가</b></h6>
                </Col>
                <Col xs={6} className={'text-right'}>
                    <h6><b>{(this.state.next_payment_price - this.state.me.mileage).toLocaleString()}원</b></h6>
                </Col>
            </Row>

        </Container>
    }

}


export default MypageProductsContainer;
