


class Cache {

    static instance = new Cache();

    pairs = {};

    getValue = (key) => {
        return this.pairs[key];
    };

    putValue = (key, value) => {
        this.pairs[key] = value;
    };

}

export default Cache;