import Payment from "../shared/service/Payment";
import MyPage from "./MyPage";
import Loader from "react-spinners/DotLoader";
import LoadingOverlay from "react-loading-overlay";
import * as React from "react";
import queryString from 'query-string';
import Home from "./Home";
import Service from "../shared/service/Service";
import Login from "./Login";
import Checkout from "./Checkout";


class Oauth extends React.Component{

    static path = '/oauth';

    query = queryString.parse(window.location.search);

    getCode = () => {
        return this.query.code;
    };

    isToBuy = () => {
        let toBuy = window.localStorage.getItem('isToBuy');
        if (toBuy !== 'undefined'){
            toBuy = JSON.parse(toBuy);
            return toBuy
        }
        return false;
    };

    is_trial = () => {
        let is_trial = window.localStorage.getItem('is_trial');
        if (is_trial !== 'undefined'){
            is_trial = JSON.parse(is_trial);
            return is_trial
        }
        return false;
    };

    onLogin = () => {
        this.setState({
            loggedIn: true
        });
        if (this.isToBuy()){
            if (this.is_trial()){
                this.props.history.push(Checkout.path + "?is_trial=true");
            }else{
                this.props.history.push(Checkout.path);
            }

        }else{
            this.props.history.push(MyPage.path);
        }
    };

    doLogin = async () => {
        let code = this.getCode();
        let response_kakao = await Service.login.getKakaoAccessToken(code);
        let access_token = response_kakao.access_token;
        let data = await Service.login.loginKakao(access_token);
        if (data.user_id){
            this.onLogin();
        }else{
            alert("이메일로 가입되어 있는 계정입니다. 이메일로 시작하기 버튼을 클릭 해주세요.");
            this.props.history.push(Login.path);
        }
    };

    componentDidMount() {
        (async ()=> {
            await this.doLogin();
        })();
    }

    render() {
        return <div style={{height: 'calc(100vh)'}}>
            <LoadingOverlay
                active={true}
                spinner={<Loader />}
            >
                <div style={{height: 'calc(100vh)'}}>

                </div>
            </LoadingOverlay>

            </div>
    }

}

export default Oauth;
