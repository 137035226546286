import React from 'react';
import TopMenu from "../components/TopMenu";
import Container from "react-bootstrap/Container";
import MainSection1 from "../components/main/MainSection1";
import MainSection2 from "../components/main/MainSection2";
import MainSection3 from "../components/main/MainSection3";
import MainSection4 from "../components/main/MainSection4";
import MainSection5 from "../components/main/MainSection5";
import MainSection6 from "../components/main/MainSection6";
import MainSection7 from "../components/main/MainSection7";
import Footer from "../components/Footer";
import EasyToCarrySection from "../components/main/EasyToCarrySection";
import FlatWhite from "../components/main/ProductSection/FlatWhite";
import AISelectorModal from "../components/main/AISelecterModal";
import BrilliantPink from "../components/main/ProductSection/BrilliantPink";
import AnotherGreen from "../components/main/ProductSection/AnotherGreen";
import DECAF from "../components/main/ProductSection/DECAF";
import LampBlack from "../components/main/ProductSection/LampBlack";
import CobaltViolet from "../components/main/ProductSection/CobaltViolet";
import BrazilColdbrew from "../components/main/ProductSection/BrazilColdbrew";
import EthiopiaColdbrew from "../components/main/ProductSection/EthiopiaColdbrew";
import CafeLatte from "../components/main/ProductSection/CafeLatte";

import Color from "../shared/Color";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


let init = false;

export default function Home(props){

    const [showCart, setShowCart] = React.useState(false);

    const [scrollPosition, setScrollPosition] = React.useState(0);
    const [showBottom, setShowBottom] = React.useState(false);
    const [showAI, setShowAI] = React.useState(false);

    const handleScroll = () => {
        let position = window.pageYOffset;
        if (!position){
            position = 0;
        }
        setScrollPosition(position);
        const point = 200;
        if (showBottom && position <= point){
            setShowBottom(false);
        }
        if (!showBottom && position > point){
            setShowBottom(true);
        }

    };

    React.useEffect(() => {
        if (!init){
            console.log('init');
            setTimeout(()=>{
                // Instagram 등의 브라우저에서 처음에 이상하게 모양 잡히는 이슈 해결
                window.dispatchEvent(new Event('resize'));
                console.log('resize');
            }, 500);
            init = true;
        }
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showBottom]);

    window.setVisibleKakao(false);

    const getBottomBarBottom = () => {
        if (showBottom){
            return 0;
        }else{
            return -60;
        }
    };



    return (<>
        <TopMenu showCart={showCart}/>

        <AISelectorModal onShowCart={(show)=>{
            setShowCart(show);
        }} show={showAI} onHide={()=>{
            setShowAI(false);
        }}/>

        <div style={{
            position: 'fixed',
            backdropFilter: 'blur(4px)', backgroundColor: 'rgba(0,0,0, 0.7)',
            bottom: getBottomBarBottom(),
            // background: 'linear-gradient(45deg, rgba(6,6,6,0.65),rgba(6, 6, 6,0.68))',
            width: '100%',
            transition: 'bottom 0.5s',
            zIndex: 1,
            borderWidth: 2,
            borderColor: Color.primary,
        }}>
            <Container fluid>
                <Row>
                    <Col md={12} style={{textAlign: 'center'}}>
                        <Button style={{width: '90%', height: 44, fontSize: 15, backgroundColor: Color.primary, color: '#fff', fontWeight: 700, margin: 8, borderRadius: 17}}
                                endIcon={<ChevronRightIcon/>}
                                onClick={()=>{
                                    setShowAI(true);
                                }}
                        >
                            커피 추천 받기
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>


        <Container fluid>
            <MainSection1
                onShowAI={()=>{
                    setShowAI(true);
                }}
                onShowCart={(show)=>{
                setShowCart(show);
            }} />
            <MainSection2/>

            <BrilliantPink/>
            <AnotherGreen/>
            <DECAF/>
            <LampBlack/>
            <FlatWhite/>
            <CobaltViolet/>

            <BrazilColdbrew/>
            <EthiopiaColdbrew/>
            <CafeLatte/>

            <MainSection3/>
            {/*<MainSection5/>*/}
            <MainSection6/>
            <MainSection4/>
            {/*<MainSection7/>*/}
        </Container>
        <Footer/>
    </>);

}
