import Button from "reactstrap/lib/Button";
import React from "react";

import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


export default function ProductCounter(props) {

    return <div style={props.style}>
        <Button style={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 30,
            paddingTop: 2,

        }} onClick={props.onDecrease}>
            <text><RemoveIcon/></text>
        </Button>
        <Button className={'text-align-center'} style={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            width: 60,
            paddingTop: 2,
            height: 30,
        }} >
            <text style={{height: 40}}>{props.count}</text>
        </Button>
        <Button style={{
            paddingTop: 2,
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            height: 30,

        }} onClick={props.onIncrease}>
            <text><Add/></text>
        </Button>
    </div>

}
