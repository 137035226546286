import SizingComponent from "../components/mixin/SizingComponent";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import backgroundMobile from "../components/images/brazil_page_m.png";
import Card from "react-bootstrap/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import TopMenu from "../components/TopMenu";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import KakaoLoginIcon from "../components/images/kakao_login_icon.png";
import Icon from "../shared/res/Icon";
import CoffeeImage from "../components/images/bottom.png";
import LoginSection from "../components/member/LoginSection";
import MyPage from "./MyPage";
import API from "../shared/service/api/API";
import Checkout from "./Checkout";
import queryString from "query-string";
import Service from "../shared/service/Service";

class Login extends SizingComponent{

    static path = '/login';


    state = {
        topMenu: null,
        loggedIn: false,
    };

    query = queryString.parse(window.location.search);


    isTrial = () => {
        return this.query.is_trial === 'true';
    };

    isToBuy = () => {
        return this.query.to_buy === 'true';
    };

    componentDidMount() {
        super.componentDidMount();
        this.checkLoggedIn();
        // let query = queryString.parse(window.location.search);
        // if (query.code){
        //     Service.login.getKakaoAccessToken(query.code).then(response => {
        //         alert(JSON.stringify(response));
        //         let access_token = response.access_token;
        //         Service.login.loginKakao(access_token).then(data=>{
        //             if (data.user_id){
        //                 this.onLogin();
        //             }else{
        //                 alert("이메일로 가입되어 있는 계정입니다. 이메일로 시작하기 버튼을 클릭 해주세요.");
        //             }
        //         });
        //     })
        // }
        window.localStorage.setItem('isToBuy', JSON.stringify(this.isToBuy()));
        window.localStorage.setItem('is_trial', JSON.stringify(this.isTrial()));
    }


    onLogin = () => {
        this.setState({
            loggedIn: true
        });
        if (this.isToBuy()){
            if (this.isTrial()){
                this.props.history.push(Checkout.path + "?is_trial=true");
            }else{
                this.props.history.push(Checkout.path);
            }
        }else{
            this.props.history.push(MyPage.path);
        }
    };

    checkLoggedIn = () => {
        API.auth.isLogin().then(loggedIn => {
            if (loggedIn){
                this.props.history.push(MyPage.path);
            }
        });
    };


    render() {
        return (
            <>
                <div style={{
                    marginTop: 40,
                    background:'linear-gradient(rgba(32,62,73,0.08), rgba(32,62,73,0.0))',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <TopMenu loggedIn={this.state.loggedIn}/>

                    <Container style={Footer.getContainerStyle()}>
                        <Row className={'mt-1 justify-content-center'} >
                            <Col style={{marginTop: 60, marginLeft: 20}}>
                                {this.isToBuy() ? <h6>구매와 동시에,</h6> : null}
                                <h3 className={'text-black'}>로그인 및 회원가입<text style={{fontWeight: 200}}>을</text></h3>
                                <h2 className={'text-black'} style={{fontWeight: 200}}>시작합니다.</h2>
                            </Col>
                        </Row>

                        <Row flex className={'justify-content-center '} style={{height: '100%'}}>

                            <LoginSection style={{maxWidth: 400}} onLogin={this.onLogin}/>

                            {/*<Col md={6} className={'text-bottom'} style={{minHeight: 600, height: 'calc(100vh - 350px)',}}>*/}
                            {/*    /!*<img src={CoffeeImage} height={'600'} width={'auto'} style={{bottom: 0,*!/*/}
                            {/*    /!*    position: 'absolute'}} />*!/*/}
                            {/*</Col>*/}
                            <Col md={6} className={'text-bottom'} style={{minHeight: 600, height: 'calc(100vh - 450px)',}}>
                                {/*<img src={CoffeeImage} height={'600'} width={'auto'} style={{bottom: 0,*/}
                                {/*    position: 'absolute'}} />*/}
                            </Col>

                        </Row>
                    </Container>

                </div>
                <Footer/>
            </>
        );
    }

}

export default Login;
