
class RequestPayment {

    //결제 요청
    static request = (pg, payment_method_id, email, name, tel, addr, postcode) => {
        return new Promise(resolve => {
            window.requestPayment(pg, payment_method_id, email, name, tel, addr, postcode, (response) => {
                resolve(response);
            })
        });
    };

    static createPaymentMethod = (pg, payment_method_id, email, name, tel) => {
        return new Promise(resolve => {
            window.requestPayment(pg, payment_method_id, email, name, tel, "payment_method_creation", "00000", (response) => {
                resolve(response);
            })
        });
    }

}

export default RequestPayment;
