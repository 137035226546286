import PageLayout from "../shared/PageLayout";
import IntroLampBlackCard from "../card/intro/IntroLampBlackCard";
import TasteDarkChocolate from "../card/taste/TasteDarkChocolate";
import TasteBerry from "../card/taste/TasteBerry";
import MicrolotCard from "../card/Pros/MicrolotCard";
import ShippingCard from "../card/Pros/ShippingCard";
import DripCoffeeCard from "../card/Pros/DripCoffeeCard";
import RTDCard from "../card/Pros/RTDCard";
import AwardCard from "../card/Pros/AwardCard";
import ReviewCard from "../card/Pros/ReviewCard";
import {Row} from "react-bootstrap";
import * as React from "react";
import IngredientCard from "../card/Pros/IngredientCard";
import TasteDarkChocolateSecond from "../card/taste/TasteDarkChocolateSecond";
import TasteDryBerry from "../card/taste/TasteDryBerry";
import IntroBrazilCard from "../card/intro/IntroBrazilCard";
import TasteRoastedPeanuts from "../card/taste/TasteRoastedPeanuts";
import IntroLatteCard from "../card/intro/IntroLatteCard";
import TasteMilk from "../card/taste/TasteMilk";
import RealMilkCard from "../card/Pros/RealMilkCard";
import RelatedLatte from "../card/postproduct/RelatedLatte";
import RelatedFlatwhite from "../card/postproduct/RelatedFlatwhite";
import RelatedAnotherGreen from "../card/postproduct/RelatedAnotherGreen";


export default function LattePage(props) {

    const product_id = 'QDv7y8oZhco6PLioK2GAjq';

    return <PageLayout
        product_id={product_id}
    >
        <IntroLatteCard/>
        <TasteMilk/>
        <TasteDarkChocolateSecond/>
        <MicrolotCard/>
        <RealMilkCard/>
        <ShippingCard/>

        <RTDCard/>
        <AwardCard/>
        <IngredientCard product_id={product_id}/>
        <ReviewCard/>


        <RelatedAnotherGreen/>
        <RelatedFlatwhite/>

    </PageLayout>

}

