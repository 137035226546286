import * as React from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CoffeeImage from "../../images/iceCoffee.png";
import BackVideo from "../../mov/backv.mp4";
import SizingComponent from "../../mixin/SizingComponent";
import BrilliantPink1 from "../../images/product_real/brilliant_pink.jpg";
import useFontRatio from "../../../util/UseWindowDimensions";
import TasteGraph from "../TasteGraph";
import SeeMoreButton from "./SeeMoreButton";
import Badge from "reactstrap/lib/Badge";
import Color from "../../../shared/Color";



export default function BrilliantPink(props){

    const fr = useFontRatio();

    return <Row style={{
        background: 'linear-gradient(rgba(10,12,13,0.98),rgba(10,12,13,0.92))',
        marginTop: -2
    }} className={'justify-content-md-center'} >

        <Col style={{
            borderRadius: 0,
            padding: 40 * fr,
            background: 'linear-gradient(rgba(203,92,192,0.68),rgba(203,92,192,0.47))'
        }}>
            <Container>
                <Row>
                    <Col md={6} className={'mt-3 mb-3'}>
                        <Badge style={{marginTop: 0, background: Color.primary, color: '#fff'}}>드립커피</Badge><br/>
                        <text style={{fontSize:  Math.max(30, Math.min(20 * fr, 20)), fontWeight: 800}} className={'text-white'}>브릴리언트 핑크</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(40, Math.min(50 * fr, 50)), fontWeight: 800, color: '#f199e8'}}>Brilliant Pink</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(30, Math.min(60 * fr, 60)), fontWeight: 800, color: '#fff', lineHeight: 1.3}}>산뜻한 입맛,<br/>상큼한 향미.</text>
                        <br/><br/>
                        <text style={{fontSize: Math.max(14, Math.min(20 * fr, 20)), fontWeight: 500, color: '#fff', lineHeight: 1.5}}>
                            오렌지 블라썸의 향미, 잘익은 오렌지의 상큼함, <br/>
                            달콤한 꿀의 밸런스가 조화로운 마일드 커피.
                        </text>
                        <br/>
                        <SeeMoreButton onClick={()=>{
                            window.open('/products/brilliant_pink');
                        }}/>

                    </Col>

                    <Col md={6} className={'mt-3 mb-3'}>
                        <img src={BrilliantPink1} width={'100%'} style={{marginRight:0, borderRadius: 10}}/>
                    </Col>
                </Row>

            </Container>


        </Col>


    </Row>

}
